






























import {Component, Vue, Prop} from "vue-property-decorator";
import ContactInnerForm from "@/components/ContactInnerForm.vue";

@Component({
  components: {ContactInnerForm}
})
export default class BookDemo extends Vue {
  @Prop({default: "Book a demo", required: false}) title?: string;
  @Prop({required: false, default: false}) rounded!: boolean;
  @Prop({required: false, default: false}) wauto!: boolean;

  isOpen = false;

  get isRounded() {
    if (!this.rounded) {
      return false;
    }
    return true;
  }
}
