


















































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {BaseDataV2, ExhibitionV2, VenueV2, WayfinderV2} from "@/types/vizgu";
import ItemSlideGroup from "@/components/ItemSlideGroupV2.vue";
import VideoCard from "@/components/VideoCard.vue";
import GUIDImage from "@/components/GUIDImage.vue";
import HighlightedSection, {
  HighlightedSectionData
} from "@/components/HighlightedSection.vue";
import venuesApi from "@/api/venues";
import {embeddedStore} from "@/store";
import {
  GetExhibitionsRequest,
  GetExhibitionsResponse,
  GetVenuesRequest,
  GetVenuesResponse,
  GetWayfindersRequest,
  GetWayfindersResponse
} from "@/types/api";
import exhibitionsApi from "@/api/exhibitions";
import wayfindersApi from "@/api/wayfinders";
import {
  exhibitionHighlightedData,
  venueHighlightedData,
  wayfinderHighlightedData
} from "@/utils/highlighted-section";
import {copyCurrentRouteClipboard} from "@/utils/clipboard";
import {EventKeys} from "@/store/modules/embedded";
import {parseBaseContentText} from "@/utils/data";
import {TranslateResult} from "vue-i18n";

export interface TabItem {
  title: string | TranslateResult;
  route: string;
}

@Component({
  components: {HighlightedSection, VideoCard, ItemSlideGroup, GUIDImage}
})
export default class City extends Vue {
  likeFeature = false;
  smallCardWidth = 220;
  wideCardWidth = 364;
  museumCardWidth = this.smallCardWidth;
  exhibitionCardWidth = this.wideCardWidth;
  eventCardWidth = this.wideCardWidth;
  cityCardWidth = this.smallCardWidth;
  artistCardWidth = this.smallCardWidth;
  wayfinderCardWidth = this.smallCardWidth;

  cityVenue: VenueV2 | null = null;

  initialDataLoaded = false;
  slidersSettings = {
    perPage: 20
  };

  slidersData = {
    venues: [] as BaseDataV2[],
    topExhibitions: [] as BaseDataV2[],
    events: [] as BaseDataV2[],
    artists: [] as BaseDataV2[],
    wayfinders: [] as BaseDataV2[]
  };

  @Watch("$route.params.id")
  onIdChange() {
    window.scrollTo(0, 0);
    this.loadApiData();
  }

  shareLink() {
    copyCurrentRouteClipboard(this.$route);
  }

  get hSections() {
    return {
      first: this.firstHighlightedSection,
      second: this.secondHighlightedSection,
      third: this.thirdHighlightedSection
    };
  }

  get firstHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.museumsData?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      content: venueHighlightedData(v as VenueV2)
    });
  }

  get secondHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.exhibitionsData?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      imageTooltipText: "New!",
      content: exhibitionHighlightedData(v as ExhibitionV2)
    });
  }

  get thirdHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.wayfindersData?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      imageTooltipText: "Popular!",
      content: wayfinderHighlightedData(v as WayfinderV2)
    });
  }

  get countryName(): string {
    return this.cityVenue?.country || "";
  }

  get cityName(): string {
    return this.cityVenue?.city || "";
  }

  get tabItems(): TabItem[] {
    return [
      {
        title: this.$t("About"),
        route: "#about"
      },
      {
        title: this.$t("Venues"),
        route: "#venues"
      },
      {
        title: this.$t("Events"),
        route: "#events"
      },
      {
        title: this.$t("Wayfinders"),
        route: "#wayfinders"
      }
    ];
  }

  get bannerImgSrc() {
    return this.cityVenue?.imageGuids.$values[0] || "";
  }

  get museumsData(): BaseDataV2[] {
    return this.slidersData.venues;
    // return getSampleBaseDataList();
  }
  get exhibitionsData(): BaseDataV2[] {
    return this.slidersData.topExhibitions;
    // return getSampleBaseDataList();
  }
  get eventsData(): BaseDataV2[] {
    return this.slidersData.events;
    // return getSampleBaseDataList();
  }
  get artistsData(): BaseDataV2[] {
    return this.slidersData.artists;
    // return getSampleBaseDataList();
  }
  get wayfindersData(): BaseDataV2[] {
    return this.slidersData.wayfinders;
    // return getSampleBaseDataList();
  }

  get description(): string {
    return this.cityVenue ? parseBaseContentText(this.cityVenue) : "";
  }

  initListeners(enable: boolean) {
    if (enable) {
      window.addEventListener(EventKeys.locale, this.onLocaleEvent);
    } else {
      window.removeEventListener(EventKeys.locale, this.onLocaleEvent);
    }
  }

  onLocaleEvent() {
    this.loadApiData();
  }

  async created() {
    this.initListeners(true);
    await this.loadApiData();
  }

  beforeDestroy() {
    this.initListeners(false);
  }

  async loadApiData() {
    embeddedStore.setCurrentVenueLoading(true);
    embeddedStore.setCurrentVenue(null);
    const id: string = this.$route.params.id;
    await this.requestVenue(id);
  }

  private async requestVenue(id: string) {
    const resp: VenueV2 = await venuesApi.getVenue(id);
    this.cityVenue = resp;
    embeddedStore.setCurrentVenue(resp);
    embeddedStore.setCurrentVenueLoading(false);
    this.requestSlidersData();
  }

  private async requestSlidersData() {
    this.getSliderMuseums();
    this.getSliderExhibitions();
    this.getSliderWayfinders();
  }

  private async getSliderMuseums() {
    const params: GetVenuesRequest = {
      take: this.slidersSettings.perPage,
      city: this.cityName
    };
    const resp: GetVenuesResponse = await venuesApi.getVenues(params);
    this.slidersData.venues = resp.list.$values;
  }

  private async getSliderExhibitions() {
    const params: GetExhibitionsRequest = {
      skip: 0,
      take: this.slidersSettings.perPage,
      city: this.cityName
    };
    const resp: GetExhibitionsResponse = await exhibitionsApi.getExhibitions(
      params
    );
    this.slidersData.topExhibitions = resp.list.$values;
  }

  private async getSliderWayfinders() {
    const params: GetWayfindersRequest = {
      skip: 0,
      take: this.slidersSettings.perPage,
      city: this.cityName
    };
    const resp: GetWayfindersResponse = await wayfindersApi.getWayfinders(
      params
    );
    this.slidersData.wayfinders = resp.list.$values;
  }
}
