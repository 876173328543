











import {Component, Vue} from "vue-property-decorator";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

@Component({
  components: {Footer, Navbar}
})
export default class FrontPageLayout extends Vue {}
