export type TCountry = {
  name: Country;
  label: string;
  icon: string;
  phoneCode: string;
};

export enum Country {
  Denmark = "Denmark",
  Switzerland = "Switzerland",
  France = "France",
  Germany = "Germany",
  Sweden = "Sweden",
  Netherlands = "Netherlands",
  Finland = "Finland",
  Canada = "Canada",
  Belgium = "Belgium",
  Ireland = "Ireland",
  Austria = "Austria",
  Norway = "Norway",
  Luxembourg = "Luxembourg",
  Spain = "Spain",
  UnitedKingdom = "UnitedKingdom",
  UnitedStates = "UnitedStates",
  Cyprus = "Cyprus",
  Slovenia = "Slovenia",
  Australia = "Australia",
  NewZealand = "NewZealand",
  Iceland = "Iceland",
  Bulgaria = "Bulgaria",
  Estonia = "Estonia",
  Hungary = "Hungary",
  Italy = "Italy",
  Singapore = "Singapore",
  Lithuania = "Lithuania",
  Poland = "Poland",
  CzechRepublic = "CzechRepublic",
  Portugal = "Portugal",
  Croatia = "Croatia",
  Malta = "Malta",
  Slovakia = "Slovakia",
  Chile = "Chile",
  Japan = "Japan",
  Latvia = "Latvia",
  Greece = "Greece",
  SouthKorea = "SouthKorea",
  Serbia = "Serbia",
  Moldova = "Moldova",
  Romania = "Romania",
  Montenegro = "Montenegro",
  Armenia = "Armenia",
  SouthAfrica = "SouthAfrica",
  Uruguay = "Uruguay",
  Cuba = "Cuba",
  India = "India",
  Russia = "Russia",
  Ukraine = "Ukraine",
  China = "China",
  Malaysia = "Malaysia",
  CostaRica = "CostaRica",
  BosniaandHerzegovina = "BosniaandHerzegovina"
}

const C = Country;

export const COUNTRIES = [
  {
    name: C.Denmark,
    label: C.Denmark,
    icon: "emojione-v1:flag-for-denmark",
    phoneCode: "+45"
  },
  {
    name: C.Switzerland,
    label: C.Switzerland,
    icon: "emojione-v1:flag-for-switzerland",
    phoneCode: "+41"
  },
  {
    name: C.France,
    label: C.France,
    icon: "emojione-v1:flag-for-france",
    phoneCode: "+33"
  },
  {
    name: C.Germany,
    label: C.Germany,
    icon: "emojione-v1:flag-for-germany",
    phoneCode: "+49"
  },
  {
    name: C.Sweden,
    label: C.Sweden,
    icon: "emojione-v1:flag-for-sweden",
    phoneCode: "+46"
  },
  {
    name: C.Netherlands,
    label: C.Netherlands,
    icon: "emojione-v1:flag-for-netherlands",
    phoneCode: "+31"
  },
  {
    name: C.Finland,
    label: C.Finland,
    icon: "emojione-v1:flag-for-finland",
    phoneCode: "+358"
  },
  {
    name: C.Canada,
    label: C.Canada,
    icon: "emojione-v1:flag-for-canada",
    phoneCode: "+1"
  },
  {
    name: C.Belgium,
    label: C.Belgium,
    icon: "emojione-v1:flag-for-belgium",
    phoneCode: "+32"
  },
  {
    name: C.Ireland,
    label: C.Ireland,
    icon: "emojione-v1:flag-for-ireland",
    phoneCode: "+353"
  },
  {
    name: C.Austria,
    label: C.Austria,
    icon: "emojione-v1:flag-for-austria",
    phoneCode: "+43"
  },
  {
    name: C.Norway,
    label: C.Norway,
    icon: "emojione-v1:flag-for-norway",
    phoneCode: "+47"
  },
  {
    name: C.Luxembourg,
    label: C.Luxembourg,
    icon: "emojione-v1:flag-for-luxembourg",
    phoneCode: "+352"
  },
  {
    name: C.Spain,
    label: C.Spain,
    icon: "emojione-v1:flag-for-spain",
    phoneCode: "+34"
  },
  {
    name: C.UnitedKingdom,
    label: C.UnitedKingdom,
    icon: "emojione-v1:flag-for-united-kingdom",
    phoneCode: "+44"
  },
  {
    name: C.UnitedStates,
    label: C.UnitedStates,
    icon: "emojione-v1:flag-for-united-states",
    phoneCode: "+1"
  },
  {
    name: C.Cyprus,
    label: C.Cyprus,
    icon: "emojione-v1:flag-for-cyprus",
    phoneCode: "+357"
  },
  {
    name: C.Slovenia,
    label: C.Slovenia,
    icon: "emojione-v1:flag-for-slovenia",
    phoneCode: "+386"
  },
  {
    name: C.Australia,
    label: C.Australia,
    icon: "emojione-v1:flag-for-australia",
    phoneCode: "+61"
  },
  {
    name: C.NewZealand,
    label: C.NewZealand,
    icon: "emojione-v1:flag-for-new-zealand",
    phoneCode: "+64"
  },
  {
    name: C.Iceland,
    label: C.Iceland,
    icon: "emojione-v1:flag-for-iceland",
    phoneCode: "+354"
  },
  {
    name: C.Bulgaria,
    label: C.Bulgaria,
    icon: "emojione-v1:flag-for-bulgaria",
    phoneCode: "+359"
  },
  {
    name: C.Estonia,
    label: C.Estonia,
    icon: "emojione-v1:flag-for-estonia",
    phoneCode: "+372"
  },
  {
    name: C.Hungary,
    label: C.Hungary,
    icon: "emojione-v1:flag-for-hungary",
    phoneCode: "+36"
  },
  {
    name: C.Italy,
    label: C.Italy,
    icon: "emojione-v1:flag-for-italy",
    phoneCode: "+39"
  },
  {
    name: C.Singapore,
    label: C.Singapore,
    icon: "emojione-v1:flag-for-singapore",
    phoneCode: "+65"
  },
  {
    name: C.Lithuania,
    label: C.Lithuania,
    icon: "emojione-v1:flag-for-lithuania",
    phoneCode: "+370"
  },
  {
    name: C.Poland,
    label: C.Poland,
    icon: "emojione-v1:flag-for-poland",
    phoneCode: "+48"
  },
  {
    name: C.Croatia,
    label: C.Croatia,
    icon: "emojione-v1:flag-for-croatia",
    phoneCode: "+385"
  },
  {
    name: C.Malta,
    label: C.Malta,
    icon: "emojione-v1:flag-for-malta",
    phoneCode: "+356"
  },
  {
    name: C.Slovakia,
    label: C.Slovakia,
    icon: "emojione-v1:flag-for-slovakia",
    phoneCode: "+421"
  },
  {
    name: C.Chile,
    label: C.Chile,
    icon: "emojione-v1:flag-for-chile",
    phoneCode: "+56"
  },
  {
    name: C.Japan,
    label: C.Japan,
    icon: "emojione-v1:flag-for-japan",
    phoneCode: "+81"
  },
  {
    name: C.Latvia,
    label: C.Latvia,
    icon: "emojione-v1:flag-for-latvia",
    phoneCode: "+371"
  },
  {
    name: C.Greece,
    label: C.Greece,
    icon: "emojione-v1:flag-for-greece",
    phoneCode: "+30"
  },
  {
    name: C.SouthKorea,
    label: C.SouthKorea,
    icon: "emojione-v1:flag-for-south-korea",
    phoneCode: "+82"
  },
  {
    name: C.Serbia,
    label: C.Serbia,
    icon: "emojione-v1:flag-for-serbia",
    phoneCode: "+381"
  },
  {
    name: C.Moldova,
    label: C.Moldova,
    icon: "emojione-v1:flag-for-moldova",
    phoneCode: "+373"
  },
  {
    name: C.Romania,
    label: C.Romania,
    icon: "emojione-v1:flag-for-romania",
    phoneCode: "+40"
  },
  {
    name: C.Montenegro,
    label: C.Montenegro,
    icon: "emojione-v1:flag-for-montenegro",
    phoneCode: "+382"
  },
  {
    name: C.Armenia,
    label: C.Armenia,
    icon: "emojione-v1:flag-for-armenia",
    phoneCode: "+374"
  },
  {
    name: C.SouthAfrica,
    label: C.SouthAfrica,
    icon: "emojione-v1:flag-for-south-africa",
    phoneCode: "+27"
  },
  {
    name: C.Uruguay,
    label: C.Uruguay,
    icon: "emojione-v1:flag-for-uruguay",
    phoneCode: "+598"
  },
  {
    name: C.Cuba,
    label: C.Cuba,
    icon: "emojione-v1:flag-for-cuba",
    phoneCode: "+53"
  },
  {
    name: C.India,
    label: C.India,
    icon: "emojione-v1:flag-for-india",
    phoneCode: "+91"
  },
  {
    name: C.Russia,
    label: C.Russia,
    icon: "emojione-v1:flag-for-russia",
    phoneCode: "+7"
  },
  {
    name: C.Ukraine,
    label: C.Ukraine,
    icon: "emojione-v1:flag-for-ukraine",
    phoneCode: "+380"
  },
  {
    name: C.China,
    label: C.China,
    icon: "emojione-v1:flag-for-china",
    phoneCode: "+86"
  },
  {
    name: C.Malaysia,
    label: C.Malaysia,
    icon: "emojione-v1:flag-for-malaysia",
    phoneCode: "+60"
  },
  {
    name: C.CostaRica,
    label: C.CostaRica,
    icon: "emojione-v1:flag-for-costa-rica",
    phoneCode: "+506"
  },
  {
    name: C.BosniaandHerzegovina,
    label: C.BosniaandHerzegovina,
    icon: "emojione-v1:flag-for-bosnia-and-herzegovina",
    phoneCode: "+387"
  }
] as Array<TCountry>;
