import Vue from "vue";
import Vuetify from "vuetify/lib";
import Icon from "@/baseComponents/Icon.vue";
import i18n from "@/plugins/i18n";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {disable: true},
  breakpoint: {
    thresholds: {
      xs: 640,
      sm: 768,
      md: 1024,
      lg: 1280
    },
    mobileBreakpoint: "sm"
  },
  icons: {
    component: Icon,
    values: {
      dropdown: "menu-down",
      checkboxOn: "checkbox-on",
      checkboxOff: "checkbox-off",
      radioOn: "radio-on",
      radioOff: "radio-off",
      next: "right",
      prev: "left"
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string
  }
});
