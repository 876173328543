




































































































































import {TranslateResult} from "vue-i18n";
import {Component, Vue} from "vue-property-decorator";

interface Advantage {
  title: string | TranslateResult;
  description: string | TranslateResult;
  imgSrc: string;
  align: string;
}

@Component({})
export default class Careers extends Vue {
  get images() {
    return {
      mainBanner: require("@/assets/images/footer/careers/main-banner.png"),
      sub1: require("@/assets/images/footer/careers/sub-1.jpg"),
      sub2: require("@/assets/images/footer/careers/sub-2.jpg"),
      sub3: require("@/assets/images/footer/careers/sub-3.jpg")
    };
  }

  advantages: Advantage[] = [
    {
      title: this.$t("Mutual Respect"),
      description: this.$t(
        "Treat people as you want to be treated. Narcissistic behaviour has no place within our company. We operate in a safe environment where you can be yourself regardless of what other people may think."
      ),
      imgSrc: this.images.sub1,
      align: "md:items-end"
    },
    {
      title: this.$t("Trust & Enablement"),
      description: this.$t(
        "We enable each other with the skillsets we have - and most importantly trust in the decisions being made - with great respect towards the individuals of expertise."
      ),
      imgSrc: this.images.sub2,
      align: "md:items-center"
    },
    {
      title: this.$t("Diversity"),
      description: this.$t(
        "We are all children of mother earth. Diversity is what makes us great. Vizgu is a safe space for all regardless of identity, sex and origin."
      ),
      imgSrc: this.images.sub3,
      align: "md:items-center"
    }
  ];
}
