



























































































import BasePageBanner, {
  BasePageBannerData
} from "@/components/BasePageBanner.vue";
import ListItems from "@/components/ListItemsV2.vue";
import {BaseDataV2, WayfinderV2} from "@/types/vizgu";
import {Component, Vue} from "vue-property-decorator";
import HighlightedSection, {
  HighlightedSectionData
} from "@/components/HighlightedSection.vue";
import SelectSlider from "@/components/SelectSlider.vue";
import wayfindersApi from "@/api/wayfinders";
import {GetWayfindersRequest, GetWayfindersResponse} from "@/types/api";
import {wayfinderHighlightedData} from "@/utils/highlighted-section";
import {EventKeys} from "@/store/modules/embedded";

@Component({
  components: {BasePageBanner, ListItems, HighlightedSection, SelectSlider}
})
export default class Wayfinders extends Vue {
  totalItems = 0;
  perPage = 10;
  itemWidth = 200;
  initialDataLoaded = false;

  showMoreWayfinders = false;

  wayfinderList: WayfinderV2[] = [];

  loaders = {
    loadingMoreWayfinders: false
  };

  get loadMoreSkeletonsCount() {
    return this.loaders.loadingMoreWayfinders ? 10 : 0;
  }

  durationLabel(e: BaseDataV2) {
    return "";
  }

  get hasMore() {
    return this.totalItems > this.wayfinderList.length;
  }

  get wayfindersData(): WayfinderV2[] {
    return this.wayfinderList;
  }

  get hSections() {
    return {
      first: this.firstHighlightedSection,
      second: this.secondHighlightedSection
    };
  }

  get firstHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.wayfinderList?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      content: wayfinderHighlightedData(v)
    });
  }

  get secondHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.wayfinderList?.[1];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      imageTooltipText: "New!",
      content: wayfinderHighlightedData(v)
    });
  }

  get baseBannerData(): BasePageBannerData {
    return {
      imgUid: "3b716bdd-0a7e-4365-854f-2321b0f7946f",
      icon: "wayfinder",
      title: this.$t("Wayfinders"),
      subtitle: this.$t(
        "Wayfinders are an exciting way to be guided around cities or wayfinders with cool stories around every corner - and sometimes even involves AR experiences!"
      )
    };
  }

  goWayfinderPage(item: BaseDataV2) {
    this.$router.push(`/wayfinders/${item.id}`);
  }

  initListeners(enable: boolean) {
    if (enable) {
      window.addEventListener(EventKeys.locale, this.onLocaleEvent);
    } else {
      window.removeEventListener(EventKeys.locale, this.onLocaleEvent);
    }
  }

  onLocaleEvent() {
    this.loadInitialData();
  }

  // lifecycle methods
  created() {
    this.initListeners(true);
    this.loadInitialData();
  }

  beforeDestroy() {
    this.initListeners(false);
  }

  // data load methods
  private async loadInitialData() {
    await this.requestWayfinders(true);
    this.initialDataLoaded = true;
  }

  private async requestWayfinders(replace = false) {
    if (!replace && !this.hasMore && this.initialDataLoaded) {
      return;
    }
    try {
      !replace && (this.loaders.loadingMoreWayfinders = true);
      const params: GetWayfindersRequest = {
        skip: this.wayfinderList.length,
        take: this.perPage
      };
      const resp: GetWayfindersResponse = await wayfindersApi.getWayfinders(
        params
      );
      this.totalItems = resp.count;
      const data: WayfinderV2[] = resp.list.$values;
      if (replace) {
        this.wayfinderList = data;
      } else {
        this.wayfinderList = this.wayfinderList.concat(data);
      }
      this.totalItems = resp.count;
    } finally {
      this.loaders.loadingMoreWayfinders = false;
    }
  }
}
