

























































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {ExhibitionV2, MapPoint, VenueV2, VizguTypeLink} from "@/types/vizgu";
import ItemSlideGroupV2 from "@/components/ItemSlideGroupV2.vue";
// import {ItemTuple} from "@/types/homepage";
import VideoCard from "@/components/VideoCard.vue";
import GUIDImage from "@/components/GUIDImage.vue";
import HighlightedSection, {
  HighlightedSectionData
} from "@/components/HighlightedSection.vue";
import venuesApi from "@/api/venues";
import {cartStore, embeddedStore} from "@/store";
import {GetExhibitionsRequest, GetExhibitionsResponse} from "@/types/api";
import exhibitionsApi from "@/api/exhibitions";
import {exhibitionHighlightedData} from "@/utils/highlighted-section";
import {copyCurrentRouteClipboard} from "@/utils/clipboard";
import {EventKeys} from "@/store/modules/embedded";
import {parseBaseContentText, venueHoursOpenCloseList} from "@/utils/data";
import ItemSlideGroup from "@/components/ItemSlideGroupV2.vue";
import {TranslateResult} from "vue-i18n";
import {openMobileLinkFor} from "@/utils/mobileLink";
import GMap, {GMapProps} from "@/components/GMap.vue";
import {
  EventCategory,
  EventsResult,
  GetEventsRequest,
  GetEventTicketsRequest
} from "@/types/api.newviz/events";
import eventsApi from "@/api.newviz/events";
import {VEvent, VizEventTickets} from "@/models/events";
import {ImageAPI} from "@/utils/image-utils";
import PickTicket, {PickTicketProps} from "@/components/PickTicket.vue";
import {VCart} from "@/models/cart";
import {format} from "date-fns";

export interface TabItem {
  title: string | TranslateResult;
  route: string;
}

@Component({
  components: {
    HighlightedSection,
    VideoCard,
    ItemSlideGroupV2,
    GUIDImage,
    GMap,
    ItemSlideGroup,
    PickTicket
  }
})
export default class VenuePage extends Vue {
  likeFeature = false;
  currentEntraceEvent: VEvent | null = null;
  currentEntraceEventTickets: VizEventTickets[] = [];

  currentVenue: VenueV2 | null = null;
  venueExhibitions: ExhibitionV2[] = [];
  showMedia = false;
  eventsTicketsList: VEvent[] = [];

  exhibitionsTake = 20;

  eventsImageAPI = ImageAPI.NewViz;

  @Watch("$route.params.id")
  onIdChange() {
    window.scrollTo(0, 0);
    this.loadApiData();
  }

  shareLink() {
    copyCurrentRouteClipboard(this.$route);
  }

  get pickTicketProps(): PickTicketProps | undefined {
    if (!this.currentEntraceEvent) {
      return undefined;
    }
    return {
      event: this.currentEntraceEvent,
      eventTickets: this.currentEntraceEventTickets,
      onUpdatePickerDate: async (pickerDate: string) => {
        await this.loadEntranceEventTickets(false, {month: pickerDate});
      },
      onAddCart: async (selectedEvent: VEvent) => {
        await VCart.createOrAdd(selectedEvent);
      },
      onCurrencyUpdate: async () => {
        await this.loadEntranceEventTickets(true);
      }
    };
  }

  openMobile() {
    openMobileLinkFor({
      type: VizguTypeLink.Venue,
      id: this.currentVenue?.id || ""
    });
  }

  goEventPage(v: VEvent) {
    embeddedStore.setCurrentEvent(v); // TODO remove once get event by id api available
    this.$router.push(`/events/${v.eventId}`);
  }

  // labels
  get loadingLabel() {
    return this.$t("Loading...");
  }
  get notFoundLabel() {
    return this.$t("Not found");
  }
  // eof labels

  get hSections() {
    return {first: this.firstHighlightedSection};
  }

  get firstHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.venueExhibitions?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      content: exhibitionHighlightedData(v)
    });
  }

  get exhibitionsTitle(): string {
    return `${this.$t("All exhibitons for")} ${this.venueName} !`;
  }

  get venueImgSrc(): string {
    return this.currentVenue?.imageGuids.$values[0] || "";
  }

  get venueName(): string {
    return this.currentVenue?.name || "";
  }

  get openingHours(): string[] {
    // return ["Monday - Saturday: 9:00 AM - 6:00 PM", "Sunday: closed"];
    return this.currentVenue ? venueHoursOpenCloseList(this.currentVenue) : [];
  }

  get openingInfo(): string | null {
    return this.currentVenue?.openingInfo?.displayText || null;
  }

  get venueLocation(): string | null {
    const address = this.currentVenue?.address;
    const country = ", " + this.currentVenue?.country || "";
    if (address && country) {
      return `${address}${country}`;
    }
    return null;
  }

  get todayOpenClose(): string | null {
    const open = this.currentVenue?.openingHours?.wednesday?.substring(0, 5);
    const close = this.currentVenue?.openingHours?.wednesdayClose?.substring(
      0,
      5
    );
    if (open && close) {
      return `${open} - ${close}`;
    }
    return null;
  }

  get tabItems(): TabItem[] {
    return [
      {
        title: this.$t("Tickets"),
        route: "#tickets"
      },
      {
        title: this.$t("Events"),
        route: "#events"
      },
      {
        title: this.$t("About"),
        route: "#about"
      },
      {
        title: this.$t("exhibitions"),
        route: "#exhibitions"
      },
      {
        title: this.$t("media"),
        route: "#media"
      },
      {
        title: this.$t("location"),
        route: "#location"
      }
    ];
  }

  get description(): string {
    return this.currentVenue ? parseBaseContentText(this.currentVenue) : "";
  }

  get isVenueLoading() {
    return embeddedStore.currentVenueLoading;
  }

  initListeners(enable: boolean) {
    if (enable) {
      window.addEventListener(EventKeys.locale, this.onLocaleEvent);
    } else {
      window.removeEventListener(EventKeys.locale, this.onLocaleEvent);
    }
  }

  onLocaleEvent() {
    this.loadApiData();
  }

  get venueMapPoint() {
    return this.currentVenue?.position;
  }

  get mapConfig(): GMapProps | undefined {
    if (!this.venueMapPoint) {
      return undefined;
    }
    const mapStyles: google.maps.MapTypeStyle = {
      stylers: []
    };
    const mapPoints: MapPoint[] = [
      {
        lat: this.venueMapPoint.latitude || 0,
        lng: this.venueMapPoint.longitude || 0,
        title: this.currentVenue?.name || ""
      }
    ];

    return {
      apiKey: "AIzaSyAWkPa63y66oU6Mzfkrnmc77Ns_nbH6eoI", // TODO add to env or get from api?
      mapPoints,
      input: "",
      mapStyles: [mapStyles],
      height: "370px",
      zoom: 16
    };
  }

  // lifecycle methods
  async created() {
    this.initListeners(true);
    await this.loadApiData();
  }
  beforeDestroy() {
    this.initListeners(false);
  }
  // eof lifecycle methods
  private async loadApiData() {
    embeddedStore.setCurrentVenueLoading(true);
    embeddedStore.setCurrentVenue(null);
    const id: string = this.$route.params.id;
    await this.requestVenue(id);

    await Promise.all([
      this.loadVenueEvents(),
      this.getSliderExhibitions(),
      this.loadVenueEntranceEvent()
    ]);

    await this.loadEntranceEventTickets();
  }

  // data load methods
  private async requestVenue(id: string) {
    const resp: VenueV2 = await venuesApi.getVenue(id);
    this.currentVenue = resp;
    embeddedStore.setCurrentVenue(resp);
    embeddedStore.setCurrentVenueLoading(false);
  }

  private async getSliderExhibitions() {
    const params: GetExhibitionsRequest = {
      skip: 0,
      take: this.exhibitionsTake,
      venueID: this.currentVenue?.id
    };
    const resp: GetExhibitionsResponse = await exhibitionsApi.getExhibitions(
      params
    );
    this.venueExhibitions = resp.list.$values;
  }

  private async loadVenueEvents() {
    const params: GetEventsRequest = {
      // orgId: this.currentVenue?.id
      query: {
        venue: this.currentVenue?.id,
        category: [
          EventCategory.EntranceTicket,
          EventCategory.EntranceTicketKombi
        ].join(","),
        buyCurrency: cartStore.currentCurrency
      }
    };
    const resp: EventsResult = await eventsApi.getEvents(params);
    this.eventsTicketsList = resp.map(v => new VEvent(v));
  }

  private async loadVenueEntranceEvent() {
    const params: GetEventsRequest = {
      query: {
        venue: this.currentVenue?.id,
        buyCurrency: cartStore.currentCurrency
      }
    };
    const resp: EventsResult = await eventsApi.getEvents(params);
    const items = resp.map(v => new VEvent(v));
    items.length > 0 && (this.currentEntraceEvent = items[0]);
  }

  private async loadEntranceEventTickets(
    replace = false,
    params: Partial<GetEventTicketsRequest> = {}
  ) {
    if (!this.currentEntraceEvent) {
      return;
    }

    const currentDate = format(new Date(), "yyy-MM-dd");
    const hasMonth = (date: string) => {
      const dFmt = format(new Date(date), "yyy-M");
      return (
        this.currentEntraceEventTickets.find(v => v.month == dFmt) != undefined
      );
    };

    if (replace) {
      this.currentEntraceEventTickets = [];
    }

    if (!replace && this.currentEntraceEventTickets) {
      if (params.month && hasMonth(params.month)) {
        return;
      } else if (!params.month && hasMonth(currentDate)) {
        return;
      }
    }
    const query: GetEventTicketsRequest = {
      buyCurrency: cartStore.currentCurrency,
      ...params
    };
    const resp = await eventsApi.getEventTickets(
      this.currentEntraceEvent?.eventId,
      query
    );
    this.currentEntraceEventTickets.push({
      month: resp.month,
      series: resp.series.map(v => new VEvent(v))
    });
  }
}
