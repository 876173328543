import {ApiClient} from "./client";
import {GetCurrenciesResult} from "@/types/api.newviz/currency";

class CurrencyApi extends ApiClient {
  public getCurrencies = async (): Promise<GetCurrenciesResult> => {
    const {data} = await this.client.get<GetCurrenciesResult>(`/currencies`, {
      baseURL: this.currentApi
    });
    return data;
  };
}

const currencyApi = new CurrencyApi();
export default currencyApi;
