
















import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
export default class Rating extends Vue {
  @Prop() withNumber!: boolean;
}
