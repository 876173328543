import {BaseDataV2, VenueV2} from "@/types/vizgu";
import i18n from "@/plugins/i18n";

export {Country, COUNTRIES, TCountry} from "./data/countries";

export function parseXmlDataToString(v: string) {
  v = v.replace(/\s*<.*?>\s*/g, "");
  v = v.replace("\n", "<br></br");
  return v;
}

export function parseBaseContentText(d: BaseDataV2): string {
  if (d.description && d.description != "") {
    return d.description;
  }
  const textDataList = d.content?.cdTexts;
  if (textDataList) {
    const items = textDataList.$values.map(v =>
      parseXmlDataToString(v.textData)
    );
    return items.join("<br></br>");
  }
  return "";
}

const Day = {
  Monday: i18n.t("Monday"),
  Tuesday: i18n.t("Tuesday"),
  Wednesday: i18n.t("Wednesday"),
  Thursday: i18n.t("Thursday"),
  Friday: i18n.t("Friday"),
  Saturday: i18n.t("Saturday"),
  Sunday: i18n.t("Sunday")
};

export function venueHoursOpenCloseNormalize(v: string) {
  return v;
}

export function venueHoursOpenCloseList(currentVenue: VenueV2): string[] {
  const nrm = (v: string) => venueHoursOpenCloseNormalize(v);
  const res: string[] = [];
  const hrs = currentVenue.openingHours;
  if (!hrs) {
    return [];
  }
  hrs.monday &&
    hrs.mondayClose &&
    res.push(`${Day.Monday}: ${nrm(hrs.monday)} - ${nrm(hrs.mondayClose)}`);
  hrs.tuesday &&
    hrs.tuesdayClose &&
    res.push(`${Day.Tuesday}: ${nrm(hrs.tuesday)} - ${nrm(hrs.tuesdayClose)}`);
  hrs.wednesday &&
    hrs.wednesdayClose &&
    res.push(
      `${Day.Wednesday}: ${nrm(hrs.wednesday)} - ${nrm(hrs.wednesdayClose)}`
    );
  hrs.thursday &&
    hrs.thursdayClose &&
    res.push(
      `${Day.Thursday}: ${nrm(hrs.thursday)} - ${nrm(hrs.thursdayClose)}`
    );
  hrs.friday &&
    hrs.fridayClose &&
    res.push(`${Day.Friday}: ${nrm(hrs.friday)} - ${nrm(hrs.fridayClose)}`);
  hrs.saturday &&
    hrs.saturdayClose &&
    res.push(
      `${Day.Saturday}: ${nrm(hrs.saturday)} - ${nrm(hrs.saturdayClose)}`
    );
  hrs.sunday &&
    hrs.sundayClose &&
    res.push(`${Day.Sunday}: ${nrm(hrs.sunday)} - ${nrm(hrs.sundayClose)}`);
  return res;
}

export function venueTodayOpenClose(currentVenue: VenueV2): string | null {
  // TODO: improve further
  const open = currentVenue?.openingHours?.wednesday?.substring(0, 5);
  const close = currentVenue?.openingHours?.wednesdayClose?.substring(0, 5);
  const l = i18n.t("Open today");
  if (open && close) {
    return `${l} ${open} - ${close}`;
  }
  // opening info
  const openingInfo = currentVenue?.openingInfo?.displayText;
  return openingInfo || null;
}
