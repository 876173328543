import {LocaleString, LOCALES} from "@/plugins/i18n";
import {embeddedStore} from "@/store";

export function stringFromLocale(v: LocaleString): string {
  const currentLocale = embeddedStore.currentLocale;
  if (Object.keys(v).includes(currentLocale.code)) {
    return v[currentLocale.code];
  } else {
    return Object.values(v)[0];
  }
}

export function stringFromLocaleSpecific(
  v: LocaleString,
  l: LOCALES
): string | undefined {
  if (!Object.keys(v).includes(l)) {
    return undefined;
  }
  return v[l];
}
