



























































































































import {Component, Vue} from "vue-property-decorator";
import {TabItem} from "@/views/Venue.vue";
import LocaleSwitchSelect from "../LocaleSwitchSelect.vue";
import {isMobile} from "@/utils/dom";

@Component({
  components: {LocaleSwitchSelect}
})
export default class Navbar extends Vue {
  drawer = false;
  showNavbar = true;
  lastScrollPosition = 0;
  height = "84";

  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize, {passive: true});
    this.onResize();
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    this.height = isMobile() ? "34" : "84";
  }

  onScroll() {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollPosition < 0) {
      return;
    }

    if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
      return;
    }
    this.showNavbar = currentScrollPosition < this.lastScrollPosition;
    this.lastScrollPosition = currentScrollPosition;
  }

  get menuItems(): TabItem[] {
    return [
      {
        title: "Vizgu museum platform",
        route: "/vizgu-platform"
      },
      {
        title: "For content creators",
        route: "/for-content-creators"
      },
      {
        title: "Vizgu village",
        route: "/vizgu-village"
      },
      {
        title: "About us",
        route: "/about"
      },
      /*
      {
        title: "Vizgu platform",
        route: "/platform"
      },
      */
      /*
      {
        title: "Pricing & Licencing",
        route: "/pricing"
      },
     */
      {
        title: "Careers",
        route: "/careers"
      }
    ];
  }

  navigate() {
    if (this.$route.path == "/") {
      this.$vuetify.goTo(0);
    } else {
      this.$router.push("/");
    }
  }
}
