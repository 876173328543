import {LOCALES} from "@/plugins/i18n";

export enum CartItemType {
  Event = "event"
}

export interface VizCart {
  id: string;
  items: CartItem[];
  fees: [];
  totalPrice: {
    net: number;
    gross: number;
    tax: number;
  };
  itemsTotalQuantity: number;
  forcedTotalPrice: number | null;
}

export interface CartItem {
  id: string;
  type: CartItemType;
  totalPrice: number;
  totalQuantity: number;
  event: CartItemEvent;
  product: unknown | null;
  membership: unknown | null;
  shipping: unknown | null;
}

export interface CartItemEvent {
  id: string;
  name: string;
  category: string;
  dateTime: string;
  items: CartItemEventItems[];
}

export interface CartItemEventItems {
  id: string;
  name: string;
  price: number;
  totalPrice: number;
  quantity: number;
}

export type AddCartItem = {
  ticketId?: string;
  quantity: number;
};

export type AddCartInstance = {
  type: CartItemType;
  eventId?: string;
  items: AddCartItem[];
};

export type CreateCartRequest = {
  added: AddCartInstance;
  language: LOCALES;
  buyCurrency: string;
};

export type AddToCartRequest = CreateCartRequest & {
  cartId: string;
};

export type RemoveFromCartRequest = {
  cartId: string;
  itemId: string;
};

export type GetCartByIdRequest = {
  cartId: string;
};

export type RecalculateCartRequest = {
  cartId: string;
  currency: string;
  query?: {
    orgId: string;
  };
};

export enum CheckoutCategory {
  Private = "Private"
}

export type CheckoutFormData = {
  category: CheckoutCategory;
  firstName: string;
  lastName: string;
  phoneCode: string;
  phoneNumber: string;
  address: string;
  email: string;
  postalCode: string;
  city: string;
  country: string;
  comment: string;
  acceptPolicy?: boolean;
};

export function defaultCheckoutFormData(): CheckoutFormData {
  return {
    category: CheckoutCategory.Private,
    firstName: "",
    lastName: "",
    phoneCode: "",
    phoneNumber: "",
    address: "",
    email: "",
    postalCode: "",
    city: "",
    country: "Denmark",
    comment: "",
    acceptPolicy: false
  };
}
