






































































































































































import hubspotApi from "@/api/hubspot";
import {Component, Vue} from "vue-property-decorator";
@Component
export default class ContactContainer extends Vue {
  emailFormSent = false;
  isOpen = {
    sendEmail: false
  };

  emailForm = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    issue: ""
  };

  resetEmailForm() {
    this.emailForm = {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      issue: ""
    };
  }

  toggleSendEmail() {
    this.isOpen.sendEmail = !this.isOpen.sendEmail;
  }

  onEmailSend() {
    // this.validateForm()
    // if (this.isFormValid)
    this.sendForm();
  }

  get formData(): Array<Record<string, string>> {
    return [
      {name: "firstName", value: this.emailForm.firstName},
      {name: "lastName", value: this.emailForm.lastName},
      {name: "email", value: this.emailForm.email},
      // {name: "company", value: this.emailForm.company},
      {name: "issue", value: this.emailForm.issue}
    ];
  }

  async sendForm() {
    const result = await hubspotApi.submitForm(this.formData);
    if (result.status == 200) {
      this.emailFormSent = true;
      this.resetEmailForm();
    } else {
      // handle error
    }
  }
}
