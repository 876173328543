import {Route} from "vue-router";
import {embeddedStore as context} from "@/store";

export function copyClipboard(msg: string) {
  navigator.clipboard.writeText(msg);
}

export function copyCurrentRouteClipboard(r: Route) {
  const msg = `${window.location.origin}${r.fullPath}`;
  copyClipboard(msg);
  context.setClipboardSnackbar(true);
}
