





























import {Component, Prop, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import {TranslateResult} from "vue-i18n";
import {isMobile} from "@/utils/dom";

export interface BasePageBannerData {
  imgUid: string;
  icon?: string;
  title?: string | TranslateResult;
  subtitle?: string | TranslateResult;
}

const defaultSubtitleClass = "mt-7 text-white text-2xl md:text-3xl max-w-3xl";

@Component({
  components: {GUIDImage}
})
export default class BasePageBanner extends Vue {
  @Prop({required: true, type: Object}) data!: BasePageBannerData;
  @Prop({required: false, type: String, default: defaultSubtitleClass})
  subtitleClass!: BasePageBannerData;

  get isMobile() {
    return isMobile();
  }

  get style() {
    return {
      iconSize: this.isMobile ? "20" : "24"
    };
  }

  get bannerIcon(): string {
    return this.data.icon || "";
  }

  get bannerTitle(): TranslateResult {
    return this.data.title || "";
  }

  get bannerSubtitle(): TranslateResult {
    return this.data.subtitle || "";
  }
}
