import {BaseDataV2, VizguType} from "@/types/vizgu";

export function getItemsHrefFromBaseData(d: BaseDataV2, t?: VizguType): string {
  const vt = t || d.vizguType || "";
  switch (vt) {
    default: {
      return "";
    }
    case VizguType.Exhibition: {
      return `/exhibitions`;
    }
    case VizguType.Venue: {
      return `/venues`;
    }
    case VizguType.City: {
      return `/cities`;
    }
    case VizguType.Wayfinder: {
      return `/wayfinders`;
    }
  }
}

export function getItemHrefFromBaseData(d: BaseDataV2, t?: VizguType): string {
  const vt = t || d.vizguType || "";
  const id = d.extra || d.id;
  switch (vt) {
    default: {
      return "";
    }
    case VizguType.Exhibition: {
      return `/exhibitions/${id}`;
    }
    case VizguType.Venue: {
      return `/venues/${id}`;
    }
    case VizguType.City: {
      return `/city/${id}`;
    }
    case VizguType.Wayfinder: {
      return `/wayfinders/${id}`;
    }
  }
}
