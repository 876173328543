import HttpClient from "./client";
import {GetGeneralDataTitleSearch} from "@/types/api";

class SearchApi extends HttpClient {
  public generalDataTitleSearch = async (
    search: string
  ): Promise<GetGeneralDataTitleSearch> => {
    const {data} = await this.client.get<GetGeneralDataTitleSearch>(
      "/search/",
      {
        params: {text: search}
      }
    );
    data.$values = [
      ...data.$values
        .reduce((a, c) => {
          a.set(c.id, c);
          return a;
        }, new Map())
        .values()
    ];
    return data;
  };
}

const searchApi = new SearchApi();
export default searchApi;
