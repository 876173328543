export function parseDateToString(date: Date, monthShort = true): string {
  const day = date.getDate();
  const month = date.toLocaleString("en-Us", {
    month: monthShort ? "short" : "long"
  });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
}

// TODO
export function fmtVizEventDate() {
  return;
}

export function dateFromVizDate(v: string) {
  return new Date(v);
}

export function dateTimeLabelFromDate(v: string | Date, tz?: string) {
  const d = typeof v === "string" ? dateFromVizDate(v) : v;
  d.setTime;
  return d.toLocaleString("en-GB", {timeZone: tz});
}

export function parseTimestamp(t: number) {
  const minutes = Math.floor(t / (1000 * 60)).toString();
  const seconds = (t % (1000 * 60)).toString().slice(0, 2);
  return {minutes, seconds};
}
