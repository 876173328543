import {Ticket, TicketType} from "@/types/api.newviz/tickets";
import {LocaleString, LOCALES} from "@/plugins/i18n";
import {stringFromLocale, stringFromLocaleSpecific} from "@/utils/i18n";

export class VTicket implements Ticket {
  name!: string;
  translatedNames!: LocaleString;
  quantity!: number;
  age!: string; // TODO enum?
  price!: number;
  vat!: number;
  totalPrice!: number;
  type!: TicketType;
  sellRestrictions: unknown; // TODO

  // locale fields
  count = 0;

  get nameLocale(): string {
    return stringFromLocale(this.translatedNames);
  }

  nameDefaultOrCurrentLocale(l?: LOCALES): string {
    if (!l) {
      return this.nameLocale;
    }
    return stringFromLocaleSpecific(this.translatedNames, l) || this.nameLocale;
  }

  constructor(v: Partial<Ticket>) {
    Object.assign(this, v);
  }

  add() {
    this.count += 1;
  }
  remove() {
    this.count -= 1;
  }

  // TODO
  get ticketPrice() {
    return this.price;
  }

  get priceWithCount() {
    return this.count > 0 ? this.ticketPrice * this.count : this.ticketPrice;
  }

  isPaid() {
    return this.type == TicketType.Paid;
  }

  isFree() {
    return this.type == TicketType.Free;
  }
}
