import {
  LocaleString,
  Location,
  Image,
  LocaleValue,
  VizDate,
  VizInterval
} from "./common";
import {LOCALES} from "@/plugins/i18n";
import {Tickets} from "./tickets";

export enum EventType {
  Public = "Public"
}

export enum EventCategory {
  Vernissage = "Vernissage",
  Guide = "Guide",
  Concert = "Concert",
  Request = "Request",
  RequestLocationRent = "RequestLocationRent",
  RequestWithPayment = "RequestWithPayment",
  EntranceTicket = "EntranceTicket",
  EntranceTicketKombi = "EntranceTicketKombi",
  GroupedTicketMin15People = "GroupedTicketMin15People",
  GroupedTicketKombiMin15People = "GroupedTicketKombiMin15People",
  IntroductionMax40People = "IntroductionMax40People",
  GuidedTourMax15People = "GuidedTourMax15People",
  CombiTicket5p5 = "CombiTicket5p5",
  BookResercher = "BookResercher",
  SchoolClass = "SchoolClass"
}

export type Experience = {
  summary: string;
  description: string;
  mainImage: Image;
};

export type EventInfo = {
  bring: string[];
  notAllowed: string[];
  additional: unknown[]; // TODO
  includes: unknown[]; // TODO
  audio: unknown[]; // TODO
  duration: string;
};

export type Occurrence = {
  id: string;
  start: VizDate;
  end: VizDate;
  allDay: boolean;
  recurring: boolean;
  repeat: {
    periodicity: string;
    daysOfWeek: unknown[]; // TODO
  };
  interval: VizInterval;
};

export type GeneralInfo = {
  name: LocaleString;
  type: EventType;
  category: EventCategory;
  display: string;
  languages: LOCALES[];
  venues: string[];
  location: Location;
  schoolClasses: unknown[]; // TODO
};

export interface VizEvent {
  eventId: string;
  generalInfo?: GeneralInfo;
  experience?: LocaleValue<Experience>;
  eventInfo?: EventInfo;
  occurrence: Occurrence[] | Occurrence;
  tickets: Tickets;
  defaultLanguage?: LOCALES;
  duration: string;
}

export type GetEventsRequest = {
  orgId?: string;
  query?: {
    venue?: string;
    category?: string;
    buyCurrency?: string;
  };
};

export type GetEventDetailsRequest = {
  id: string;
  query?: {
    buyCurrency?: string;
  };
};

export type GetOrgEventsRequest = {
  orgId: string;
};

export type GetEventTicketsRequest = {
  month?: string;
  buyCurrency?: string;
};

export type EventsResult = VizEvent[];

export type EventTicketsResult = {
  month: string;
  series: VizEvent[];
};
