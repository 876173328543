var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"inner-container my-10"},[_c('div',{staticClass:"flex flex-col md:flex-row gap-10 text-2xs w-full"},[_c('div',{staticClass:"cursor-pointer",on:{"click":_vm.navigate}},[_c('v-icon',{staticClass:"hidden md:block md:pr-4 md:justify-start md:self-center",attrs:{"size":"84"}},[_vm._v("vizgu-logo")])],1),_c('div',{staticClass:"flex flex-col md:w-1/6 space-y-2"},[_c('div',{staticClass:"font-medium mb-2 whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.languageCurrencyLabel)+" ")]),_c('div',{staticClass:"flex flex-row md:flex-col space-x-2 md:space-x-0 md:space-y-2"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"flex-1 md:flex-none",attrs:{"depressed":"","color":"rounded-none bg-dark text-white"}},'v-btn',attrs,false),on),[_c('div',{staticClass:"flex flex-row items-center justify-between"},[_c('div',{staticClass:"text-2xs"},[_vm._v(" "+_vm._s(_vm.currentLocale.name)+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" menu-down ")])],1)])]}}])},[_c('v-list',_vm._l((_vm.locales),function(locale){return _c('v-list-item',{key:locale.code,class:[
                'cursor-pointer',
                _vm.currentLocale.code == locale.code ? 'bg-gray' : ''
              ],on:{"click":function($event){return _vm.setLocale(locale)}}},[_c('v-list-item-title',[_vm._v(_vm._s(locale.name))])],1)}),1)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"flex-1 md:flex-none",attrs:{"depressed":"","color":"rounded-none bg-dark text-white"}},'v-btn',attrs,false),on),[_c('div',{staticClass:"flex flex-row items-center justify-between"},[_c('div',{staticClass:"text-2xs"},[_vm._v(" "+_vm._s(_vm.currentCurrency)+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" menu-down ")])],1)])]}}])},[_c('v-list',{staticClass:"overflow-y-scroll",attrs:{"max-height":"200"}},_vm._l((_vm.currencies),function(currency){return _c('v-list-item',{key:currency,class:[
                'cursor-pointer',
                currency == _vm.currentCurrency ? 'bg-gray' : ''
              ],on:{"click":function($event){return _vm.setCurrency(currency)}}},[_c('v-list-item-title',[_vm._v(_vm._s(currency))])],1)}),1)],1)],1)]),_c('div',{staticClass:"flex flex-col md:w-1/6 space-y-2"},[_c('div',{staticClass:"font-medium mb-2"},[_vm._v(_vm._s(_vm.mobileAppLabel))]),_c('div',{staticClass:"flex flex-row md:flex-col space-x-2 md:space-x-0 md:space-y-2"},[_c('v-btn',{staticClass:"flex-1 md:flex-none",attrs:{"depressed":"","color":"rounded-none bg-dark text-white"},on:{"click":function($event){return _vm.goAppStoreApp()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" apple ")]),_c('div',{staticClass:"text-tiny font-raleway whitespace-pre-line text-left"},[_vm._v(" "+_vm._s(_vm.appStoreText)+" ")])],1),_c('v-btn',{staticClass:"flex-1 md:flex-none",attrs:{"depressed":"","color":"rounded-none bg-dark text-white"},on:{"click":function($event){return _vm.goGooglePlayApp()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" google-mono ")]),_c('div',{staticClass:"text-tiny font-raleway whitespace-pre-line text-left"},[_vm._v(" "+_vm._s(_vm.googlePlayText)+" ")])],1)],1)]),_vm._l((_vm.secondaryRoutes),function(v,i){return _c('div',{key:i,staticClass:"hidden md:block"},[_c('div',{staticClass:"font-medium mb-2"},[_c('div',{},[_vm._v(" "+_vm._s(v.title)+" ")])]),_vm._l((v.routes),function(item){return _c('div',{key:item.title},[_c('router-link',{staticClass:"text-primary no-underline",attrs:{"to":v.routeLink + item.route}},[_vm._v(" "+_vm._s(item.title))])],1)})],2)}),_c('v-expansion-panels',{staticClass:"block md:hidden",attrs:{"accordion":""}},_vm._l((_vm.secondaryRoutes),function(v,i){return _c('v-expansion-panel',{key:i,staticClass:"border-solid border-0 border-b-2 border-black no-shadow"},[_c('v-expansion-panel-header',{staticClass:"pt-0 pb-0 px-0 min-h-0 border-none",attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var open = ref.open;
return [_c('div',{staticClass:"flex gap-6 items-center justify-between py-3"},[_c('div',{staticClass:"w-full font-normal text-lg font-medium"},[_vm._v(" "+_vm._s(v.title)+" ")]),_c('v-icon',{class:{
                  'rotate-180 transform': open,
                  'w-8 h-8 text-black': true
                }},[_vm._v("menu-down")])],1)]}}],null,true)}),_c('v-expansion-panel-content',{staticClass:"py-3"},_vm._l((v.routes),function(item){return _c('div',{key:item.title},[_c('router-link',{staticClass:"text-primary no-underline text-lg",attrs:{"to":v.routeLink + item.route}},[_vm._v(" "+_vm._s(item.title))])],1)}),0)],1)}),1),_c('div',{staticClass:"md:pl-4 md:justify-end md:self-center"},[_c('div',{staticClass:"flex flex-row space-x-3 md:justify-end mb-3"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("facebook")]),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("instagram")]),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("linkedin")])],1),_c('div',{staticClass:"flex flex-row md:flex-col whitespace-nowrap"},[_c('div',{staticClass:"md:text-right"},[_vm._v("© Copyright vizgu 2024.")]),_c('div',{staticClass:"md:text-right"},[_vm._v(_vm._s(_vm.weLoveOurUsersLabel))])])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }