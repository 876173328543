







































































































































































































import {Component, Vue} from "vue-property-decorator";
import {TabItem} from "@/views/Venue.vue";
import {
  supportSubpages,
  companySubpages,
  workWithUsSubpages
} from "@/utils/footer";
import {localeList, SiteLocale} from "@/plugins/i18n";
import {cartStore} from "@/store";

@Component({
  components: {}
})
export default class Footer extends Vue {
  routes = [
    this.$t("Contact"),
    this.$t("Legal Notice"),
    this.$t("Privacy Policy"),
    this.$t("Terms and Conditions"),
    this.$t("Sitemap"),
    this.$t("About us"),
    this.$t("Careers"),
    this.$t("Become supplier"),
    this.$t("Become partner")
  ];

  secondaryRoutes = [
    {title: this.supportLabel, routes: supportSubpages, routeLink: "/support"},
    {title: this.companyLabel, routes: companySubpages, routeLink: ""},
    {
      title: this.workWithUsLabel,
      routes: this.workWithUsSubpages,
      routeLink: ""
    }
  ];

  appStoreAppHref = "https://apps.apple.com/us/app/vizgu/id1034370823";
  googlePlayAppHref =
    "https://play.google.com/store/apps/details?id=com.vizgu.vizguapp";
  // labels
  get languageCurrencyLabel() {
    return this.$t("Language & currency");
  }
  get mobileAppLabel() {
    return this.$t("Mobile app");
  }
  get supportLabel() {
    return this.$t("Support");
  }
  get companyLabel() {
    return this.$t("Company");
  }
  get workWithUsLabel() {
    return this.$t("Work with us");
  }
  get weLoveOurUsersLabel() {
    return this.$t("We love our users!");
  }
  get getItOnLabel() {
    return this.$t("Get it on");
  }
  // eof labels

  get locales(): SiteLocale[] {
    return localeList;
  }
  get currentLocale(): SiteLocale {
    return localeList.find(l => l.code === this.$i18n.locale) || localeList[0];
  }

  setLocale(locale: SiteLocale) {
    this.$i18n.locale = locale.code;
  }

  get currencies(): Array<string> {
    return cartStore.currencies;
  }

  get currentCurrency(): string {
    return cartStore.currentCurrency;
  }

  setCurrency(currency: string) {
    cartStore.setCurrentCurrency(currency);
    cartStore.cacheCurrency();
    if (cartStore.cart) {
      cartStore.cart.recalculateCurrency();
    }
  }

  get supportSubpages(): TabItem[] {
    return supportSubpages;
  }

  get companySubpages(): TabItem[] {
    return companySubpages;
  }

  get workWithUsSubpages(): TabItem[] {
    return workWithUsSubpages;
  }

  get googlePlayText(): string {
    return `${this.getItOnLabel} \n Google Play`;
  }

  get appStoreText(): string {
    return `${this.getItOnLabel} \n App store`;
  }

  goAppStoreApp() {
    window.location.href = this.appStoreAppHref;
  }

  goGooglePlayApp() {
    window.location.href = this.googlePlayAppHref;
  }

  navigate() {
    if (this.$route.path == "/") {
      this.$vuetify.goTo(0);
    } else {
      this.$router.push("/");
    }
  }
}
