






import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {},
  props: {
    icon: String
  }
})
export default class Icon extends Vue {
  //empty
}
