import {LocaleString} from "./common";

export enum TicketType {
  Paid = "Paid",
  Free = "Free"
}

export interface Ticket {
  name: string;
  translatedNames: LocaleString;
  quantity: number;
  age: string; // TODO enum?
  price: number;
  vat: number;
  totalPrice: number;
  type: TicketType;
  sellRestrictions: unknown; // TODO
}

export interface Tickets {
  capacity: number;
  available: number;
  list: Ticket[];
}
