































































import paymentApi from "@/api.newviz/payment";
import {VizOrder} from "@/types/api.newviz/payment";
import {Component, Vue} from "vue-property-decorator";
@Component({components: {}})
export default class CheckoutProcess extends Vue {
  currentOrder: VizOrder | null = null;
  loading = {
    global: false
  };
  get styles() {
    return {
      icon: {
        // sizeIcon: isMobile() ? "50" : "120"
      }
    };
  }

  get isLoading() {
    return this.loading.global;
  }

  get canShowOrder() {
    return !this.loading.global && this.currentOrder != null;
  }

  // TODO get info from order objects instance
  get orderInfo() {
    const o = this.currentOrder;
    return {
      number: o?.orderNumber,
      email: o?.sentToEmail,
      createdDateTime: o?.event.dateTime,
      name: o?.event.name
    };
  }

  get labels() {
    return {
      orderNum: "Order nr. ",
      thankForPurchase: "THANK YOU FOR YOUR PURCHASE!",
      orderConfirmed:
        "Your order is confirmed. We will send you an order confirmation to your E-mail shortly",
      orderInfo: "Order Information",
      created: "Created",
      paymentMethod: "Payment method",
      totalAmount: "Total amount"
    };
  }

  async mounted() {
    await this.loadData();
  }

  async loadData() {
    const orderId = this.$route.params?.["orderId"];
    if (!orderId) {
      return;
    }
    try {
      this.loading.global = true;
      const order = await paymentApi.getOrderById({orderId});
      this.currentOrder = order;
    } finally {
      this.loading.global = false;
    }
  }
}
