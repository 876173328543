import HttpClient from "./client";
import {
  GetVenuesResponse,
  GetVenuesRequest,
  GetVenueExhibtionsResponse
} from "@/types/api";
import {VenueV2} from "@/types/vizgu";
import {embeddedStore} from "@/store";

class VenuesApi extends HttpClient {
  public getVenues = async (
    params: GetVenuesRequest
  ): Promise<GetVenuesResponse> => {
    params.language = embeddedStore.apiLocaleFromI18n;
    const {data} = await this.client.get<GetVenuesResponse>("/venue/list", {
      params: params
    });
    return data;
  };
  public getVenuesByIds = async (params: {
    venueIds: string[];
  }): Promise<GetVenuesResponse> => {
    // params.language = embeddedStore.apiLocaleFromI18n;
    const {data} = await this.client.post<GetVenuesResponse>(
      "/venue/multiple",
      params.venueIds
    );
    return data;
  };
  public getVenue = async (id: string): Promise<VenueV2> => {
    const {data} = await this.client.get<VenueV2>("/venue", {
      params: {
        language: embeddedStore.apiLocaleFromI18n,
        id: id
      }
    });
    return data;
  };

  public getVenueExhibitions = async (
    id: string
  ): Promise<GetVenueExhibtionsResponse> => {
    const {data} = await this.client.get<GetVenueExhibtionsResponse>(
      "/venue/exhibitions",
      {
        params: {
          language: embeddedStore.apiLocaleFromI18n,
          id: id
        }
      }
    );
    return data;
  };
}

const venuesApi = new VenuesApi();
export default venuesApi;
