
























































import {Component, Vue} from "vue-property-decorator";
import Rating from "@/components/Rating.vue";
import AvatarGroup from "@/components/AvatarGroup.vue";

@Component({
  components: {AvatarGroup, Rating}
})
export default class ExhibitionCard extends Vue {}
