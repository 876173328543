
































































import {Component, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import BasePageBanner, {
  BasePageBannerData
} from "@/components/BasePageBanner.vue";
import ItemSlideGroup from "@/components/ItemSlideGroupV2.vue";
import eventsApi from "@/api.newviz/events";
import {EventsResult, GetEventsRequest} from "@/types/api.newviz/events";
import {GetVenuesResponse} from "@/types/api";
import venuesApi from "@/api/venues";
import {VenueV2} from "@/types/vizgu";
import {cartStore, embeddedStore} from "@/store";
import {VEvent} from "@/models/events";
import ListItems from "@/components/ListItemsV2.vue";
import {ImageAPI} from "@/utils/image-utils";

@Component({
  components: {ItemSlideGroup, BasePageBanner, GUIDImage, ListItems}
})
export default class Tickets extends Vue {
  eventImageAPI = ImageAPI.NewViz;
  itemWidth = 200;
  loading = {
    initial: false,
    events: false
  };
  eventsTicketsList: VEvent[] = [];
  eventsVenues: VenueV2[] = [];
  get baseBannerData(): BasePageBannerData {
    return {
      imgUid: "d2c04f18-0490-4419-9f4b-622a90fd59d6",
      icon: "ticket-white",
      title: this.$t("Tickets"),
      subtitle: this.$t(
        "Find and buy tickets to interesting venues & exciting events all around the world!"
      )
    };
  }

  get isLoading() {
    return this.loading.initial;
  }

  goEventPage(v: VEvent) {
    embeddedStore.setCurrentEvent(v); // TODO remove once get event by id api available
    this.$router.push(`/events/${v.eventId}`);
  }

  venueForEvent(e: VEvent): VenueV2 | undefined {
    const matched = this.eventsVenues.find(v =>
      e.generalInfo?.venues.includes(v.id)
    );
    if (!matched) {
      return undefined;
    }
    return matched;
  }

  venueImage(v?: VenueV2) {
    if (!v) {
      return "d2c04f18-0490-4419-9f4b-622a90fd59d6";
    }
    return v.imageGuids.$values[0];
  }

  created() {
    this.loadInitialData();
  }

  // data load methods
  private async loadInitialData() {
    this.loading.initial = true;
    await this.requestEvents();
    // await this.requestEventsVenues();
    this.loading.initial = false;
  }

  private async requestEvents() {
    // TODO cleanup & finalize
    const params: GetEventsRequest = {
      query: {
        buyCurrency: cartStore.currentCurrency
      }
    };
    const resp: EventsResult = await eventsApi.getEvents(params);
    this.eventsTicketsList = resp.map(v => new VEvent(v));
  }

  private async requestEventsVenues() {
    // TODO cleanup & finalize
    const venueIds: string[] = [];
    this.eventsTicketsList.forEach(
      e =>
        e.generalInfo &&
        !venueIds.includes(e.generalInfo.venues[0]) &&
        venueIds.push(e.generalInfo.venues[0])
      /*
        e.generalInfo.venues.forEach(id => {
          !venueIds.includes(id) && venueIds.push(id);
        })
        */
    );
    if (venueIds.length == 0) {
      return;
    }
    const params = {venueIds: venueIds};
    const resp: GetVenuesResponse = await venuesApi.getVenuesByIds(params);
    this.eventsVenues = resp.list.$values;
  }
}
