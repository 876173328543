






























































import {Component, Prop, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import {BaseData} from "@/types/vizgu";
import {ItemTuple} from "@/types/homepage";

@Component({
  components: {GUIDImage}
})
export default class ItemSlideGroup extends Vue {
  @Prop() dataList!: ItemTuple<BaseData>[];
  @Prop() title!: string;
  @Prop({type: Boolean, required: false, default: true}) useSubtitle!: boolean;
  @Prop({type: Boolean, required: false, default: false}) useLocation!: boolean;
  @Prop() itemWidth!: number;
  @Prop({type: String, required: false, default: "venue"}) cardTopIcon!: number;
  @Prop({type: Number, required: false, default: 20}) iconSize!: number;
  @Prop({type: String, required: false, default: ""}) viewAllHref!: string;

  // labels
  get viewAllLabel() {
    return this.$t("view all");
  }
  artworksLabel(e: BaseData) {
    const c = e.artworksCount;
    if (!c) {
      return "";
    }
    const l = this.$t("artworks");
    return `${c} ${l}`;
  }
  get awayLabel() {
    return this.$t("away");
  }
  // eof labels

  handleItemHref(item: ItemTuple<BaseData>) {
    this.$emit("onItemHref", item);
  }

  goViewAllPage() {
    this.$router.push(this.viewAllHref);
  }
}
