











import {Component, Vue} from "vue-property-decorator";
import Navbar from "@/components/vizguaps/Navbar.vue";
import Footer from "@/components/vizguaps/Footer.vue";

@Component({
  components: {Footer, Navbar}
})
export default class VizguApSLayout extends Vue {}
