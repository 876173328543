




















































































































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import VideoFrame from "@/components/vizguaps/VideoFrame.vue";
import PlatformFAQ from "@/components/PlatformFAQ.vue";
import BookDemo from "@/components/vizguaps/BookDemo.vue";
import {isMobile} from "@/utils/dom";

@Component({
  components: {
    VideoFrame,
    BookDemo,
    PlatformFAQ
  }
})
export default class ForContentCreators extends Vue {
  mediaPath(file: string) {
    return require(`@/assets/images/for-creators/${file}`);
  }

  get isMobile() {
    return isMobile();
  }

  get videoHeight() {
    return this.isMobile ? "auto" : "1000";
  }

  media = {
    section1: {
      desktop1: this.mediaPath("section1-1-desktop.png"),
      mobile1: this.mediaPath("section1-1-mobile.png"),
      mobile2: this.mediaPath("section1-2-mobile.png")
    },
    section2: {
      desktop1: this.mediaPath("section2-1-desktop.png"),
      mobile1: this.mediaPath("section2-1-mobile.png"),
      mobile2: this.mediaPath("section2-2-mobile.png")
    },
    section3: {
      desktop1: this.mediaPath("section3-1-desktop.png"),
      mobile1: this.mediaPath("section3-1-mobile.png")
    },
    section4: {
      desktop1: this.mediaPath("section4-1-desktop.png"),
      desktop2: this.mediaPath("section4-2-desktop.png"),
      desktop3: this.mediaPath("section4-3-desktop.png"),
      desktop4: this.mediaPath("section4-4-desktop.png"),
      mobile1: this.mediaPath("section4-1-mobile.png"),
      mobile2: this.mediaPath("section4-2-mobile.png"),
      mobile3: this.mediaPath("section4-3-mobile.png")
    },
    section5: {
      desktop1: this.mediaPath("section5-1-desktop.png"),
      mobile1: this.mediaPath("section5-1-mobile.png")
    },
    section6: {
      desktop1: this.mediaPath("section6-1-desktop.png"),
      mobile1: this.mediaPath("section6-1-mobile.png")
    },
    hands: this.mediaPath("hands.svg")
  };
}
