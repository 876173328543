




































































import {Component, Prop, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import {BaseDataV2, VizguType} from "@/types/vizgu";
// import {ItemTuple} from "@/types/homepage";
import {getItemHrefFromBaseData} from "@/utils/routing";
import {isMobile} from "@/utils/dom";
import {ImageAPI} from "@/utils/image-utils";
import CardItemSkeleton from "./CardItemSkeleton.vue";

@Component({
  components: {GUIDImage, CardItemSkeleton}
})
export default class ListItems extends Vue {
  @Prop() dataList!: BaseDataV2;
  @Prop({required: false, type: Boolean, default: false}) loading!: boolean;
  @Prop() title!: string;
  @Prop({type: Boolean, required: false, default: true}) useSubtitle!: boolean;
  @Prop({type: Boolean, required: false, default: false}) useLocation!: boolean;
  @Prop() itemWidth!: number;
  @Prop({type: String, required: false, default: "venue"}) cardTopIcon!: number;
  @Prop({type: Boolean, default: true}) useVizguTypeRouting!: boolean;
  @Prop({default: undefined, required: false}) contentType!: VizguType;
  @Prop({default: undefined, required: false}) imageSrcHandler!: (
    el: BaseDataV2 | any
  ) => string;
  @Prop({required: false, default: undefined}) imageAPI?: ImageAPI;
  @Prop({required: false, default: 0}) appendLoadngSkeletons!: number;

  itemImage(v: BaseDataV2) {
    if (this.imageSrcHandler) {
      return this.imageSrcHandler(v);
    }
    return v?.imageGuids?.$values?.[0] || null;
  }

  get imageHeight(): number {
    return isMobile() ? 110 : 150;
  }

  handleItemHref(item: BaseDataV2) {
    if (this.useVizguTypeRouting) {
      const r = getItemHrefFromBaseData(item, this.contentType);
      this.$router.push(r);
    } else {
      this.$emit("onItemHref", item);
    }
  }
}
