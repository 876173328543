























































































































































































































import {Component, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import EmptyCart from "@/components/EmptyCart.vue";
import {VTicket} from "@/models/ticket";
import {cartStore, embeddedStore} from "@/store";
import {VCart, VCartItem} from "@/models/cart";
import CartItem from "@/components/CartItem.vue";
@Component({components: {GUIDImage, EmptyCart, CartItem}})
export default class Cart extends Vue {
  placeholderImageUid = "e17fb04a-e32f-4041-92f1-abf608dbb73c";
  coupon = "";

  loading = {
    global: false
  };

  get ticketsCurrency() {
    return cartStore.currentCurrency;
  }

  setGlobalLoading() {
    this.loading.global = true;
  }

  unsetGlobalLoading() {
    this.loading.global = false;
  }

  get isLoading() {
    return this.loading.global;
  }

  get timeNow(): Date {
    return embeddedStore.timeNow;
  }

  get cart(): VCart | null {
    return cartStore.cart;
  }

  async removeCartItem(item: VCartItem) {
    this.setGlobalLoading();
    try {
      await this.cart?.removeItem(item.id);
    } catch (e) {
      cartStore.setCurrentCart(null);
    } finally {
      this.unsetGlobalLoading();
    }
  }

  // TODO
  tickets: VTicket[] = [];

  get styles() {
    return {
      title: {
        letterSpacing: "0.3rem"
      }
    };
  }

  get labels() {
    return {
      holdTime: this.$t("We’ll hold your ticket spot for"),
      reviewOrder: this.$t("Review your order"),
      product: this.$t("Product")
        .toString()
        .toLowerCase(),
      adults: "Adults",
      child: "Child",
      senior: "Senior"
    };
  }

  get cartRemainTimeLabel() {
    const timeLeftLabel = this.cart?.timeLeftLabel || "";
    return `<span class="font-light">${this.labels.holdTime}</span> - <span class="font-medium">${timeLeftLabel} minutes</span>`;
  }

  get reviewOrderLabel() {
    const count = this.cart?.items.length || 0;
    return `${this.labels.reviewOrder} (${count} ${this.labels.product})`;
  }

  paymentImagePath(name: string) {
    const path = `@/assets/images/payments/${name}`;
    return require(path);
  }
  get payments() {
    return [
      {icon: require("@/assets/images/payments/visa.png")},
      {icon: require("@/assets/images/payments/master-1.png")},
      {icon: require("@/assets/images/payments/master-2.png")},
      {icon: require("@/assets/images/payments/nets.png")},
      {icon: require("@/assets/images/payments/dk.png")},
      {icon: require("@/assets/images/payments/mobile-pay.png")}
    ];
  }

  // TODO
  get cartTotalAmount() {
    const price = this.cart?.totalPrice.gross || 0;
    return `${price} ${this.ticketsCurrency}`;
  }

  continueShoppingClick() {
    // TODO checks here
    this.$router.push("/tickets");
  }

  goCheckoutClick() {
    // TODO checks here
    this.$router.push("/checkout");
  }
}
