




















import {Component, Vue} from "vue-property-decorator";
import ListItems from "@/components/ListItemsV2.vue";
import {GetGeneralDataTitleSearch} from "@/types/api";
import {BaseDataV2} from "@/types/vizgu";
import searchApi from "@/api/search";
import {getItemHrefFromBaseData} from "@/utils/routing";

@Component({
  components: {ListItems}
})
export default class Search extends Vue {
  itemWidth = 200;
  isLoadingData = false;
  inputSearch = "";
  resultsList: BaseDataV2[] = [];

  goHrefItemPage(item: BaseDataV2) {
    const link = getItemHrefFromBaseData(item);
    this.$router.push(link);
  }

  // lifecycle methods
  created() {
    this.inputSearch = this.$route.query.q.toString() || "";
    if (this.inputSearch.trim().length > 0) {
      this.requestDataTitleSearch();
    }
  }
  // data load methods
  async requestDataTitleSearch() {
    try {
      this.isLoadingData = true;
      const resp: GetGeneralDataTitleSearch = await searchApi.generalDataTitleSearch(
        this.inputSearch
      );
      this.resultsList = resp.$values;
    } catch (e) {
      // TODO handle error
    } finally {
      this.isLoadingData = false;
    }
  }
}
