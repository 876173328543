import {
  VizEvent,
  Experience,
  EventInfo,
  Occurrence,
  GeneralInfo
} from "@/types/api.newviz/events";
import {LocaleValue} from "@/types/api.newviz/common";
import {LOCALES} from "@/plugins/i18n";
import {dateTimeLabelFromDate} from "@/utils/datetime";
import {VTicket} from "./ticket";
import {stringFromLocale} from "@/utils/i18n";

export type VizEventTickets = {
  month: string;
  series: VEvent[];
};

export type VTickets = {
  capacity: number;
  available: number;
  list: VTicket[];
};

export class VEvent implements VizEvent {
  eventId!: string;
  generalInfo?: GeneralInfo;
  experience?: LocaleValue<Experience>;
  eventInfo?: EventInfo;
  occurrence!: Occurrence[] | Occurrence;
  tickets!: VTickets;
  defaultLanguage?: LOCALES;
  duration!: string;

  private PLACEHOLDER_IMAGE = "d2c04f18-0490-4419-9f4b-622a90fd59d6";

  constructor(v: Partial<VizEvent>) {
    Object.assign(this, v);
    if (v.tickets) {
      v.tickets.list = v.tickets.list.map(t => new VTicket(t));
    }
  }

  get name(): string {
    // TODO improve
    const name = this.generalInfo?.name;
    return name ? stringFromLocale(name) : "";
  }

  get date(): string | undefined {
    return this.firstOccurrence?.start?.date;
  }

  get mainImage(): string {
    const first = Object.values(this?.experience || {})?.[0];
    return first?.mainImage?.fileId || this.PLACEHOLDER_IMAGE;
  }

  get firstOccurrence() {
    return Array.isArray(this.occurrence)
      ? this.occurrence[0]
      : this.occurrence;
  }

  get startDate() {
    return this.firstOccurrence.start.date;
  }

  startDateTimeLabel() {
    return dateTimeLabelFromDate(
      this.firstOccurrence.start.moment,
      this.firstOccurrence.start.tzId
    );
  }

  get locationLabel() {
    return this.generalInfo?.location.address || "";
  }

  get selectTimeLabel() {
    // TODO translation
    const allDayLabel = "All day";
    return this.firstOccurrence.allDay
      ? allDayLabel
      : this.firstOccurrence.start.time;
  }
}
