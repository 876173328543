

























import {Component, Prop, Vue} from "vue-property-decorator";
import {bigImageUri, ImageAPI} from "@/utils/image-utils";
import Vibrant from "node-vibrant";

@Component({
  components: {}
})
export default class GUIDImage extends Vue {
  @Prop() imageUid!: string;
  @Prop({required: false, default: undefined}) imageAPI?: ImageAPI;
  @Prop() height!: number;
  @Prop({required: false, default: "100%"})
  width!: number | string;
  @Prop() rounded!: boolean;
  @Prop({type: Boolean, required: false, default: false}) darken!: boolean;

  errorLoadingImage = false;
  vibrantColor = "";
  defaultColor = "#E5E3ED";

  get imgClass() {
    return [this.rounded ? "rounded-3xl" : "rounded-none"];
  }

  get imageUri() {
    return bigImageUri(this.imageUid, this.imageAPI) || "";
  }

  get imageContainerStyles() {
    return {
      backgroundColor: this.vibrantColor
    };
  }

  mounted() {
    Vibrant.from(this.imageUri).getPalette((err, palette) => {
      const prominentColor = palette?.Vibrant?.getHex();
      this.vibrantColor = prominentColor ? prominentColor : this.defaultColor;
    });
  }
}
