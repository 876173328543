
















import {Component, Vue, Ref, Prop} from "vue-property-decorator";

@Component({
  components: {}
})
export default class VideoFrame extends Vue {
  @Prop({required: false, default: undefined}) customClass?: string;
  @Prop({required: true}) url!: string;
  @Prop({default: 320}) width!: number | string;
  @Prop({default: 200}) height!: number | string;

  @Ref("videoRef") videoRef!: HTMLVideoElement;

  isMounted = false;

  get videoProps() {
    return {
      width: this.width,
      height: this.height,
      url: this.url
    };
  }

  async playVideo() {
    if (!this.videoRef) {
      return;
    }
    // this.videoRef
    this.videoRef.autoplay = true;
    this.videoRef.loop = true;
    this.videoRef.controls = false;
    await this.videoRef.play();
  }

  async mounted() {
    this.isMounted = true;

    await this.playVideo();

    console.log("video ref is", this.videoRef);
  }
}
