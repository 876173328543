import HttpClient from "./client";
import {GetExhibitionsResponse, GetExhibitionsRequest} from "@/types/api";
import {ExhibitionV2} from "@/types/vizgu";
import {embeddedStore} from "@/store";

class ExhibitionsApi extends HttpClient {
  public getCurrentExhibitions = async (
    params: GetExhibitionsRequest
  ): Promise<GetExhibitionsResponse> => {
    const {data} = await this.client.get<GetExhibitionsResponse>(
      "/exhibition/ListCurrent",
      {params: params}
    );
    return data;
  };
  public getExhibitions = async (
    params: GetExhibitionsRequest
  ): Promise<GetExhibitionsResponse> => {
    params.language = embeddedStore.apiLocaleFromI18n;
    const {data} = await this.client.get<GetExhibitionsResponse>(
      "/exhibition/list",
      {params: params}
    );
    return data;
  };
  public getFutureExhibitions = async (): Promise<GetExhibitionsResponse> => {
    const {data} = await this.client.get<GetExhibitionsResponse>(
      "/exhibition/ListFuture"
    );
    return data;
  };
  public getPopularExhibitions = async (): Promise<GetExhibitionsResponse> => {
    const {data} = await this.client.get<GetExhibitionsResponse>(
      "/exhibition/ListPopular"
    );
    return data;
  };
  public getExhibition = async (id: string): Promise<ExhibitionV2> => {
    const {data} = await this.client.get<ExhibitionV2>("/exhibition", {
      params: {
        language: embeddedStore.apiLocaleFromI18n,
        id: id
      }
    });
    return data;
  };
}

const exhibitionsApi = new ExhibitionsApi();
export default exhibitionsApi;
