









































import {Component, Vue, Prop} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import Vibrant from "node-vibrant";
import {bigImageUri} from "@/utils/image-utils";
import {TranslateResult} from "vue-i18n";

const defaultImgSrc = "e17fb04a-e32f-4041-92f1-abf608dbb73c";

export class HighlightedSectionData {
  imageSrc = "e17fb04a-e32f-4041-92f1-abf608dbb73c";
  content: string | TranslateResult = "";
  imageTooltipText: string | TranslateResult = "Popular!";
  imageTopIcon = "place-location";

  constructor(params: Partial<HighlightedSectionData> = {}) {
    Object.assign(this, params);
  }
}

@Component({
  components: {GUIDImage}
})
export default class HighlightedSection extends Vue {
  @Prop({type: Boolean, required: false, default: false})
  hideImageTooltip!: boolean;
  @Prop({type: String, required: false, default: defaultImgSrc})
  imgSrc!: string;
  @Prop({
    type: HighlightedSectionData,
    required: true,
    default: () => new HighlightedSectionData()
  })
  data!: HighlightedSectionData;

  vibrantColor = "";
  defaultColor = "#E5E3ED";

  mounted() {
    Vibrant.from(this.imageUrl).getPalette((_err, palette) => {
      const prominentColor = palette?.Vibrant?.getHex();
      this.vibrantColor = prominentColor ? prominentColor : this.defaultColor;
    });
  }

  get imageTooltipText() {
    return this.data.imageTooltipText;
  }
  get imageTopIcon() {
    return this.data.imageTopIcon;
  }

  get imageUrl(): string {
    return bigImageUri(this.data.imageSrc) || "";
  }

  get backgroundStyle(): {[key: string]: string} {
    return {
      background: `linear-gradient(to right, ${
        this.vibrantColor
      }, ${this.lighterColor(this.vibrantColor)})`
    };
  }

  lighterColor(color: string): string {
    return color + "80";
  }
}
