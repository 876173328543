import Vue from "vue";
import VueRouter, {RouteConfig, Route} from "vue-router";
import Places from "@//views/Places.vue";
import Venues from "@//views/Venues.vue";
import Events from "@//views/Events.vue";
import Wayfinders from "@//views/Wayfinders.vue";
import Exhibitions from "@//views/Exhibitions.vue";
import Artworks from "@//views/Artworks.vue";
import Artists from "@//views/Artists.vue";
import Cities from "@//views/Cities.vue";
import Countries from "@//views/Countries.vue";
import Home from "@//views/Home.vue";
import Venue from "@/views/Venue.vue";
import Exhibition from "@/views/Exhibition.vue";
import Event from "@/views/Event.vue";
import Wayfinder from "@/views/Wayfinder.vue";
import Artwork from "@/views/Artwork.vue";
import Artist from "@/views/Artist.vue";
import Support from "@/views/footer/Support.vue";
import GiftCard from "@/views/footer/GiftCard.vue";
import BecomePartner from "@/views/footer/BecomePartner.vue";
import AboutUs from "@/views/vizguAps/AboutUs.vue";
import Careers from "@/views/vizguAps/Careers.vue";
import Platform from "@/views/vizguAps/PlatformV3.vue";
import {embeddedStore} from "@/store";
import Country from "@/views/Country.vue";
import City from "@/views/City.vue";
import Search from "@/views/Search.vue";
import Tickets from "@/views/Tickets.vue";
import Cart from "@/views/Cart.vue";
import Checkout from "@/views/Checkout.vue";
import Payment from "@/views/PaymentV2.vue";
import CheckoutProcess from "@/views/CheckoutProcess.vue";
import VizguPlatform from "@/views/vizguAps/VizguPlatform.vue";
import ForContentCreators from "@/views/vizguAps/ForContentCreators.vue";
import VizguVillage from "@/views/vizguAps/VizguVillage.vue";

Vue.use(VueRouter);

const publicRoutes: Array<RouteConfig> = [
  {
    path: "/",
    // name: "Home",
    // component: Home,
    // meta: {
    //   layout: "FrontPageLayout"
    // }
    redirect: "/vizgu-platform"
  },
  {
    path: "/places",
    name: "Destinations",
    component: Places,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/venues",
    name: "Places",
    component: Venues,
    meta: {
      layout: "FrontPageLayout",
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {name: "Home"},
            href: "/"
          },
          {
            text: "Venues",
            to: {name: "Venues"},
            href: "/venues",
            disabled: true
          }
        ];
      }
    }
  },
  {
    path: "/venues/:id",
    name: "Venue",
    component: Venue,
    meta: {
      layout: "FrontPageLayout",
      breadCrumb(route: Route) {
        const id: string | undefined = route.params?.id;
        return [
          {
            text: "Home",
            to: {name: "Home"},
            href: "/"
          },
          {
            text: "Venues",
            to: {name: "Venues"},
            href: "/venues"
          },
          {
            text: embeddedStore.currentVenueName,
            to: {name: "Venue"},
            href: `/venues/${id || ""}`,
            disabled: true
          }
        ];
      }
    }
  },
  {
    path: "/exhibitions",
    name: "Exhibitions",
    component: Exhibitions,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/exhibitions/:id",
    name: "Exhibition",
    component: Exhibition,
    meta: {
      layout: "FrontPageLayout",
      breadCrumb(route: Route) {
        const id: string | undefined = route.params?.id;
        return [
          {
            text: "Home",
            to: {name: "Home"},
            href: "/"
          },
          {
            text: "Exhibitions",
            to: {name: "Exhibitions"},
            href: "/exhibitions"
          },
          {
            text: embeddedStore.currentExhibition?.name || "Loading...",
            to: {name: "Exhibition"},
            href: `/exhibitions/${id || ""}`,
            disabled: true
          }
        ];
      }
    }
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/events/:id",
    name: "Event",
    component: Event,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  /*
  {
    path: "/event",
    name: "Event",
    component: Event,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  */
  {
    path: "/wayfinders",
    name: "Wayfinders",
    component: Wayfinders,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/wayfinders/:id",
    name: "Wayfinder",
    component: Wayfinder,
    meta: {
      layout: "FrontPageLayout",
      breadCrumb(route: Route) {
        const id: string | undefined = route.params?.id;
        return [
          {
            text: "Home",
            to: {name: "Home"},
            href: "/"
          },
          {
            text: "Wayfinders",
            to: {name: "Wayfinders"},
            href: "/wayfinders"
          },
          {
            text: embeddedStore.currentWayfinder?.name || "Loading...",
            to: {name: "Wayfinder"},
            href: `/wayfinders/${id || ""}`,
            disabled: true
          }
        ];
      }
    }
  },
  {
    path: "/artworks",
    name: "Artworks",
    component: Artworks,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/artwork",
    name: "Artwork",
    component: Artwork,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/artists",
    name: "Artists",
    component: Artists,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/cities",
    name: "Cities",
    component: Cities,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/artist",
    name: "Artist",
    component: Artist,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/countries",
    name: "Countries",
    component: Countries,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/country/:id",
    name: "Country",
    component: Country,
    meta: {
      layout: "FrontPageLayout",
      breadCrumb(route: Route) {
        const id: string | undefined = route.params?.id;
        return [
          {
            text: "Home",
            to: {name: "Home"},
            href: "/"
          },
          {
            text: "Countries",
            to: {name: "Countries"},
            href: "/countries"
          },
          {
            text: embeddedStore.currentVenueCountry,
            to: {name: "Venue"},
            href: `/country/${id || ""}`,
            disabled: true
          }
        ];
      }
    }
  },
  {
    path: "/city/:id",
    name: "City",
    component: City,
    meta: {
      layout: "FrontPageLayout",
      breadCrumb(route: Route) {
        const id: string | undefined = route.params?.id;
        return [
          {
            text: "Home",
            to: {name: "Home"},
            href: "/"
          },
          {
            text: "Cities",
            to: {name: "Cities"},
            href: "/cities"
          },
          {
            text: embeddedStore.currentVenueCity,
            to: {name: "Venue"},
            href: `/city/${id || ""}`,
            disabled: true
          }
        ];
      }
    }
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/vizgu-platform",
    name: "Vizgu museum platform",
    component: VizguPlatform,
    meta: {
      layout: "VizguApSLayout"
    }
  },
  {
    path: "/for-content-creators",
    name: "For content creators",
    component: ForContentCreators,
    meta: {
      layout: "VizguApSLayout"
    }
  },

  {
    path: "/vizgu-village",
    name: "Vizgu village",
    component: VizguVillage,
    meta: {
      layout: "VizguApSLayout"
    }
  },
  {
    path: "/about",
    name: "About Us",
    component: AboutUs,
    meta: {
      layout: "VizguApSLayout"
    }
  },
  /*
  {
    path: "/pricing",
    name: "Pricing & Licencing",
    component: PricingPage,
    meta: {
      layout: "VizguApSLayout"
    }
  },
    */
  {
    path: "/careers",
    name: "Careers",
    component: Careers,
    meta: {
      layout: "VizguApSLayout"
    }
  },
  {
    path: "/platform",
    name: "Vizgu platform",
    component: Platform,
    meta: {
      layout: "VizguApSLayout"
    }
  },
  {
    path: "/become-partner",
    name: "Become partner",
    component: BecomePartner,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/giftcards",
    name: "Gift cards",
    component: GiftCard,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: Tickets,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/payment/:orderId",
    name: "Payment",
    component: Payment,
    meta: {
      layout: "FrontPageLayout"
    }
  },
  {
    path: "/checkout-process/:orderId",
    name: "Checkout Process",
    component: CheckoutProcess,
    meta: {
      layout: "FrontPageLayout"
    }
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [...publicRoutes],
  scrollBehavior: () => {
    return {selector: "#v", position: {top: 0}};
  }
});

export default router;
