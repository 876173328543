












































































































import BasePageBanner, {
  BasePageBannerData
} from "@/components/BasePageBanner.vue";
import ListItemsV2 from "@/components/ListItemsV2.vue";
// import {ItemTuple} from "@/types/homepage";
import {BaseDataV2, VenueV2} from "@/types/vizgu";
import {Component, Vue} from "vue-property-decorator";
import HighlightedSection, {
  HighlightedSectionData
} from "@/components/HighlightedSection.vue";
import SelectSlider from "@/components/SelectSlider.vue";
import venuesApi from "@/api/venues";
import {GetVenuesRequest, GetVenuesResponse} from "@/types/api";
import {venueHighlightedData} from "@/utils/highlighted-section";
import {EventKeys} from "@/store/modules/embedded";
import {venueTodayOpenClose} from "@/utils/data";

@Component({
  components: {BasePageBanner, ListItemsV2, HighlightedSection, SelectSlider}
})
export default class Venues extends Vue {
  totalItems = 0;
  perPage = 20;
  itemWidth = 200;
  initialDataLoaded = false;

  venuesList: VenueV2[] = [];
  popularVenue: VenueV2 | null = null;

  loaders = {
    loadingMoreVenues: false
  };

  get loadMoreSkeletonsCount() {
    return this.loaders.loadingMoreVenues ? 10 : 0;
  }

  l = this.$t("Open today");

  venueLocation(currentVenue: VenueV2): string | null {
    // const address = currentVenue?.address || "";
    // const country = ", " + currentVenue?.country || "";
    // return `${address}${country}`;
    return currentVenue?.country || null;
  }

  get hasMore() {
    return this.totalItems > this.venuesList.length;
  }

  get popularDescription(): string | null {
    return this.popularVenue?.description || null;
  }

  get popularCity(): string | null {
    return this.popularVenue?.city || null;
  }

  get popularImgSrc(): string | undefined {
    return this.popularVenue?.imageGuids.$values[0];
  }

  get hSections() {
    return {
      first: this.firstHighlightedSection,
      second: this.secondHighlightedSection
    };
  }

  get firstHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.venuesList?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      content: venueHighlightedData(v)
    });
  }

  get secondHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.venuesList?.[1];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      imageTooltipText: "New!",
      content: venueHighlightedData(v)
    });
  }

  /*
  exampleVenue: ItemTuple<BaseData> = {
    item1: {
      name: "Koldinghus",
      position: null,
      canReview: false,
      extra: null,
      like: null,
      id: "123",
      imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
      isLocked: true,
      rating: 4.5,
      thumbImageURL: null,
      vizguType: VizguType.Venue
    },
    item2: 1
  };
  */

  venueOpenCloseDate(currentVenue: VenueV2) {
    return venueTodayOpenClose(currentVenue);
  }

  /*
  get venuesData(): ItemTuple<BaseData>[] {
    // sample data for now
    const data: ItemTuple<BaseData>[] = [];
    for (let i = 0; i < 10; i++) {
      const item: ItemTuple<BaseData> = JSON.parse(
        JSON.stringify(this.exampleVenue)
      );
      item.item1.id = `venue-${i}`;
      data.push(item);
    }
    return data;
  }
  */

  get venuesDataV2(): BaseDataV2[] {
    return this.venuesList;
  }

  get baseBannerData(): BasePageBannerData {
    return {
      imgUid: "fdab8a01-0184-485d-8373-19989bf550f5",
      icon: "venue",
      title: this.$t("Venues"),
      subtitle: this.$t(
        "Find all the coolest venues in the world and see all the art and culture they have to offer"
      )
    };
  }

  goVenuePage(item: BaseDataV2) {
    this.$router.push(`/venues/${item.extra || item.id}`);
  }

  initListeners(enable: boolean) {
    if (enable) {
      window.addEventListener(EventKeys.locale, this.onLocaleEvent);
    } else {
      window.removeEventListener(EventKeys.locale, this.onLocaleEvent);
    }
  }

  onLocaleEvent() {
    this.loadInitialData();
  }

  // lifecycle methods
  created() {
    this.initListeners(true);
    this.loadInitialData();
  }
  beforeDestroy() {
    this.initListeners(false);
  }

  // data load methods
  private async loadInitialData() {
    await this.requestVenues(true);
    this.initialDataLoaded = true;
  }

  private async requestVenues(replace = false) {
    if (replace && !this.hasMore && this.initialDataLoaded) {
      return;
    }
    try {
      !replace && (this.loaders.loadingMoreVenues = true);
      const params: GetVenuesRequest = {
        skip: this.venuesList.length,
        take: this.perPage
      };
      const resp: GetVenuesResponse = await venuesApi.getVenues(params);
      this.totalItems = resp.count;
      if (replace) {
        this.venuesList = resp.list.$values;
      } else {
        this.venuesList = this.venuesList.concat(resp.list.$values);
      }
    } finally {
      this.loaders.loadingMoreVenues = false;
    }
  }
}
