import {env} from "@/env";

export enum ImageAPI {
  VizguAzuredgePublicdet = "VizguAzuredgePublicdet",
  VizguAzuredgeThumb = "VizguAzuredgeThumb",
  NewViz = "NewViz"
}

export const imageAPIMap = {
  VizguAzuredgePublicdet: (id: string) =>
    `https://vizgu.azureedge.net/publicdet/${id}`,
  VizguAzuredgeThumb: (id: string) => `https://vizgu.azureedge.net/thumb/${id}`,
  NewViz: (id: string) => `${env.API.newViz}/images/content/${id}/master`
};

export function thumbnailImageUri(
  imageUid: string,
  imageAPI = ImageAPI.VizguAzuredgeThumb
): string | undefined {
  return imageAPIMap[imageAPI](imageUid);
}

export function bigImageUri(
  imageUid: string,
  imageAPI = ImageAPI.VizguAzuredgePublicdet
): string | undefined {
  return imageAPIMap[imageAPI](imageUid);
}
