import {TabItem} from "@/views/Venue.vue";
import i18n from "@/plugins/i18n";

export const supportSubpages: TabItem[] = [
  {
    title: i18n.t("Contact"),
    route: "#contact"
  },
  {
    title: i18n.t("Legal Notice"),
    route: "#legal-notice"
  },
  {
    title: i18n.t("Privacy Policy"),
    route: "#privacy-policy"
  },
  {
    title: i18n.t("Terms and Conditions"),
    route: "#terms"
  },
  {
    title: i18n.t("Sitemap"),
    route: "#sitemap"
  }
];

export const companySubpages: TabItem[] = [
  {
    title: i18n.t("About us"),
    route: "/about"
  },
  {
    title: i18n.t("Careers"),
    route: "/careers"
  }
  /*
  {
    title: i18n.t("Press"),
    route: "/press"
  },
  {
    title: i18n.t("Gift cards"),
    route: "/giftcards"
  },
  {
    title: i18n.t("Magazine"),
    route: "/"
  }
  */
];

export const workWithUsSubpages: TabItem[] = [
  {
    title: i18n.t("Become supplier"),
    route: "/"
  },
  {
    title: i18n.t("Become partner"),
    route: "/become-partner"
  }
];
