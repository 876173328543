













































































































import {PaymentFormData} from "@/views/Payment.vue";
import {Component, Vue, Prop} from "vue-property-decorator";

enum PaymentMethod {
  Card,
  Mobile
}

@Component({components: {}})
export default class PaymentForm extends Vue {
  @Prop({required: true}) formData!: PaymentFormData;

  PaymentMethod = PaymentMethod;
  selectedPayment = PaymentMethod.Card;

  get isPayCard() {
    return this.selectedPayment == PaymentMethod.Card;
  }
  get isPayMobile() {
    return this.selectedPayment == PaymentMethod.Mobile;
  }

  get labels() {
    return {
      addCard: this.$t("Add card"),
      mobilePay: this.$t("MobilePay"),
      cardNumber: this.$t("Card number"),
      expiry: this.$t("Expiry (mm/yy)")
    };
  }

  get cardPayments() {
    return [
      {icon: require("@/assets/images/payments/visa.png")},
      {icon: require("@/assets/images/payments/master-1.png")},
      {icon: require("@/assets/images/payments/master-2.png")},
      {icon: require("@/assets/images/payments/dk.png")}
    ];
  }

  get mobilePayments() {
    return [{icon: require("@/assets/images/payments/mobile-pay.png")}];
  }
}
