

































import {localeList, SiteLocale} from "@/plugins/i18n";
import {Component, Vue} from "vue-property-decorator";
import {embeddedStore} from "@/store";
@Component({
  components: {}
})
export default class LocaleSwitchSelect extends Vue {
  get locales(): SiteLocale[] {
    return localeList;
  }
  get currentLocale(): SiteLocale {
    return (
      localeList.find(l => l.code === embeddedStore.i18n.locale) ||
      localeList[0]
    );
  }
  setLocale(locale: SiteLocale) {
    embeddedStore.setLocale(locale.code);
    // this.$i18n.locale = locale.code;
  }
}
