var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasePageBanner',{attrs:{"data":_vm.baseBannerData}}),(false)?_c('SelectSlider'):_vm._e(),_c('div',{staticClass:"flex flex-wrap gap-3 justify-between mt-16 inner-container"},[_c('h2',{staticClass:"text-4xl"},[_vm._v(" "+_vm._s(_vm.$t("All exhibitions"))+" ")]),(false)?_c('div',[_c('v-btn',{staticClass:"bg-dark text-white rounded-none w-28 h-10",attrs:{"depressed":""}},[_vm._v(" "+_vm._s(_vm.$t("Sort by"))+" "),_c('v-icon',{staticClass:"text-white ml-1",attrs:{"x-small":""}},[_vm._v("$dropdown")])],1),_c('v-btn',{staticClass:"bg-dark text-white rounded-none w-28 h-10 ml-3",attrs:{"depressed":""}},[_vm._v(" "+_vm._s(_vm.$t("Filters"))+" ")])],1):_vm._e()]),_c('div',{staticClass:"mt-16"},[_c('ListItemsV2',{key:"1",attrs:{"loading":!_vm.initialDataLoaded,"data-list":_vm.exhibitionsDataV2 && _vm.exhibitionsDataV2.slice(0, 3),"title":_vm.$t('Countries'),"item-width":_vm.itemWidth,"card-top-icon":"exhibition"},scopedSlots:_vm._u([{key:"subtitle",fn:function(ref){
var element = ref.element;
return [_vm._v(" "+_vm._s(_vm.exhibitionDatePeriod(element))+" ")]}},{key:"location",fn:function(ref){
var element = ref.element;
return [_vm._v(" "+_vm._s(_vm.exhibitionLocation(element))+" ")]}}])})],1),(_vm.hSections.first)?_c('div',{staticClass:"my-16"},[_c('HighlightedSection',{attrs:{"data":_vm.hSections.first}})],1):_vm._e(),_c('div',[_c('ListItemsV2',{key:"1",attrs:{"data-list":_vm.exhibitionsDataV2 && _vm.exhibitionsDataV2.slice(3, 6),"title":_vm.$t('Countries'),"item-width":_vm.itemWidth,"card-top-icon":"exhibition"},scopedSlots:_vm._u([{key:"subtitle",fn:function(ref){
var element = ref.element;
return [_vm._v(" "+_vm._s(_vm.exhibitionDatePeriod(element))+" ")]}},{key:"location",fn:function(ref){
var element = ref.element;
return [_vm._v(" "+_vm._s(_vm.exhibitionLocation(element))+" ")]}}])})],1),(_vm.hSections.second)?_c('div',{staticClass:"mt-16"},[_c('HighlightedSection',{attrs:{"data":_vm.hSections.second}})],1):_vm._e(),_c('div',{staticClass:"mt-12"},[_c('ListItemsV2',{key:"1",attrs:{"data-list":_vm.exhibitionsDataV2 && _vm.exhibitionsDataV2.slice(6),"title":_vm.$t('Countries'),"item-width":_vm.itemWidth,"appendLoadngSkeletons":_vm.loadMoreSkeletonsCount,"card-top-icon":"exhibition"},scopedSlots:_vm._u([{key:"subtitle",fn:function(ref){
var element = ref.element;
return [_vm._v(" "+_vm._s(_vm.exhibitionDatePeriod(element))+" ")]}},{key:"location",fn:function(ref){
var element = ref.element;
return [_vm._v(" "+_vm._s(_vm.exhibitionLocation(element))+" ")]}}])})],1),(!_vm.showMoreExhibitions)?_c('div',{staticClass:"flex justify-center mt-14"},[(_vm.hasMore)?_c('v-btn',{staticClass:"bg-dark text-white rounded-none h-12 w-32",attrs:{"loading":_vm.loaders.loadingMoreExhibitions,"depressed":""},on:{"click":function($event){return _vm.requestExhibitions()}}},[_vm._v(" "+_vm._s(_vm.$t("Show more"))+" "),_c('v-icon',{staticClass:"text-white ml-1",attrs:{"x-small":""}},[_vm._v("$dropdown")])],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }