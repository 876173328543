import HttpClient from "./client";
import {AxiosResponse} from "axios";

class HubspotApi extends HttpClient {
  baseApiUrl = "https://api.hsforms.com";
  submitFormUrl = "/submissions/v3/integration/submit/";
  portalId = "4178182";
  formGuid = "a05c71c3-35b2-4841-bf8a-14fc77fe2cbc";
  public submitForm = async (
    formData: Array<Record<string, string>>
  ): Promise<AxiosResponse<unknown>> => {
    const result = await this.client.post(
      `${this.submitFormUrl}/${this.portalId}/${this.formGuid}`,
      {fields: formData},
      {baseURL: this.baseApiUrl}
    );
    return result;
  };
}

const hubspotApi = new HubspotApi();
export default hubspotApi;
