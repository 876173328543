import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../locales/en.json";
import da from "../locales/da.json";
import nl from "../locales/nl.json";
import preferredLocale from "preferred-locale";

export type LocaleString = Record<LOCALES, string>;

export interface SiteLocale {
  code: LOCALES;
  name: string;
  shortName: string;
}

export enum LOCALES {
  EN = "en",
  DA = "da",
  NL = "nl"
}

export const localeList: SiteLocale[] = [
  {
    code: LOCALES.EN,
    name: "English",
    shortName: "EN"
  },
  {
    code: LOCALES.DA,
    name: "Danish",
    shortName: "DA"
  },
  {
    code: LOCALES.NL,
    name: "Dutch",
    shortName: "NL"
  }
];

Vue.use(VueI18n);

export class TestingFormatter implements VueI18n.Formatter {
  interpolate(message: string): string[] | null {
    return ["XXX" + message + "XXX"];
  }
}

export default new VueI18n({
  fallbackLocale: "en",
  formatFallbackMessages: true,
  locale: "en",
  // locale: preferredLocale(["en", "da", "nl"], "en", {
  //   regionLowerCase: true,
  //   languageOnly: true
  // }),
  silentTranslationWarn: true,
  //formatter: new TestingFormatter(),
  messages: {
    en: en,
    da: da,
    nl: nl
  },
  dateTimeFormats: {
    en: {
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      },
      short: {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      },
      time: {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      }
    },
    da: {
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric"
      },
      short: {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      },
      time: {
        hour: "numeric",
        minute: "numeric"
      }
    }
  }
});
