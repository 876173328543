










































































import searchApi from "@/api/search";
import {GetGeneralDataTitleSearch} from "@/types/api";
import {BaseDataV2} from "@/types/vizgu";
import {bigImageUri} from "@/utils/image-utils";
import {getItemHrefFromBaseData} from "@/utils/routing";
import {debounce} from "vue-debounce";
import {Component, Vue, VModel, Watch} from "vue-property-decorator";
@Component
export default class MobileSearchDialog extends Vue {
  @VModel({required: true}) showDialog!: boolean;

  inputSearch = "";
  searchDebounceWait = 700;
  isLoadingData = false;
  resultsList: BaseDataV2[] = [];
  defaultImageId = "e17fb04a-e32f-4041-92f1-abf608dbb73c";

  get searhInputPlaceholder() {
    return "";
  }

  get showAllResultsLabel() {
    return this.$t("Show all results for");
  }

  get showResultsLabel() {
    return `${this.showAllResultsLabel} ${this.inputSearch}`;
  }

  get canShowResults() {
    return this.inputSearch.trim().length > 0;
  }

  // watchers

  @Watch("showDialog")
  onShowDialogChange() {
    this.resultsList = [];
    this.inputSearch = "";
  }

  @Watch("inputSearch")
  onInputSearchChange() {
    this.debounceSearch();
  }
  // eof watchers

  debounceSearch = debounce(() => {
    this.requestDataTitleSearch();
  }, this.searchDebounceWait);

  goSearchPage() {
    this.showDialog = false;
    if (this.inputSearch.trim().length < 1) {
      return;
    }
    this.$router.push({
      name: "Search",
      query: {
        q: this.inputSearch
      }
    });
  }

  itemImageSrc(item: BaseDataV2) {
    return bigImageUri(item.imageGuids.$values[0] || this.defaultImageId);
  }

  goHrefItemPage(item: BaseDataV2) {
    this.showDialog = false;
    const link = getItemHrefFromBaseData(item);
    this.$router.push(link);
  }

  // data load methods
  async requestDataTitleSearch() {
    if (this.inputSearch.trim().length < 3) {
      return;
    }
    try {
      this.isLoadingData = true;
      const resp: GetGeneralDataTitleSearch = await searchApi.generalDataTitleSearch(
        this.inputSearch
      );
      this.resultsList = resp.$values;
    } catch (e) {
      // TODO handle error
    } finally {
      this.isLoadingData = false;
    }
  }
}
