
































import {Component, Prop, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import VideoCard from "./VideoCard.vue";

@Component({
  components: {VideoCard, GUIDImage}
})
export default class VideoList extends Vue {
  @Prop() title!: string;
}
