































































































import BasePageBanner, {
  BasePageBannerData
} from "@/components/BasePageBanner.vue";
import ListItems from "@/components/ListItemsV2.vue";
import {ItemTuple} from "@/types/homepage";
import {BaseData, VenueV2, VizguType} from "@/types/vizgu";
import {Component, Vue} from "vue-property-decorator";
import HighlightedSection from "@/components/HighlightedSection.vue";
import SelectSlider from "@/components/SelectSlider.vue";
import {GetVenuesRequest, GetVenuesResponse} from "@/types/api";
import venuesApi from "@/api/venues";

@Component({
  components: {BasePageBanner, ListItems, HighlightedSection, SelectSlider}
})
export default class Countries extends Vue {
  venuesCountryList: VenueV2[] = [];
  totalItems = 0;
  itemWidth = 200;
  perPage = 20;
  initialDataLoaded = false;

  exampleCity: ItemTuple<BaseData> = {
    item1: {
      name: "Denmark",
      position: null,
      canReview: false,
      extra: null,
      like: null,
      id: "123",
      imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
      isLocked: true,
      rating: 4.5,
      thumbImageURL: null,
      vizguType: VizguType.City
    },
    item2: 1
  };

  get hasMore() {
    return this.totalItems > this.venuesCountryList.length;
  }

  get countriesData(): VenueV2[] {
    return this.venuesCountryList;
  }
  /*
  get countriesData(): ItemTuple<BaseData>[] {
    // sample data for now
    const data: ItemTuple<BaseData>[] = [];
    for (let i = 0; i < 10; i++) {
      const item: ItemTuple<BaseData> = JSON.parse(
        JSON.stringify(this.exampleCity)
      );
      item.item1.id = `country-${i}`;
      data.push(item);
    }
    return data;
  }
  */
  goCountryPage(item: VenueV2) {
    this.$router.push(`/country/${item.id}`);
  }

  baseBannerData: BasePageBannerData = {
    imgUid: "e17fb04a-e32f-4041-92f1-abf608dbb73c",
    icon: "place-location",
    title: "Countries",
    subtitle: `Explore countries - find new and exciting artwork and activities in all the coolest countries around the world`
  };

  // lifecycle methods
  created() {
    this.loadInitialData();
  }

  // data load methods
  private async loadInitialData() {
    await this.requestPlacesCountry(true);
    this.initialDataLoaded = true;
  }

  private async requestPlacesCountry(replace = false) {
    if (!replace && !this.hasMore && this.initialDataLoaded) {
      return;
    }
    const params: GetVenuesRequest = {
      skip: this.venuesCountryList.length,
      take: this.perPage,
      type: VizguType.Country
    };
    const resp: GetVenuesResponse = await venuesApi.getVenues(params);
    const venues = resp.list.$values;
    this.totalItems = resp.count;
    if (replace) {
      this.venuesCountryList = venues;
    } else {
      this.venuesCountryList = this.venuesCountryList.concat(venues);
    }
  }
}
