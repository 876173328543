import {VenueV2, WayfinderV2, ExhibitionV2} from "@/types/vizgu";
import i18n from "@/plugins/i18n";

class HighlightedSectionContent {
  title = "";
  description = "";
  linkLabel = "";
  linkHref: string | undefined = undefined;

  constructor(params: Partial<HighlightedSectionContent> = {}) {
    Object.assign(this, params);
  }

  shorten(s: string, symbols = 240): string {
    s = s.slice(0, symbols);
    const prefix = s.length >= symbols ? "..." : "";
    return `${s}${prefix}`;
  }

  getHtml(): string {
    let content = `
      <h2>${this.title}</h2>
      <div class="text-md">
        ${this.shorten(this.description)}
      </div>

    `;
    if (this.linkHref) {
      content += `
      <a href="${this.linkHref}" class="text-md text-white">
          ${this.linkLabel}
      </a>
      `;
    }
    return content;
  }
}

export function cityHighlightedData(v: VenueV2): string {
  const title = v.city || "";
  const description = "";
  const e = i18n.t("Explore");
  const linkLabel = `${e} ${title}`;
  const linkHref = `/city/${v.id}`;

  const content = new HighlightedSectionContent({
    title,
    description,
    linkLabel,
    linkHref
  });
  return content.getHtml();
}

export function countryHighlightedData(v: VenueV2): string {
  const title = v.name || "";
  const description = "";
  const e = i18n.t("Explore");
  const linkLabel = `${e} ${title}`;
  const linkHref = `/country/${v.id}`;

  const content = new HighlightedSectionContent({
    title,
    description,
    linkLabel,
    linkHref
  });
  return content.getHtml();
}

export function venueHighlightedData(v: VenueV2): string {
  const title = v.name || "";
  const description = v.description || "";
  const e = i18n.t("Explore");
  const linkLabel = `${e} ${title}`;
  const linkHref = `/venues/${v.id}`;

  const content = new HighlightedSectionContent({
    title,
    description,
    linkLabel,
    linkHref
  });
  return content.getHtml();
}

export function wayfinderHighlightedData(v: WayfinderV2): string {
  const title = v.name || "";
  const description = v.description || "";
  const e = i18n.t("Explore");
  const linkLabel = `${e} ${title}`;
  const linkHref = `/wayfinders/${v.id}`;

  const content = new HighlightedSectionContent({
    title,
    description,
    linkLabel,
    linkHref
  });
  return content.getHtml();
}

export function exhibitionHighlightedData(v: ExhibitionV2): string {
  const title = v.name || "";
  const description = v.description || "";
  const e = i18n.t("Explore");
  const linkLabel = `${e} ${title}`;
  const linkHref = `/exhibitions/${v.id}`;

  const content = new HighlightedSectionContent({
    title,
    description,
    linkLabel,
    linkHref
  });
  return content.getHtml();
}
