















































import {TranslateResult} from "vue-i18n";
import {Component, Vue} from "vue-property-decorator";

type QuestionTyle = {
  title: string | TranslateResult;
  description: string | TranslateResult;
};

@Component
export default class PlatformFAQComponent extends Vue {
  get items(): QuestionTyle[] {
    return [
      {
        title: this.$t("How do you implement Vizgu?"),
        description: this.$t(
          "At Vizgu we develop out products with API first approach. Meaning our solutions are easy to implement and do not require large teams nor project management skills from customer side. Typically, and depending on the maturity level on software– we implement within 1 to 3 weeks. With test and go-live."
        )
      },
      {
        title: this.$t(
          "Do I need the use the Vizgu APP as part of the Platform?"
        ),
        description: this.$t(
          "The app is an extension of Vizgu services offerings – and is not required. However, we do suggest that you push your offerings trough the app- as it is used by many guests, tourists, art & culture enthusiasts."
        )
      },
      {
        title: this.$t(
          "If I have a ticketing system, can I use your guide management?"
        ),
        description: this.$t(
          "Our entire feature portfolio in Vizgu Platform & APP work as standalone or in unison. We don’t want you to buy stuff you don’t need. <3"
        )
      },
      {
        title: this.$t(
          "Can you integrate with my collection management system?"
        ),
        description: this.$t(
          "Yes. Vizgu API supports collection management systems."
        )
      },
      {
        title: this.$t("Do you have donation features?"),
        description: this.$t(
          "Yes. Vizgu offers a standalone donation feature - for web and in app."
        )
      },
      {
        title: this.$t(
          "Can I manage and communicate with my members via Vizgu?"
        ),
        description: this.$t(
          "Yes. With Vizgu you can use our entire suite or just use our membership feature. The membership feature supports: Digital Membership card. Physical Card- all cards communicate with ticketing systems and POS systems."
        )
      },
      {
        title: this.$t(
          "I have a special project- but none of the features I need are not listed."
        ),
        description: this.$t(
          "Please reach out to us - Vizgu is built with co-creation and if we feel your project has the possibility to help many other professionals - we are more than willing to co-create the features with you."
        )
      }
    ];
  }
}
