











import {Component, Prop, Vue} from "vue-property-decorator";
@Component
export default class CardItemSkeleton extends Vue {
  @Prop({required: false, default: 200}) width!: number;
}
