import HttpClient from "./client";
import {GetWayfindersResponse, GetWayfindersRequest} from "@/types/api";
import {WayfinderV2} from "@/types/vizgu";
import {embeddedStore} from "@/store";

class WayfindersApi extends HttpClient {
  public getWayfinders = async (
    params: GetWayfindersRequest
  ): Promise<GetWayfindersResponse> => {
    params.language = embeddedStore.apiLocaleFromI18n;
    const {data} = await this.client.get<GetWayfindersResponse>(
      "/wayfinder/list",
      {params: params}
    );
    return data;
  };
  public getWayfinder = async (id: string): Promise<WayfinderV2> => {
    const {data} = await this.client.get<WayfinderV2>("/wayfinder", {
      params: {
        id: id,
        language: embeddedStore.apiLocaleFromI18n
      }
    });
    return data;
  };
}

const wayfindersApi = new WayfindersApi();
export default wayfindersApi;
