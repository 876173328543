















import {embeddedStore as context} from "@/store";
import {Component, Vue} from "vue-property-decorator";
@Component
export default class Snackbars extends Vue {
  get text() {
    return {
      clipboard: this.$t("Copied to clipboard"),
      notifyError: context.snackbars.notifyError.text
    };
  }

  timeouts = {
    clipboard: 1000,
    notifyError: 2000
  };

  get openClipboard() {
    return context.snackbars.clipboard;
  }
  set openClipboard(isOpen: boolean) {
    context.setClipboardSnackbar(isOpen);
  }

  get openNotifyError() {
    return context.snackbars.notifyError.open;
  }

  set openNotifyError(isOpen: boolean) {
    context.setNotifyErrorSnackbar({
      open: isOpen,
      text: !isOpen ? this.text.notifyError : ""
    });
  }
}
