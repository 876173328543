

























import {Component, Vue} from "vue-property-decorator";
import Rating from "@/components/Rating.vue";

@Component({
  components: {Rating}
})
export default class Review extends Vue {
  get reviewText(): string {
    return `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at porttitor odio, eu tristique tortor. Phasellus et faucibus nisi. Etiam in quam eget orci faucibus eleifend eu convallis velit.

Suspendisse eget gravida enim.Nam mollis velit tellus, posuere accumsan ante consectetur sed. Praesent sed sem vitae est malesuada lacinia egestas a arcu. Proin sed tempus diam. In eleifend cursus lorem ut posuere. Phasellus elit arcu, tincidunt id sapien vitae. Maecenas fermentum, nisi non finibus venenatis, ante diam maximus nisl, et pellentesque nulla sem nec est. Nunc sagittis mattis lorem, in commodo metus rutrum sed.

Pellentesque id maximus sem, et vestibulum libero. Pellentesque quis imperdiet urna. Vivamus interdum, Mauris in diam sed tellus imperdiet convallis at eget lacus.`;
  }
}
