



























































































import BasePageBanner, {
  BasePageBannerData
} from "@/components/BasePageBanner.vue";
import ListItemsV2 from "@/components/ListItemsV2.vue";
import {BaseDataV2, ExhibitionV2} from "@/types/vizgu";
import {Component, Vue} from "vue-property-decorator";
import HighlightedSection, {
  HighlightedSectionData
} from "@/components/HighlightedSection.vue";
import SelectSlider from "@/components/SelectSlider.vue";
import {GetExhibitionsRequest, GetExhibitionsResponse} from "@/types/api";
import exhibitionsApi from "@/api/exhibitions";
import {parseDateToString} from "@/utils/datetime";
import {exhibitionHighlightedData} from "@/utils/highlighted-section";
import {EventKeys} from "@/store/modules/embedded";

@Component({
  components: {
    BasePageBanner,
    ListItemsV2,
    HighlightedSection,
    SelectSlider
  }
})
export default class Exhibitions extends Vue {
  itemWidth = 340;
  perPage = 10;
  totalItems = 0;
  showMoreExhibitions = false;
  initialDataLoaded = false;

  loaders = {
    loadingMoreExhibitions: false
  };

  exhibitionList: ExhibitionV2[] = [];
  popularExhibition: ExhibitionV2 | null = null;

  get loadMoreSkeletonsCount() {
    return this.loaders.loadingMoreExhibitions ? 10 : 0;
  }

  get hSections() {
    return {
      first: this.firstHighlightedSection,
      second: this.secondHighlightedSection
    };
  }

  get firstHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.exhibitionList?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      content: exhibitionHighlightedData(v)
    });
  }

  get secondHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.exhibitionList?.[1];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      imageTooltipText: "New!",
      content: exhibitionHighlightedData(v)
    });
  }

  get hasMore() {
    return this.totalItems > this.exhibitionList.length;
  }

  get exhibitionsDataV2(): BaseDataV2[] {
    return this.exhibitionList;
  }

  get popularDescription(): string {
    return this.popularExhibition?.description || "";
  }

  get popularVenueName(): string | null {
    return this.popularExhibition?.venueName || null;
  }

  get popularImgSrc(): string | undefined {
    return this.popularExhibition?.imageGuids.$values[0];
  }

  exhibitionLocation(exhibition: ExhibitionV2) {
    return exhibition.venueName ?? "";
  }

  // TODO: remove further
  toggleShowMoreExhibitions() {
    this.showMoreExhibitions = !this.showMoreExhibitions;
  }

  exhibitionDatePeriod(exhibition: ExhibitionV2) {
    if (!exhibition.begin || !exhibition.end) {
      return "";
    }
    const begin = new Date(exhibition.begin);
    const end = new Date(exhibition.end);
    const beginString = parseDateToString(begin);
    const endString = parseDateToString(end);
    return `${beginString} - ${endString}`;
  }

  get baseBannerData(): BasePageBannerData {
    return {
      imgUid: "42f405b6-f1e1-4791-8185-ad46a8ce4dca",
      icon: "exhibition",
      title: this.$t("Exhibitions"),
      subtitle: this.$t(
        "Experience the coolest art exhibitions all over the world. Experince art up close!"
      )
    };
  }

  initListeners(enable: boolean) {
    if (enable) {
      window.addEventListener(EventKeys.locale, this.onLocaleEvent);
    } else {
      window.removeEventListener(EventKeys.locale, this.onLocaleEvent);
    }
  }

  onLocaleEvent() {
    this.loadInitialData();
  }
  // lifecycle methods
  created() {
    this.initListeners(true);
    this.loadInitialData();
  }

  beforeDestroy() {
    this.initListeners(false);
  }

  private async loadInitialData() {
    await this.requestExhibitions(true);
    this.initialDataLoaded = true;
  }

  private async requestExhibitions(replace = false) {
    if (!replace && !this.hasMore && this.initialDataLoaded) {
      return;
    }
    try {
      !replace && (this.loaders.loadingMoreExhibitions = true);
      const params: GetExhibitionsRequest = {
        skip: this.exhibitionList.length,
        take: this.perPage,
        end: new Date().toISOString()
      };
      const resp: GetExhibitionsResponse = await exhibitionsApi.getExhibitions(
        params
      );
      this.totalItems = resp.count;
      const rExhibitons = resp.list.$values;
      this.totalItems = resp.count;
      if (replace) {
        this.exhibitionList = rExhibitons;
      } else {
        this.exhibitionList = this.exhibitionList.concat(rExhibitons);
      }
    } finally {
      this.loaders.loadingMoreExhibitions = false;
    }
    // this.popularExhibition = resp.popular;
  }
}
