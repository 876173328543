









































































































































































import {TranslateResult} from "vue-i18n";
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {},
  data: () => ({
    image: require("@/assets/images/footer/giftcard.png")
  })
})
export default class GiftCard extends Vue {
  get giftcardDescription(): string | TranslateResult {
    return this.$t(
      "Give the ones you love unforgettable art and culture experiences and make memories to last a lifetime."
    );
  }
}
