import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import {env} from "@/env";

// can be replacted with env/settings file import
const mainAPIURL = env.API.mainAPI;
// const appToken = "";

abstract class HttpClient {
  protected readonly client: AxiosInstance;

  public constructor(baseURL: string = mainAPIURL) {
    const headers = {
      // "content-type": "application/json",
      // "app-token": "test-token",
      // "Access-Control-Allow-Origin": "*"
      Accept: "*",
      "Access-Control-Allow-Origin": "*"
      // "Allow-Origin": "*"
    };
    const config: AxiosRequestConfig = {
      baseURL: baseURL,
      headers: headers
    };
    this.client = axios.create(config);
    this.client.interceptors.response.use(
      response => response,
      (error: AxiosError) => {
        return this.handleError(error);
      }
    );
  }

  private handleError(error: AxiosError) {
    /*
     * just simple ex. Handling errors can be customized, based on
     * backend api structure
     */
    const errorMessage = error?.response || "unknown error";
    throw new Error(JSON.stringify(errorMessage));
  }
}

export default HttpClient;
