












































































import BasePageBanner, {
  BasePageBannerData
} from "@/components/BasePageBanner.vue";
import ItemSlideGroup from "@/components/ItemSlideGroupV2.vue";
import {VenueV2, VizguType} from "@/types/vizgu";
import {Component, Vue} from "vue-property-decorator";
import HighlightedSection, {
  HighlightedSectionData
} from "@/components/HighlightedSection.vue";
import {GetVenuesRequest, GetVenuesResponse} from "@/types/api";
import venuesApi from "@/api/venues";
import {
  cityHighlightedData,
  countryHighlightedData
} from "@/utils/highlighted-section";
import {EventKeys} from "@/store/modules/embedded";

@Component({
  components: {BasePageBanner, ItemSlideGroup, HighlightedSection}
})
export default class Places extends Vue {
  initialDataLoading = false;
  itemWidth = 220;
  perPage = 20;

  venuesCountryList: VenueV2[] = [];
  venuesCityList: VenueV2[] = [];

  get hSections() {
    return {
      first: this.firstHighlightedSection,
      second: this.secondHighlightedSection
    };
  }

  get firstHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.venuesCountryList?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      content: countryHighlightedData(v)
    });
  }

  get secondHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.citiesData?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      imageTooltipText: this.$t("New!"),
      content: cityHighlightedData(v)
    });
  }

  get countriesData(): VenueV2[] {
    return this.venuesCountryList;
  }

  get citiesData(): VenueV2[] {
    return this.venuesCityList;
  }
  /*
  get countriesData(): ItemTuple<Place>[] {
    // sample data for now
    const data: ItemTuple<Place>[] = [];
    for (let i = 1; i < 10; i++) {
      const item: ItemTuple<Place> = JSON.parse(
        JSON.stringify(this.exampleCountry)
      );
      item.item1.id = `country-${i}`;
      data.push(item);
    }
    return data;
  }
  */

  /*
  get citiesData(): ItemTuple<Place>[] {
    // sample data for now
    const data: ItemTuple<Place>[] = [];
    for (let i = 1; i < 10; i++) {
      const item: ItemTuple<Place> = JSON.parse(
        JSON.stringify(this.exampleCity)
      );
      item.item1.id = `city-${i}`;
      data.push(item);
    }
    return data;
  }
  */

  get baseBannerData(): BasePageBannerData {
    return {
      imgUid: "22408243-3b9c-479d-ad2d-903c25463144",
      icon: "venue",
      title: this.$t("Places"),
      subtitle: this.$t(
        "Find your favorite places in the world and see all of the art and culture that they have to offer"
      )
    };
  }

  goCountryPage(item: VenueV2) {
    this.$router.push(`/country/${item.id}`);
  }

  goCityPage(item: VenueV2) {
    this.$router.push(`/city/${item.id}`);
  }

  initListeners(enable: boolean) {
    if (enable) {
      window.addEventListener(EventKeys.locale, this.onLocaleEvent);
    } else {
      window.removeEventListener(EventKeys.locale, this.onLocaleEvent);
    }
  }

  onLocaleEvent() {
    this.loadInitialData();
  }

  // lifecycle methods
  async created() {
    this.initialDataLoading = true;
    this.initListeners(true);
    await this.loadInitialData();
    this.initialDataLoading = false;
  }

  beforeDestroy() {
    this.initListeners(false);
  }

  // data load methods
  private async loadInitialData() {
    await Promise.all([
      this.requestPlacesCountry(true),
      this.requestPlacesCity(true)
    ]);
  }

  private async requestPlacesCountry(replace = false) {
    const params: GetVenuesRequest = {
      skip: this.venuesCountryList.length,
      take: this.perPage,
      type: VizguType.Country
    };
    const resp: GetVenuesResponse = await venuesApi.getVenues(params);
    const venues = resp.list.$values;
    if (replace) {
      this.venuesCountryList = venues;
    } else {
      this.venuesCountryList = this.venuesCountryList.concat(venues);
    }
  }

  private async requestPlacesCity(replace = false) {
    const params: GetVenuesRequest = {
      skip: this.venuesCityList.length,
      take: this.perPage,
      type: VizguType.City
    };
    const resp: GetVenuesResponse = await venuesApi.getVenues(params);
    const venues = resp.list.$values;
    if (replace) {
      this.venuesCityList = venues;
    } else {
      this.venuesCityList = this.venuesCityList.concat(venues);
    }
  }
}
