import {ApiClient} from "./client";
import {
  GetEventsRequest,
  EventsResult,
  VizEvent,
  EventTicketsResult,
  GetEventTicketsRequest,
  GetEventDetailsRequest
} from "@/types/api.newviz/events";

class EventsApi extends ApiClient {
  public getEvents = async (
    params: GetEventsRequest = {}
  ): Promise<EventsResult> => {
    const orgId = params.orgId || this.vizguOrgId;
    const {query} = params;
    const {data} = await this.client.get<EventsResult>(
      `/events/organizations/${orgId}`,
      {baseURL: this.currentApi, params: query}
    );
    return data;
  };
  public getEvent = async (
    params: GetEventDetailsRequest
  ): Promise<VizEvent> => {
    const {id, query} = params;
    const {data} = await this.client.get<VizEvent>(`/events/${id}/public`, {
      baseURL: this.currentApi,
      params: query
    });
    return data;
  };
  public getEventTickets = async (
    eventId: string,
    params: GetEventTicketsRequest
  ): Promise<EventTicketsResult> => {
    const {data} = await this.client.get<EventTicketsResult>(
      `/events/${eventId}/series`,
      {baseURL: this.currentApi, params}
    );
    return data;
  };
}

const eventsApi = new EventsApi();
export default eventsApi;
