




























































































































































































































































































import {VEvent, VizEventTickets} from "@/models/events";
import {cartStore, embeddedStore} from "@/store";
import {stringFromLocale} from "@/utils/i18n";
import {Component, Prop, Watch, Vue} from "vue-property-decorator";

export type PickTicketProps = {
  event: VEvent;
  eventTickets: VizEventTickets[];
  onUpdatePickerDate: (pickerDate: string) => Promise<void>;
  onAddCart: (selectedEvent: VEvent) => Promise<void>;
  onCurrencyUpdate?: () => Promise<void>;
};

@Component({
  // components: {}
})
export default class PickTicket extends Vue {
  @Prop({required: true}) props!: PickTicketProps;
  /*
  TODO:
  - tickets count available logic
  - fix UI glitches
  */

  selectedDate: string | null = null;
  selectedTime: string | null = null;
  selectedEvent: VEvent | null = null;

  loaders = {
    findTicket: false,
    addCart: false
  };

  @Watch("currentCurrency")
  onCurrencyChange() {
    this.reset();
    if (this.props.onCurrencyUpdate) {
      this.props.onCurrencyUpdate();
    }
  }

  get currentCurrency() {
    return cartStore.currentCurrency;
  }

  selectEvent(e: VEvent | null) {
    this.selectedEvent = e;
  }

  reset() {
    this.selectedDate = null;
    this.selectedTime = null;
    this.selectedEvent = null;
    // this.selectEvent(null);
  }

  get selectedEventConfig() {
    const e = this.selectedEvent;
    if (!e) {
      return null;
    }
    // date = e.occurrence
    return {
      dateLabel: e.startDateTimeLabel(),
      locationLabel: this.event.locationLabel
    };
  }

  get isAddCartDisabled() {
    if (this.tickets.filter(t => t.count > 0).length == 0) {
      return true;
    }
    return false;
  }

  onDatePick() {
    this.selectedDateEvents && this.selectEvent(this.selectedDateEvents[0]);
  }

  async onDatePickerChange(pickerDate: string) {
    const pickerDateFullFmt = `${pickerDate}-1`;
    await this.props.onUpdatePickerDate(pickerDateFullFmt);
    this.onDatePick();
  }

  get allowedDatesStrings(): string[] {
    const t = this.props.eventTickets;
    if (!t) {
      return [];
    }
    const dates: string[] = [];
    const items = t.map(v => v.series);
    items.forEach(monthItem =>
      monthItem.forEach(ev => {
        const o = Array.isArray(ev.occurrence)
          ? ev.occurrence[0]
          : ev.occurrence;
        !dates.includes(o.start.date) && dates.push(o.start.date);
      })
    );
    return dates;
  }

  get selectedDateEvents(): VEvent[] | null {
    const t = this.props.eventTickets;
    const d = this.selectedDate;
    if (!d || !t) {
      return null;
    }
    let yearMonth: any = d.replaceAll("0", "").split("-");
    yearMonth.splice(yearMonth.length - 1, 1);
    yearMonth = yearMonth.join("-");
    const monthItems =
      t.find(v => v.month.replaceAll("0", "") == yearMonth)?.series || null;
    if (!monthItems) {
      return null;
    }
    const dateItems = monthItems.filter(v => {
      const o = Array.isArray(v.occurrence) ? v.occurrence[0] : v.occurrence;
      return o.start.date == this.selectedDate;
    });
    return dateItems.length > 0 ? dateItems : null;
  }

  allowDateChecker(date: string) {
    return this.allowedDatesStrings.includes(date);
  }

  get ticketsCurrency() {
    return cartStore.currentCurrency;
  }

  get timeVariants() {
    return this.selectedDateEvents
      ? this.selectedDateEvents.map(v => v.selectTimeLabel)
      : [];
  }

  selectEventByTime(v: string) {
    if (!this.selectedDateEvents) {
      return;
    }
    this.selectEvent(
      this.selectedDateEvents.find(e => e.selectTimeLabel == v) || null
    );
  }

  get totalPrice() {
    let price = 0;
    this.tickets
      .filter(v => v.count > 0)
      .map(v => v.priceWithCount)
      .forEach(p => (price += p));
    return price;
  }

  styles = {
    container: {
      background: `linear-gradient(270.1deg, #E0C1B6 -13.97%, #967E76 111.63%), #232323`
    },
    ticketsList: {
      width: "auto"
      // minWidth: "200px"
    },
    selection: {
      minWidth: "280px"
    }
  };

  get labels() {
    return {
      chooseVisiters: this.$t("Choose visiters"),
      chooseDate: this.$t("Choose date"),
      findTickets: this.$t("Find tickets"),
      addToCart: this.$t("Add to cart"),
      addingToCart: this.$t("Adding to cart"),
      free: this.$t("Free")
    };
  }

  get addCartLabel() {
    return this.loaders.addCart
      ? this.labels.addingToCart
      : this.labels.addToCart;
  }

  get canFindTicket() {
    // const hasVisiters = this.tickets.find(v => v.count > 0) != undefined;
    const hasDate = this.selectedDate != null;
    return hasDate;
  }

  get tickets() {
    return this.selectedEvent?.tickets?.list || [];
  }

  get event() {
    return this.props.event;
  }

  // TODO
  get eventName() {
    const name = this.event.generalInfo?.name;
    if (!name) {
      return "";
    }
    return stringFromLocale(name);
  }

  get eventDateTime() {
    return this.selectedEventConfig?.dateLabel || "";
  }

  get eventLocation() {
    return this.selectedEventConfig?.locationLabel;
  }

  // TODO
  async findTickets() {
    this.loaders.findTicket = true;
    setTimeout(() => (this.loaders.findTicket = false), 1000);
  }

  // TODO
  async addToCart() {
    if (!this.selectedEvent) {
      return;
    }
    this.loaders.addCart = true;
    this.selectedEvent.defaultLanguage = this.event.defaultLanguage;
    try {
      await this.props.onAddCart(this.selectedEvent);
    } catch (e) {
      embeddedStore.notifyError("Error while adding item to cart");
    } finally {
      this.loaders.addCart = false;
    }
  }
}
