




















































import {Component, Prop, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import {BaseData} from "@/types/vizgu";
import {ItemTuple} from "@/types/homepage";

@Component({
  components: {GUIDImage}
})
export default class ListItems extends Vue {
  @Prop() dataList!: ItemTuple<BaseData>[];
  @Prop() title!: string;
  @Prop({type: Boolean, required: false, default: true}) useSubtitle!: boolean;
  @Prop({type: Boolean, required: false, default: false}) useLocation!: boolean;
  @Prop() itemWidth!: number;
  @Prop({type: String, required: false, default: "venue"}) cardTopIcon!: number;

  handleItemHref(item: ItemTuple<BaseData>) {
    this.$emit("onItemHref", item);
  }
}
