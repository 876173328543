


































































import BasePageBanner, {
  BasePageBannerData
} from "@/components/BasePageBanner.vue";
import ListItems from "@/components/ListItems.vue";
import {ItemTuple} from "@/types/homepage";
import {BaseData, VizguType} from "@/types/vizgu";
import {Component, Vue} from "vue-property-decorator";
import HighlightedSection from "@/components/HighlightedSection.vue";
import SelectSlider from "@/components/SelectSlider.vue";

@Component({
  components: {BasePageBanner, ListItems, HighlightedSection, SelectSlider}
})
export default class Artists extends Vue {
  itemWidth = 200;

  exampleArtist: ItemTuple<BaseData> = {
    item1: {
      name: "Dea Trier Morch",
      position: null,
      canReview: false,
      extra: null,
      like: null,
      id: "123",
      imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
      isLocked: true,
      rating: 4.5,
      thumbImageURL: null,
      vizguType: VizguType.Artist
    },
    item2: 1
  };

  get artistsData(): ItemTuple<BaseData>[] {
    // sample data for now
    const data: ItemTuple<BaseData>[] = [];
    for (let i = 0; i < 10; i++) {
      const item: ItemTuple<BaseData> = JSON.parse(
        JSON.stringify(this.exampleArtist)
      );
      item.item1.id = `artist-${i}`;
      data.push(item);
    }
    return data;
  }

  baseBannerData: BasePageBannerData = {
    imgUid: "e17fb04a-e32f-4041-92f1-abf608dbb73c",
    icon: "artist",
    title: "Artists",
    subtitle: `Find and learn about artists, and all of their artwork, from all around the world`
  };
}
