





















































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import ItemSlideGroup from "@/components/ItemSlideGroup.vue";
import VideoCard from "@/components/VideoCard.vue";
import EventInfoBox, {InformationItem} from "@/components/EventInfoBox.vue";
import GUIDImage from "@/components/GUIDImage.vue";
import Rating from "@/components/Rating.vue";
import Review from "@/components/Review.vue";
import PickTicket, {PickTicketProps} from "@/components/PickTicket.vue";
import {cartStore, embeddedStore} from "@/store";
import {GetEventTicketsRequest, VizEvent} from "@/types/api.newviz/events";
import eventsApi from "@/api.newviz/events";
import {VEvent, VizEventTickets} from "@/models/events";
import {VCart} from "@/models/cart";
import {ImageAPI} from "@/utils/image-utils";
import {copyCurrentRouteClipboard} from "@/utils/clipboard";
import {format} from "date-fns";

export interface TicketType {
  name: string;
  price: string;
}

@Component({
  components: {
    EventInfoBox,
    VideoCard,
    ItemSlideGroup,
    GUIDImage,
    Rating,
    Review,
    PickTicket
  }
})
export default class Event extends Vue {
  currentEvent: VEvent | null = null;
  currentEventTickets: VizEventTickets[] = [];

  eventImageAPI = ImageAPI.NewViz;

  showCritics = false;
  showLike = false;

  @Watch("$route.params.id")
  onIdChange() {
    window.scrollTo(0, 0);
    this.loadApiData();
  }

  get cart(): VCart | null {
    return cartStore.cart;
  }

  shareLink() {
    copyCurrentRouteClipboard(this.$route);
  }

  get availableTickets(): TicketType[] {
    return [
      {
        name: "Adults (18-64)",
        price: "60 DKK"
      },
      {
        name: "Adults (18-64)",
        price: "60 DKK"
      },
      {
        name: "Senior discount (65+)",
        price: "45 DKK"
      }
    ];
  }
  get eventInfo(): InformationItem[] {
    const e = this.currentEvent;
    return [
      {
        icon: "calendar",
        title: e?.date || ""
      },
      {
        icon: "clock",
        title: e?.duration || ""
      },
      {
        icon: "place-location",
        title: e?.generalInfo?.location?.address || ""
      }
    ];
  }
  get items(): string[] {
    return ["Foo", "Bar", "Fizz", "Buzz"];
  }
  get description(): string {
    return `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at porttitor odio, eu tristique tortor. Phasellus et faucibus nisi. Etiam in quam eget orci faucibus eleifend eu convallis velit.

Suspendisse eget gravida enim.Nam mollis velit tellus, posuere accumsan ante consectetur sed. Praesentsed sem vitae est malesuada lacinia egestas a arcu.
Proin sed tempus diam.

Quisque ut semper ipsum. In hac habitasse platea dictumst. In eleifend
cursus lorem ut posuere. Phasellus elit arcu, tincidunt id sapien vitae,
finibus hendrerit nulla. Maecenas fermentum, nisi non finibus venenatis,
ante diam maximus nisl, et pellentesque nulla sem nec est. Nunc sagittis
mattis lorem, in commodo metus rutrum sed.`;
  }

  get pickTicketProps(): PickTicketProps | undefined {
    if (!this.currentEvent) {
      return undefined;
    }
    return {
      event: this.currentEvent,
      eventTickets: this.currentEventTickets,
      onUpdatePickerDate: async (pickerDate: string) => {
        await this.loadEventTickets(false, {month: pickerDate});
      },
      onAddCart: async (selectedEvent: VEvent) => {
        await VCart.createOrAdd(selectedEvent);
      },
      onCurrencyUpdate: async () => {
        await this.loadEventTickets(true);
      }
    };
  }

  // lifecycle methods
  async created() {
    // this.initListeners(true);
    await this.loadApiData();
  }
  // eof lifecycle methods
  private async loadApiData() {
    embeddedStore.setCurrentEventLoading(true);
    embeddedStore.setCurrentEvent(null);
    const id: string = this.$route.params.id;
    await this.requestEvent(id);
    await this.loadEventTickets();
  }

  // data load methods
  private async requestEvent(id: string) {
    const resp: VizEvent = await eventsApi.getEvent({
      id
    });
    embeddedStore.setCurrentEvent(new VEvent(resp));
    this.currentEvent = embeddedStore.currentEvent;
    embeddedStore.setCurrentEventLoading(false);
  }

  private async loadEventTickets(
    replace = false,
    params: Partial<GetEventTicketsRequest> = {}
  ) {
    if (!this.currentEvent) {
      return;
    }

    const currentYearMonth = format(new Date(), "yyy-MM-dd");
    const hasMonth = (date: string) => {
      const dFmt = format(new Date(date), "yyy-M");
      return this.currentEventTickets.find(v => v.month == dFmt) != undefined;
    };

    if (replace) {
      this.currentEventTickets = [];
    }

    if (!replace && this.currentEventTickets) {
      if (params.month && params.month && hasMonth(params.month)) {
        return;
      } else if (!params.month && hasMonth(currentYearMonth)) {
        return;
      }
    }
    const query: GetEventTicketsRequest = {
      buyCurrency: cartStore.currentCurrency,
      ...params
    };
    const resp = await eventsApi.getEventTickets(
      this.currentEvent?.eventId,
      query
    );
    this.currentEventTickets.push({
      month: resp.month,
      series: resp.series.map(v => new VEvent(v))
    });
  }
}
