import HttpClient from "@/api/client";
import {env, VIZGU_ORG_ID} from "@/env";

const mainAPIUrl = env.API.newViz;

export class ApiClient extends HttpClient {
  currentApi = env.API.newViz;
  public constructor(baseURL: string = mainAPIUrl) {
    super(baseURL);
  }

  get vizguOrgId() {
    return VIZGU_ORG_ID;
  }
}
