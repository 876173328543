var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasePageBanner',{attrs:{"data":_vm.baseBannerData}}),_c('div',{staticClass:"mt-16"},[_c('ItemSlideGroup',{attrs:{"loading":_vm.initialDataLoading,"data-list":_vm.countriesData,"title":_vm.$t('Countries'),"item-width":_vm.itemWidth,"useLocation":false,"card-top-icon":"place-location","viewAllHref":'/countries',"useVizguTypeRouting":false},on:{"onItemHref":function (item) { return _vm.goCountryPage(item); }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var element = ref.element;
return [_vm._v(" "+_vm._s(element.name)+" ")]}},{key:"subtitle",fn:function(ref){
var element = ref.element;
return [_vm._v(" "+_vm._s(element.content)+" ")]}}])})],1),(_vm.hSections.first)?_c('div',{staticClass:"my-16"},[_c('HighlightedSection',{attrs:{"data":_vm.hSections.first}})],1):_vm._e(),_c('div',[_c('ItemSlideGroup',{attrs:{"loading":_vm.initialDataLoading,"data-list":_vm.citiesData,"title":_vm.$t('Cities'),"item-width":_vm.itemWidth,"card-top-icon":"place-location","useLocation":false,"viewAllHref":'/cities'},on:{"onItemHref":function (item) { return _vm.goCityPage(item); }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var element = ref.element;
return [_vm._v(" "+_vm._s(element.city)+" ")]}},{key:"subtitle",fn:function(ref){
var element = ref.element;
return [_vm._v(" "+_vm._s(element.content)+" ")]}}])})],1),(_vm.hSections.second)?_c('div',{staticClass:"mt-16"},[_c('HighlightedSection',{attrs:{"data":_vm.hSections.second}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("Most Venues & activities"))+" ")]),_c('div',{staticClass:"text-sm max-w-sm"},[_vm._v(" "+_vm._s(_vm.$t( "Explore the place with the most available venues & activities: Denmark" ))+" ")]),_c('div',{staticClass:"font-roboto text-sm"},[_c('span',[_vm._v(_vm._s(_vm.$t("Country")))]),_c('v-icon',{staticClass:"text-white ml-1",attrs:{"x-small":""}},[_vm._v("place-location")]),_c('span',[_vm._v("Denmark")])],1),_c('div',{staticClass:"hidden md:flex flex-row cursor-pointer items-center"},[_c('div',{staticClass:"text-xs"},[_vm._v("287 "+_vm._s(_vm.$t("venues & activities")))]),_c('v-icon',{staticClass:"text-white ml-1",attrs:{"x-small":""}},[_vm._v("$next")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }