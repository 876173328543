import {VizguTypeLink} from "@/types/vizgu";

const mobileLinkBaseUrl = "https://appbackend.vizgu.com/link/GetLink";

export function getMobileLinkFor(p: {type: VizguTypeLink; id: string}) {
  const {type, id} = p;
  return `${mobileLinkBaseUrl}?type=${type}&id=${id}`;
}

export function openMobileLinkFor(p: {type: VizguTypeLink; id: string}) {
  const {type, id} = p;
  const link = getMobileLinkFor({type, id});
  window.open(link, "_blank")?.focus();
}
