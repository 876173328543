
























































































import {Component, Vue} from "vue-property-decorator";

import ItemSlideGroup from "@/components/ItemSlideGroup.vue";
import GUIDImage from "@/components/GUIDImage.vue";
import {InformationItem} from "@/components/EventInfoBox.vue";
import VideoCard from "@/components/VideoCard.vue";
import {getSampleBaseDataList} from "@/types/vizgu";

@Component({
  components: {ItemSlideGroup, GUIDImage, VideoCard}
})
export default class Artist extends Vue {
  get eventInfo(): InformationItem[] {
    return [
      {
        icon: "brush",
        title: "P.S Krøyer, 1961"
      }
    ];
  }

  get moreFromArtist() {
    return getSampleBaseDataList();
  }

  get description(): string {
    return `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at porttitor
odio, eu tristique tortor. Phasellus et faucibus nisi. Etiam in quam eget
orci faucibus eleifend eu convallis velit.

Suspendisse eget gravida enim.Nam mollis velit tellus, posuere accumsan ante consectetur sed. Praesent
sed sem vitae est malesuada lacinia egestas a arcu. Proin sed tempus diam.

Quisque ut semper ipsum. In hac habitasse platea dictumst. In eleifend
cursus lorem ut posuere. Phasellus elit arcu, tincidunt id sapien vitae,
finibus hendrerit nulla. Maecenas fermentum, nisi non finibus venenatis,
ante diam maximus nisl, et pellentesque nulla sem nec est. Nunc sagittis
mattis lorem, in commodo metus rutrum sed.

Pellentesque id maximus sem, et
vestibulum libero. Pellentesque quis imperdiet urna. Vivamus interdum,
Mauris in diam sed tellus imperdiet convallis at eget lacus.`;
  }
}
