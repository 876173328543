















































































































import hubspotApi from "@/api/hubspot";
import {roleList} from "@/data";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class PlatformContactForm extends Vue {
  @Prop({type: Boolean, default: false}) isActive!: boolean;

  setActive(v: boolean) {
    this.isActive = v;
  }

  toggleActive() {
    this.setActive(!this.isActive);
  }

  get roles(): string[] {
    return roleList.map(v => this.$t(v.title).toString());
  }

  // roles = ["Curator", "Director"];

  form = {
    title: this.roles[0],
    firstName: "",
    lastName: "",
    email: ""
  };

  get formData(): Array<Record<string, string>> {
    return [
      {name: "email", value: this.form.email},
      {name: "firstName", value: this.form.firstName},
      {name: "lastName", value: this.form.lastName},
      {name: "role", value: this.form.title}
    ];
  }

  onEmailSend() {
    // this.validateForm()
    // if (this.isFormValid)
    this.sendForm();
  }

  async sendForm() {
    const email = this.form.email;
    console.log(email);
    const result = await hubspotApi.submitForm(this.formData);
    if (result.status == 200) {
      this.$emit("send", email);
    } else {
      // handle error
    }
  }
}
