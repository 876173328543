





























































































































































































































































































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import VideoFrame from "@/components/vizguaps/VideoFrame.vue";
import PlatformFAQ from "@/components/PlatformFAQ.vue";
import BookDemo from "@/components/vizguaps/BookDemo.vue";

import {Role, roleList} from "@/data";
import {TranslateResult} from "vue-i18n";
import {isMobile} from "@/utils/dom";

@Component({
  components: {
    VideoFrame,
    BookDemo,
    PlatformFAQ
  }
})
export default class VizguVillage extends Vue {
  mediaPath(file: string) {
    return require(`@/assets/images/vizgu-village/${file}`);
  }

  get isMobile() {
    return isMobile();
  }

  get videoHeight() {
    return this.isMobile ? "auto" : "1000";
  }

  media = {
    section1: {
      desktop1: this.mediaPath("section1-1-desktop.png"),
      mobile1: this.mediaPath("section1-1-mobile.png")
    },
    section2: {
      desktop1: this.mediaPath("section2-1-desktop.png"),
      mobile1: this.mediaPath("section2-1-mobile.png")
    },
    section3: {
      desktop1: this.mediaPath("section3-1-desktop.png"),
      mobile1: this.mediaPath("section3-1-mobile.png")
    },
    section4: {
      desktop1: this.mediaPath("section4-1-desktop.png"),
      mobile1: this.mediaPath("section4-1-mobile.png")
    },
    section5: {
      desktop1: this.mediaPath("section5-1-desktop.png"),
      mobile1: this.mediaPath("section5-1-mobile.png")
    },
    section6: {
      desktop1: this.mediaPath("section6-1-desktop.png"),
      mobile1: this.mediaPath("section6-1-mobile.png"),
      mobile2: this.mediaPath("section6-2-mobile.png")
    },
    section7: {
      desktop1: this.mediaPath("section7-1-desktop.png"),
      mobile1: this.mediaPath("section7-1-mobile.png")
    },
    section8: {
      desktop1: this.mediaPath("section8-1-desktop.png"),
      mobile1: this.mediaPath("section8-1-mobile.png")
    },
    section9: {
      desktop1: this.mediaPath("section9-1-desktop.png"),
      mobile1: this.mediaPath("section9-1-mobile.png")
    },
    section10: {
      desktop1: this.mediaPath("section10-1-desktop.png"),
      mobile1: this.mediaPath("section10-1-mobile.png")
    }
  };
}
