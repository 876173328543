









































































import BasePageBanner, {
  BasePageBannerData
} from "@/components/BasePageBanner.vue";
import ListItems from "@/components/ListItems.vue";
import {ItemTuple} from "@/types/homepage";
import {BaseData, VizguType} from "@/types/vizgu";
import {Component, Vue} from "vue-property-decorator";
import HighlightedSection from "@/components/HighlightedSection.vue";
import SelectSlider from "@/components/SelectSlider.vue";

@Component({
  components: {BasePageBanner, ListItems, HighlightedSection, SelectSlider}
})
export default class Artworks extends Vue {
  itemWidth = 200;

  exampleArtwork: ItemTuple<BaseData> = {
    item1: {
      name: "Sommeraften ved Skagens strand",
      position: null,
      canReview: false,
      extra: null,
      like: null,
      id: "123",
      imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
      isLocked: true,
      rating: 4.5,
      thumbImageURL: null,
      vizguType: VizguType.Artwork
    },
    item2: 1
  };

  get artworksData(): ItemTuple<BaseData>[] {
    // sample data for now
    const data: ItemTuple<BaseData>[] = [];
    for (let i = 0; i < 10; i++) {
      const item: ItemTuple<BaseData> = JSON.parse(
        JSON.stringify(this.exampleArtwork)
      );
      item.item1.id = `artwork-${i}`;
      data.push(item);
    }
    return data;
  }

  baseBannerData: BasePageBannerData = {
    imgUid: "e17fb04a-e32f-4041-92f1-abf608dbb73c",
    icon: "artwork",
    title: "Artworks",
    subtitle: `Find new exciting artwork and sculptures, and learn the amazing stories behind some of the biggest works around the world
    `
  };
}
