import "./class-component-hooks";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./assets/tailwind.css";
import "./sass/overrides.scss";
import vueDebounce from "vue-debounce";
import i18n from "@/plugins/i18n";
import store from "@/store/index";
import {Icon} from "@iconify/vue2";

Vue.config.productionTip = false;

Vue.use(vueDebounce);
Vue.component("Icon", Icon);

const app = new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
});
app.$store.dispatch("embedded/initialize");
app.$store.dispatch("cart/initialize");
app.$mount("#app");
