







































































































































































import {Component, Vue} from "vue-property-decorator";
import {Route, RouteConfig} from "vue-router";
import MobileSearchDialog from "./MobileSearchDialog.vue";
import DesktopSearchDialog from "./DesktopSearchDialog.vue";
import LocaleSwitchSelect from "./LocaleSwitchSelect.vue";
import {cartStore} from "@/store";

export interface MenuItem {
  icon: string;
  iconActive: string;
  title: string;
  route: string;
}

@Component({
  components: {DesktopSearchDialog, MobileSearchDialog, LocaleSwitchSelect}
})
export default class Navbar extends Vue {
  drawer = false;
  showNavbar = true;
  showBreadcrumbs = true;
  lastScrollPosition = 0;
  mobileShowSearchDialog = false;
  desktopShowSearchDialog = false;
  signInEnabled = false;

  extraRouteTranslations = {
    home: this.$t("Home")
  };

  get cartItemsCount() {
    return cartStore.cart?.items.length.toString() || "";
  }

  // labels
  get signInLabel() {
    return this.$t("Sign in");
  }
  // eof labels

  openMobileSearchBox() {
    this.mobileShowSearchDialog = true;
  }

  openDesktopSearchBox() {
    this.desktopShowSearchDialog = true;
  }

  mounted() {
    window.addEventListener("scroll", this.onScroll);
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll() {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollPosition < 0) {
      return;
    }

    if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
      return;
    }
    this.showNavbar = currentScrollPosition < this.lastScrollPosition;
    this.lastScrollPosition = currentScrollPosition;
  }

  get needToShowBreadcrumbs(): boolean {
    if (this.currentRoutePath.href === "/") {
      return false;
    }
    if (this.breadcrumbItems.length == 0) {
      return false;
    }
    return true;
  }

  get currentRoutePath() {
    const cr: Route = this.$route;
    const currentPath = {
      text: cr.name,
      disabled: true,
      href: cr.path
    };
    return currentPath;
  }

  get breadcrumbItemsMain() {
    if (this.$route.meta?.breadCrumb) {
      return this.breadcrumbItems;
    }
    return this.breadcrumbItemsDefault;
  }

  get breadcrumbItems() {
    if (typeof this.$route.meta?.breadCrumb === "function") {
      return this.$route.meta?.breadCrumb.call(this, this.$route);
    }
    return this.breadcrumbItemsDefault;
  }

  get breadcrumbItemsDefault() {
    const routes: RouteConfig[] | undefined = this.$router.options.routes;
    if (!routes || routes.length < 1) {
      return [];
    }

    const br: RouteConfig = routes[0];
    const basePath = {
      text: br.name,
      disabled: false,
      href: br.path
    };

    return [basePath, this.currentRoutePath];
  }

  get menuTitles() {
    return {
      places: this.$t("Destinations").toString(),
      venues: this.$t("Places").toString(),
      wayfinders: this.$t("Wayfinders").toString(),
      events: this.$t("Events").toString(),
      exhibitions: this.$t("Exhibitions").toString(),
      tickets: this.$t("Tickets").toString()
    };
  }

  get menuItems(): MenuItem[] {
    return [
      {
        icon: "menu-place",
        iconActive: "menu-places-on-click",
        title: this.menuTitles.places,
        route: "/places"
      },
      {
        icon: "menu-venue",
        iconActive: "menu-venue-on-click",
        title: this.menuTitles.venues,
        // route: "/venue"
        route: "/venues"
      },
      {
        icon: "menu-wayfinder",
        iconActive: "menu-wayfinder-on-click",
        title: this.menuTitles.wayfinders,
        // route: "/wayfinder"
        route: "/wayfinders"
      },
      /*
      {
        icon: "menu-event",
        iconActive: "menu-event-on-click",
        title: this.menuTitles.events,
        // route: "/event"
        route: "/events"
      },
      */
      {
        icon: "menu-exhibition",
        iconActive: "menu-exhibition-on-click",
        title: this.menuTitles.exhibitions,
        // route: "/exhibition"
        route: "/exhibitions"
      },
      {
        icon: "menu-ticket",
        iconActive: "menu-ticket-active",
        title: this.menuTitles.tickets,
        route: "/tickets"
      }
    ];
  }

  navigate() {
    if (this.$route.path == "/") {
      this.$vuetify.goTo(0);
    } else {
      this.$router.push("/");
    }
  }

  goCartPage() {
    this.$router.push("/cart");
  }
}
