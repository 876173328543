




























































































import BasePageBanner, {
  BasePageBannerData
} from "@/components/BasePageBanner.vue";
import ListItems from "@/components/ListItemsV2.vue";
import {ItemTuple} from "@/types/homepage";
import {BaseData, VenueV2, VizguType} from "@/types/vizgu";
import {Component, Vue} from "vue-property-decorator";
import HighlightedSection from "@/components/HighlightedSection.vue";
import SelectSlider from "@/components/SelectSlider.vue";
import {GetVenuesRequest, GetVenuesResponse} from "@/types/api";
import venuesApi from "@/api/venues";

@Component({
  components: {BasePageBanner, ListItems, HighlightedSection, SelectSlider}
})
export default class Cities extends Vue {
  totalItems = 0;
  itemWidth = 200;
  perPage = 20;
  initialDataLoaded = false;
  venuesCityList: VenueV2[] = [];

  exampleCity: ItemTuple<BaseData> = {
    item1: {
      name: "Copenhagen",
      position: null,
      canReview: false,
      extra: null,
      like: null,
      id: "123",
      imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
      isLocked: true,
      rating: 4.5,
      thumbImageURL: null,
      vizguType: VizguType.City
    },
    item2: 1
  };

  get citiesData(): VenueV2[] {
    return this.venuesCityList;
  }

  get hasMore(): boolean {
    return this.totalItems > this.venuesCityList.length;
  }

  get cityType(): VizguType {
    return VizguType.City;
  }
  /*
  get citiesData(): ItemTuple<BaseData>[] {
    // sample data for now
    const data: ItemTuple<BaseData>[] = [];
    for (let i = 0; i < 10; i++) {
      const item: ItemTuple<BaseData> = JSON.parse(
        JSON.stringify(this.exampleCity)
      );
      item.item1.id = `city-${i}`;
      data.push(item);
    }
    return data;
  }
  */

  baseBannerData: BasePageBannerData = {
    imgUid: "e17fb04a-e32f-4041-92f1-abf608dbb73c",
    icon: "place-location",
    title: "Cities",
    subtitle: `Explore cities - find new and exciting artwork and activities in all the coolest cities around the world`
  };

  // lifecycle methods
  created() {
    this.loadInitialData();
  }

  // data load methods
  private async loadInitialData() {
    await this.requestPlacesCity(true);
    this.initialDataLoaded = true;
  }
  private async requestPlacesCity(replace = false) {
    if (!replace && !this.hasMore && this.initialDataLoaded) {
      return;
    }
    const params: GetVenuesRequest = {
      skip: this.venuesCityList.length,
      take: this.perPage
    };
    const resp: GetVenuesResponse = await venuesApi.getVenues(params);
    this.totalItems = resp.count;
    const venues = resp.list.$values;
    if (replace) {
      this.venuesCityList = venues;
    } else {
      this.venuesCityList = this.venuesCityList.concat(venues);
    }
  }
}
