










import {Component, Vue, Prop} from "vue-property-decorator";
export interface SelectItem {
  value: string;
  label: string;
}
export const sampleSelectItems: SelectItem[] = [
  {value: "all", label: "All"},
  ...Array(30).fill({value: "aalborg", label: "Aalborg"})
];
@Component
export default class SelectSlider extends Vue {
  @Prop({type: Array, required: false, default: () => sampleSelectItems})
  items!: SelectItem[];
}
