import {ItemTuple} from "@/types/homepage";

export enum VizguTypeLink {
  Venue = "museum",
  Artwork = "artwork",
  Artist = "artist",
  Exhibition = "exhibition",
  Tour = "tour",
  Guide = "guide",
  Wayfinder = "wayfinder",
  Augmented = "augmented",
  Hunt = "hunt"
}

export enum VizguType {
  Artwork = 0,
  Artist = 1,
  Exhibition = 2,
  Place = 3,
  Venue = 4,
  Event = 21,
  Country = 16384,
  City = 8,
  Guide = 16,
  Wayfinder = 32,
  Review = 64,
  AugmentedReality = 128,
  Collection = 256
}

export type DayOfWeek =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

export interface Position {
  latitude: number;
  longitude: number;
  mapSpanLongitude: number;
  mapSpanLatitude: number;
}

export interface ArrayTuple<T> {
  $values: Array<T>;
}

export interface OpeningHours {
  monday?: string;
  mondayClose?: string;
  tuesday?: string;
  tuesdayClose?: string;
  wednesday?: string;
  wednesdayClose?: string;
  thursday?: string;
  thursdayClose?: string;
  friday?: string;
  fridayClose?: string;
  saturday?: string;
  saturdayClose?: string;
  sunday?: string;
  sundayClose?: string;
}

export interface OpeningInfo {
  displayText: string;
}

export interface BaseDataV2 {
  canReview: boolean;
  extra?: string; // slug
  id: string;
  imageGuids: ArrayTuple<string>;
  isLocked: boolean;
  like: boolean | null;
  name: string;
  position: Position | null;
  rating: number;
  thumbImageURL: string | null;
  vizguType: VizguType;
  description?: string;
  content?: BaseDataContent;
  artworksCount?: number;
}

export interface BaseDataContent {
  cdTexts: ArrayTuple<CDTextContent>;
}

export interface CDTextContent {
  textData: string;
}

export interface BaseData {
  canReview: boolean;
  extra: string | null;
  id: string;
  imageGuids: Array<string>;
  isLocked: boolean;
  like: boolean | null;
  name: string;
  position: Position | null;
  rating: number;
  thumbImageURL: string | null;
  vizguType: VizguType;
  artworksCount?: number;
}

export interface Exhibition extends BaseData {
  artworks: Array<Artwork> | null;
  begin: string;
  content: string | null;
  description: string | null;
  end: string;
  isPermanent: boolean;
  media: string | null;
  relatables: null;
  source: string | null;
  venueId: string;
  venueName: string | null;
  vernissage: string | null;
  artworksCount?: number;
}

export interface ExhibitionV2 extends BaseDataV2 {
  artworks: Array<Artwork> | null;
  begin?: string;
  content?: BaseDataContent;
  description?: string;
  end?: string;
  isPermanent: boolean;
  media: string | null;
  relatables: null;
  source: string | null;
  venueId: string;
  venueName: string | null;
  vernissage: string | null;
  artworksCount?: number;
}

export interface Wayfinder extends BaseData {
  begin: string | null;
  content: string | null;
  description: string | null;
  duration: string | null;
  end: string | null;
  lengthMeters: string | null;
  reviewData: string;
  showRoute: boolean | null;
  wayfinderDirections: null;
  wayfinderLines: null;
  wayfinderPoints: ArrayTuple<WayfinderPoint>;
}

export interface WayfinderPoint {
  sequenceNumber: number;
  id: string;
  name: string;
  imageGuids: ArrayTuple<string>;
  position: MapPosition;
  isHidden?: boolean;
}

export interface MapPosition {
  latitude: number;
  longitude: number;
  mapSpanLongitude: number;
  mapSpanLatitude: number;
}

export interface WayfinderV2 extends BaseDataV2 {
  begin: string | null;
  content?: BaseDataContent;
  description?: string;
  duration: string | null;
  end: string | null;
  lengthMeters: string | null;
  reviewData: string;
  showRoute: boolean | null;
  wayfinderDirections: null;
  wayfinderLines: null;
  wayfinderPoints: ArrayTuple<WayfinderPoint>;
}

export interface AugmentedReality extends BaseData {
  audioURL: string | null;
  content: string | null;
  info: string;
  modelID: string | null;
}

export interface Artwork extends BaseData {
  accessionNumber: string | null;
  acquiredYear: string | null;
  additionalNotes: string | null;
  artists: Array<Artist>;
  content: string | null;
  copyrightOwner: string | null;
  copyrighted: boolean;
  createdYear: string | null;
  description: string | null;
  dimensions: string | null;
  media: string | null;
  medium: string | null;
  period: string | null;
  photoCredit: string | null;
  physicalLocation: string | null;
  provenance: string | null;
  publicationDate: string | null;
  relatedArtworks: Array<Artwork> | null;
  relatedExhibitions: Array<Exhibition>;
  relatedGuides: Array<string>;
  relatedWayfinders: Array<Wayfinder>;
  reviewData: string | null;
  shortDescription: boolean;
  textProvidedBy: string | null;
}

export interface Artist extends BaseData {
  content: string | null;
  description: string | null;
  media: string | null;
  relatedArtworks: Array<Artwork> | null;
  relatedExhibitions: Array<Exhibition> | null;
  relatedGuides: Array<string> | null;
  relatedWayfinders: Array<Wayfinder> | null;
  reviewData: string | null;
  textProvidedBy: string | null;
}

export interface Venue extends BaseData {
  address: string | null;
  arItems: Array<AugmentedReality>;
  artworkItems: Array<Artwork>;
  city: string | null;
  content: string | null;
  country: string | null;
  description: string | null;
  enableReview: boolean;
  exhibitions: Array<Exhibition>;
  exhibitionsFuture: Array<Exhibition>;
  exhibitionsPast: Array<Exhibition>;
  guides: Array<string>;
  isAlwaysOpen: boolean;
  isCity: boolean;
  link: string;
  media: Array<string>;
  openingHours: string | null;
  regoin: string | null;
  reviewData: string | null;
  venues: Array<Venue>;
  wayfinders: Array<Wayfinder>;
  zipCode: string | null;
}

export interface VenueV2 extends BaseDataV2 {
  guidString: string;
  address: string | null;
  arItems: Array<AugmentedReality>;
  artworkItems: Array<Artwork>;
  city: string | null;
  content?: BaseDataContent;
  country: string | null;
  description?: string;
  enableReview: boolean;
  exhibitions: Array<Exhibition>;
  exhibitionsFuture: Array<Exhibition>;
  exhibitionsPast: Array<Exhibition>;
  guides: Array<string>;
  isAlwaysOpen: boolean;
  isCity: boolean;
  link: string;
  media: Array<string>;
  openingHours?: OpeningHours;
  openingInfo?: OpeningInfo;
  regoin: string | null;
  reviewData: string | null;
  venues: Array<Venue>;
  wayfinders: Array<Wayfinder>;
  zipCode: string | null;
}

export interface Place extends BaseData {
  content: string | null;
  address: string | null;
  venues: Array<Venue>;
}

export interface MapPoint {
  lat: number | null;
  lng: number | null;
  title?: string;
  // zoom?: number;
}

export function getSampleBaseDataList(): ItemTuple<BaseData>[] {
  return [
    {
      item1: {
        name: "Far from home",
        canReview: true,
        extra: null,
        id: "1234",
        imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
        isLocked: true,
        like: null,
        position: null,
        rating: 4.5,
        thumbImageURL: null,
        vizguType: 2
      },
      item2: 1
    },
    {
      item1: {
        name: "Far from home",
        canReview: true,
        extra: null,
        id: "1235",
        imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
        isLocked: true,
        like: null,
        position: null,
        rating: 4.5,
        thumbImageURL: null,
        vizguType: 2
      },
      item2: 1
    },
    {
      item1: {
        name: "Far from home",
        canReview: true,
        extra: null,
        id: "1236",
        imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
        isLocked: true,
        like: null,
        position: null,
        rating: 4.5,
        thumbImageURL: null,
        vizguType: 2
      },
      item2: 1
    },
    {
      item1: {
        name: "Far from home",
        canReview: true,
        extra: null,
        id: "1237",
        imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
        isLocked: true,
        like: null,
        position: null,
        rating: 4.5,
        thumbImageURL: null,
        vizguType: 2
      },
      item2: 1
    },
    {
      item1: {
        name: "Far from home",
        canReview: true,
        extra: null,
        id: "1238",
        imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
        isLocked: true,
        like: null,
        position: null,
        rating: 4.5,
        thumbImageURL: null,
        vizguType: 2
      },
      item2: 1
    },
    {
      item1: {
        name: "Far from home",
        canReview: true,
        extra: null,
        id: "1239",
        imageGuids: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"],
        isLocked: true,
        like: null,
        position: null,
        rating: 4.5,
        thumbImageURL: null,
        vizguType: 2
      },
      item2: 1
    }
  ];
}

export function getSampleBaseDataListV2(): BaseDataV2[] {
  return [
    {
      name: "Far from home",
      canReview: true,
      id: "1234",
      imageGuids: {$values: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"]},
      isLocked: true,
      like: null,
      position: null,
      rating: 4.5,
      thumbImageURL: null,
      vizguType: 2
    },
    {
      name: "Far from home",
      canReview: true,
      id: "1234",
      imageGuids: {$values: ["9f3de6ba-22eb-4a51-bc07-6ff7bfd15531"]},
      isLocked: true,
      like: null,
      position: null,
      rating: 4.5,
      thumbImageURL: null,
      vizguType: 2
    }
  ];
}
