





































































import {Component, Prop, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import {VCartItem} from "@/models/cart";
@Component({components: {GUIDImage}})
export default class CartItem extends Vue {
  @Prop({required: true}) item!: VCartItem;
  @Prop({required: false, default: false}) isLoading!: boolean;

  get eventName() {
    return this.item.event.name;
  }

  removeFromCart() {
    this.$emit("onRemove");
  }
}
