














































export interface WayfinderStopsProps {
  wayfinder: Wayfinder;
}

import {
  MapPoint,
  WayfinderPoint,
  WayfinderV2 as Wayfinder
} from "@/types/vizgu";
import {Component, Vue, Prop} from "vue-property-decorator";
import GMap, {GMapProps} from "@/components/GMap.vue";
import {thumbnailImageUri} from "@/utils/image-utils";
@Component({
  components: {GMap}
})
export default class WayfinderStops extends Vue {
  @Prop({}) props!: WayfinderStopsProps;

  activePoint: MapPoint | null = null;
  setActivePoint(p: MapPoint) {
    this.activePoint = p;
  }

  get wayfinder() {
    return this.props.wayfinder;
  }

  get stopPoints() {
    return this.wayfinder.wayfinderPoints.$values;
  }

  get enabledStopPoints() {
    return this.wayfinder.wayfinderPoints.$values.filter(v => !v.isHidden);
  }

  stopImages(v: WayfinderPoint) {
    return v.imageGuids.$values;
  }

  thumbImg(guid: string) {
    return thumbnailImageUri(guid);
  }

  mapPointFromWayfinderPoint(p: WayfinderPoint): MapPoint {
    return {
      lat: p.position.latitude || 0,
      lng: p.position.longitude || 0,
      title: p.name || ""
    };
  }

  onStopItemClick(stop: WayfinderPoint) {
    this.setActivePoint(this.mapPointFromWayfinderPoint(stop));
  }

  get mapConfig(): GMapProps {
    const mapStyles: google.maps.MapTypeStyle = {
      stylers: []
    };
    const mapPoints: MapPoint[] = this.enabledStopPoints.map(
      (p: WayfinderPoint) => {
        return {
          lat: p.position.latitude || 0,
          lng: p.position.longitude || 0,
          title: p.name
        };
      }
    );

    return {
      apiKey: "AIzaSyAWkPa63y66oU6Mzfkrnmc77Ns_nbH6eoI", // TODO add to env or get from api?
      mapPoints,
      input: "",
      mapStyles: [mapStyles],
      height: "370px",
      zoom: 16,
      activePoint: this.activePoint,
      setActivePoint: this.setActivePoint
    };
  }
}
