const isDev = false;
const useProd = false;

export const VIZGU_ORG_ID = "2b9f67f1-888f-4c50-9203-271cd13b8e20";

const enum APISources {
  V2BAzure = "https://v2b.azurewebsites.net/api/v1",
  newVizTestAPI = "https://new-viz.azurewebsites.net/api/public",
  newVizProductionAPI = "https://portal.vizgu.com/api/public",
  newVizTestAPIDev = "http://localhost:3001/api-public-dev",
  newVizProductionAPIDev = "http://localhost:3001/api-portal"
}

function getNewVizAPI() {
  if (isDev) {
    return useProd
      ? APISources.newVizProductionAPIDev
      : APISources.newVizTestAPIDev;
  } else {
    return useProd ? APISources.newVizProductionAPI : APISources.newVizTestAPI;
  }
}

const API = {
  mainAPI: APISources.V2BAzure,
  newViz: getNewVizAPI()
};
export const env = {
  API: API
};
