import {Module, Mutation, VuexModule, Action} from "vuex-module-decorators";

import {VenueV2, WayfinderV2, ExhibitionV2} from "@/types/vizgu";
import i18nPlugin, {LocaleString} from "@/plugins/i18n";
import VueI18n from "vue-i18n";
import {VizEvent} from "@/types/api.newviz/events";
import {localeList, SiteLocale} from "@/plugins/i18n";
import {VEvent} from "@/models/events";

export interface EmbeddedState {
  currentVenue: VenueV2 | null;
  currentExhibition: ExhibitionV2 | null;
  currentWayfinder: WayfinderV2 | null;
  snackbars: {
    clipboard: boolean;
  };
  i18n: VueI18n;
}

export enum EventKeys {
  locale = "internall-locale"
}

@Module({name: "embedded", namespaced: true})
export default class EmbeddedModule extends VuexModule
  implements EmbeddedState {
  timeNow: Date = new Date();

  currentVenue: VenueV2 | null = null;
  currentEvent: VEvent | null = null;
  currentVenueLoading = false;
  currentEventLoading = false;
  currentExhibition: ExhibitionV2 | null = null;
  currentWayfinder: WayfinderV2 | null = null;
  i18n = i18nPlugin;

  snackbars = {
    clipboard: false,
    notifyError: {
      open: false,
      text: ""
    }
  };

  loadingLabel = "Loading...";
  notFoundLabel = "Not Found";
  events = {
    locale: new Event(EventKeys.locale)
  };

  @Action
  dispatchLocaleEvent() {
    window.dispatchEvent(this.events.locale);
  }

  @Action
  initialize() {
    setInterval(() => {
      this.context.commit("setTimeNow", new Date());
    }, 1000);
    // restore locale
    const cachedLocale = window.localStorage.getItem("locale");
    if (cachedLocale) {
      //this.i18n.locale = cachedLocale;
    }
  }

  @Action
  setLocale(l: string) {
    this.i18n.locale = l;
    this.dispatchLocaleEvent();
    window.localStorage.setItem("locale", l);
  }

  get currentLocale(): SiteLocale {
    return localeList.find(l => l.code === this.i18n.locale) || localeList[0];
  }

  get apiLocaleFromI18n(): number {
    switch (this.i18n.locale) {
      case "en":
        return 2;
      case "da":
        return 1;
      case "nl":
        return 3;
      default:
        return 2;
    }
  }

  get currentVenueName() {
    if (this.currentVenueLoading) {
      return this.loadingLabel;
    }
    return this.currentVenue?.name || this.notFoundLabel;
  }

  get currentVenueCountry() {
    if (this.currentVenueLoading) {
      return this.loadingLabel;
    }
    return this.currentVenue?.name || this.notFoundLabel;
  }

  get currentVenueCity() {
    if (this.currentVenueLoading) {
      return this.loadingLabel;
    }
    return this.currentVenue?.city || this.notFoundLabel;
  }

  @Mutation
  setTimeNow(t: Date) {
    this.timeNow = t;
  }

  @Mutation
  setCurrentVenue(venue: VenueV2 | null) {
    this.currentVenue = venue;
  }

  @Mutation
  setCurrentEvent(event: VEvent | null) {
    this.currentEvent = event;
  }

  @Mutation
  setCurrentVenueLoading(isLoading: boolean) {
    this.currentVenueLoading = isLoading;
  }

  @Mutation
  setCurrentEventLoading(isLoading: boolean) {
    this.currentEventLoading = isLoading;
  }

  @Mutation
  setCurrentExhibition(exhibition: ExhibitionV2 | null) {
    this.currentExhibition = exhibition;
  }
  @Mutation
  setCurrentWayfinder(wayfinder: WayfinderV2 | null) {
    this.currentWayfinder = wayfinder;
  }

  @Mutation
  setClipboardSnackbar(v: boolean) {
    this.snackbars.clipboard = v;
  }

  @Mutation
  setNotifyErrorSnackbar(p: {open: boolean; text?: string}) {
    this.snackbars.notifyError.open = p.open;
    p.text && (this.snackbars.notifyError.text = p.text);
  }

  @Mutation
  notifyError(text: string) {
    this.snackbars.notifyError.text = text;
    this.snackbars.notifyError.open = true;
    // this.setNotifyErrorSnackbar({open: true, text});
  }
}
