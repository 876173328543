





















































































import {isMobile} from "@/utils/dom";
import {Component, Vue} from "vue-property-decorator";
import ContactInnerForm from "@/components/ContactInnerForm.vue";
import {is} from "date-fns/locale";
@Component({
  components: {ContactInnerForm}
})
export default class PlatformContactForm extends Vue {
  isActive = false;
  formSended = false;
  email = "";

  get iconsHeight() {
    return {
      top: isMobile() ? "105" : "170",
      bottom: isMobile() ? "220" : "300"
    };
  }

  setActive(v: boolean) {
    this.isActive = v;
  }
  toggleActive() {
    this.setActive(!this.isActive);
  }

  handleSend(email: string) {
    console.log("handled!", email);
    this.email = email;
    this.formSended = true;
  }

  get sentTitleFirst() {
    const f = this.$t("We’ve just sent you an Email to");
    return `${f} ${this.email}. `;
  }
  get sentTitleLast() {
    const l = this.$t("Start your journey with vizgu today!");
    return l;
  }

  media = {
    icons1: require("@/assets/images/footer/platform/form-icons1.png"),
    icons2: require("@/assets/images/footer/platform/form-icons2.png"),
    sentImage: require("@/assets/images/aboutus-v2/form-sent.png")
  };

  get formWindowStyle() {
    return {
      height: isMobile() ? "100%" : "500px",
      minHeight: isMobile() ? "80vh" : "auto",
      borderWidth: "8px"
    };
  }
}
