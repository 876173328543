


































import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {},
  data: () => ({
    image: require("@/assets/images/footer/partner.png")
  })
})
export default class BecomePartner extends Vue {}
