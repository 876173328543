
































































































































































































































































































































import IntroSignUpModal from "@/components/IntroSignUpModal.vue";
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {
    IntroSignUpModal
  }
})
export default class AboutUs extends Vue {
  isSignUpModalOpen = false;
  setSignUpModalOpen(isOpen: boolean) {
    this.isSignUpModalOpen = isOpen;
  }

  get signUpLabel() {
    return this.$t("Sign up for an introduction letter");
  }

  get cocreation(): string {
    return require("@/assets/images/footer/co-creation.png");
  }

  images = {
    mainBanner: require("@/assets/images/aboutus/main-banner.png"),
    launchBanner: {
      main: require("@/assets/images/aboutus/launch-main.png"),
      logo: require("@/assets/images/aboutus/launch-logo.png"),
      desktop: require("@/assets/images/aboutus/launch-desktop.png"),
      mobile: require("@/assets/images/aboutus/launch-mobile.png"),
      mobileMain: require("@/assets/images/aboutus/launch-vizgu-mobile.png")
    },
    platform: {
      main: require("@/assets/images/aboutus/platform-main.png")
    }
  };

  get teamMembers(): {
    name: string;
    role: string;
    secondRole: string;
    image: string;
  }[] {
    return [
      {
        name: "Esmir Juhl Ibricic",
        role: "CEO",
        secondRole: "Co - founder",
        image: require("@/assets/images/footer/team/esmir.png")
      },
      {
        name: "Peter Madsen",
        role: "Backend developer",
        secondRole: "Co - founder",
        image: require("@/assets/images/footer/team/peter-madsen.png")
      },
      {
        name: "Peter Krusager",
        role: "Backend developer",
        secondRole: "Co - founder",
        image: require("@/assets/images/footer/team/peter-krusager.png")
      },
      {
        name: "Mensur Ibricic",
        role: "Co - creation & strategy",
        secondRole: "Co - founder",
        image: require("@/assets/images/footer/team/mensur.png")
      },
      {
        name: "Alicia Busborg",
        role: "& UI designer",
        secondRole: "Video editor",
        image: require("@/assets/images/footer/team/alicia-busborg.png")
      }
    ];
  }
}
