import Vue from "vue";
import Vuex, {Store} from "vuex";
import {getModule} from "vuex-module-decorators";
import EmbeddedModule, {EmbeddedState} from "./modules/embedded";
import CartModule, {CartState} from "./modules/cart";

Vue.use(Vuex);

export let embeddedStore: EmbeddedModule;
export let cartStore: CartModule;

const initializer = (store: Store<AppState>) => {
  embeddedStore = getModule(EmbeddedModule, store);
  cartStore = getModule(CartModule, store);
};

export interface AppState {
  embedded: EmbeddedState;
  cart: CartState;
}

export default new Vuex.Store<AppState>({
  plugins: [initializer],
  modules: {
    embedded: EmbeddedModule,
    cart: CartModule
  }
});
