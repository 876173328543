
















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {MapPoint} from "@/types/vizgu";

interface WindowWithGMaps {
  initMap: () => void;
}

export interface GMapProps {
  apiKey: string;
  showSearch?: boolean;
  mapPoints: Array<MapPoint>;
  input: string;
  mapStyles: Array<google.maps.MapTypeStyle>;
  markerIcon?: any;
  height?: string;
  zoom?: number;
  activePoint?: MapPoint | null;
  setActivePoint?: (p: MapPoint) => void;
  markerConfig?: {
    path?: string;
    fillColor: string;
    scale?: number;
  };
}

const DefaultZoom = 16;

@Component({
  components: {}
})
export default class GMap extends Vue {
  @Prop() props!: GMapProps;

  get mapStyle() {
    return {
      height: this.props.height || "256px"
    };
  }

  get apiKey() {
    return this.props.apiKey;
  }
  get showSearch() {
    return this.props.showSearch;
  }
  get mapPoints() {
    return this.props.mapPoints;
  }
  get input() {
    return this.props.input;
  }
  get mapStyles() {
    return this.props.mapStyles;
  }

  get markerConfig() {
    return this.props.markerConfig;
  }

  get markerIcon() {
    return this.props.markerIcon;
  }

  get zoom() {
    return this.props.zoom;
  }

  location = "";
  map!: google.maps.Map;
  markers: Array<google.maps.Marker> = [];
  marker!: google.maps.Marker;
  infoWindow!: google.maps.InfoWindow;
  listeners: Array<google.maps.MapsEventListener> = [];

  get mapVisible() {
    return this.mapPoints.length > 0;
  }

  assertMapIsCreated() {
    const config: google.maps.MapOptions = {
      disableDefaultUI: true,
      gestureHandling: "none",
      styles: this.mapStyles || []
    };
    if (!this.map) {
      this.map = new window.google.maps.Map(
        this.$refs["map"] as HTMLElement,
        config
      );
      const icon = this.markerIcon
        ? Object.assign({}, this.markerIcon)
        : undefined;
      if (icon && icon.path === "circle") {
        icon.path = window.google.maps.SymbolPath.CIRCLE;
      }
      this.marker = new window.google.maps.Marker({
        map: this.map,
        icon: icon
      });
      this.infoWindow = new window.google.maps.InfoWindow();
    }
  }

  findMarketFromPoint(p: MapPoint): google.maps.Marker | undefined {
    return this.markers.find(m => {
      const pos = m.getPosition();
      return pos?.lng() == p.lng && pos?.lat() == p.lat;
    });
  }

  getPointFromMarker(m: google.maps.Marker): MapPoint {
    const pos = m.getPosition();
    return {
      lat: pos?.lat() || 0,
      lng: pos?.lng() || 0,
      title: m.getTitle() || ""
    };
  }

  onMarkerClick(marker: google.maps.Marker) {
    this.props.setActivePoint
      ? this.props.setActivePoint(this.getPointFromMarker(marker))
      : this.setInfoWindowFromMarker(marker);
  }

  get markerIconDefault() {
    return {
      path:
        this.markerConfig?.path ||
        "M84.312 137.088c1.944-2.606 4.77-4.377 7.866-5.37 24.183-7.761 41.697-30.543 41.697-57.438 0-33.292-26.835-60.28-59.938-60.28C40.836 14 14 40.988 14 74.28c0 26.895 17.514 49.677 41.697 57.438 3.096.993 5.923 2.764 7.866 5.37l7.629 10.23a3.425 3.425 0 0 0 5.491 0l7.629-10.23Z",
      fillColor: this.markerConfig?.fillColor || "#5AD69F",
      fillOpacity: 1.0,
      strokeWeight: 0,
      // rotation: 0,
      scale: this.markerConfig?.scale || 0.2,
      anchor: new window.google.maps.Point(0, 20)
    };
  }

  drawMapMarkers() {
    this.mapPoints.forEach(p => {
      const marker = new window.google.maps.Marker({
        title: p.title,
        position: {lat: p.lat || 0, lng: p.lng || 0},
        clickable: true,
        optimized: false,
        icon: this.markerIconDefault,
        // icon: "/ext/icons/venue.svg",
        // label: p.title,
        map: this.map
      });
      marker.setVisible(true);
      marker.addListener("click", () => this.onMarkerClick(marker));
      this.markers.push(marker);
    });
  }

  setInfoWindowFromMarker(marker: google.maps.Marker) {
    this.infoWindow.close();
    const content = marker.getTitle();
    this.infoWindow.setContent(content);
    this.infoWindow.open(this.map, marker);
  }

  initMap() {
    if (this.mapPoints.length > 0) {
      this.assertMapIsCreated();
      // this.marker.setVisible(false);

      this.map.setZoom(this.zoom || DefaultZoom);
      this.drawMapMarkers();

      const latlng = {
        lat: this.markers[0].getPosition()?.lat() || 0,
        lng: this.markers[0].getPosition()?.lng() || 0
      } as google.maps.LatLngLiteral;
      this.map.setCenter(latlng);

      window.google.maps.event.addListenerOnce(this.map, "idle", () => {
        this.$emit("gmap-loaded");
      });
    }
  }

  initLocationSearch() {
    const options = {
      fields: ["formatted_address", "geometry", "name"]
    } as google.maps.places.AutocompleteOptions;
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("location") as HTMLInputElement,
      options
    );
    this.listeners.push(
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.geometry) {
          let lat = 0;
          let lng = 0;
          const zoom = DefaultZoom;
          if (place.geometry.viewport) {
            lat = place.geometry.viewport.getCenter().lat();
            lng = place.geometry.viewport.getCenter().lng();
          } else if (place.geometry.location) {
            lat = place.geometry.location.lat();
            lng = place.geometry.location.lng();
          }
          this.$emit(
            "location-changed",
            place.formatted_address,
            place.name || "",
            this.location,
            {lat, lng, zoom} as MapPoint
          );
        }
      })
    );
  }

  @Watch("props.activePoint")
  onActivePointChange() {
    const m = this.props.activePoint
      ? this.findMarketFromPoint(this.props.activePoint)
      : undefined;
    m && this.setInfoWindowFromMarker(m);
  }

  @Watch("mapPoints")
  setMapToPoint(newPoints?: MapPoint, oldPoints?: MapPoint) {
    if (JSON.stringify(newPoints) != JSON.stringify(oldPoints)) {
      this.initMap();
    }
  }

  initMapAndLocationSearch() {
    this.location = this.input;
    this.initMap();
    this.initLocationSearch();
  }

  async initGoogleMapsScript(callback: () => void) {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?callback=initMap&libraries=places&key=" +
      this.apiKey;
    const w = (window as unknown) as WindowWithGMaps;
    w.initMap = callback;
    document.body.appendChild(script);
  }

  mounted() {
    if (!window.google.maps) {
      this.initGoogleMapsScript(this.initMapAndLocationSearch);
    } else {
      this.initMapAndLocationSearch();
    }
  }

  beforeDestroy() {
    this.listeners.forEach(listener => {
      window.google.maps.event.removeListener(listener);
    });
  }
}
