















































































































































































































































































































































































































































































































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import VideoFrame from "@/components/vizguaps/VideoFrame.vue";
import BookDemo from "@/components/vizguaps/BookDemo.vue";
import PlatformContactForm from "@/components/PlatformContactForm.vue";
import PlatformFAQ from "@/components/PlatformFAQ.vue";
import {Role, roleList} from "@/data";
import {TranslateResult} from "vue-i18n";
import {isMobile} from "@/utils/dom";
import {is} from "date-fns/locale";

@Component({
  components: {
    VideoFrame,
    BookDemo,
    PlatformContactForm,
    PlatformFAQ
  }
})
export default class VizguPlatform extends Vue {
  currentRole: Role | null = null;
  featureCards: Array<TranslateResult> = [
    this.$t("In-APP purchases"),
    this.$t("Tour Guide booking"),
    this.$t("Digital  AR guides"),
    this.$t("3D audio guides"),
    this.$t("Tickets"),
    this.$t("Mixed reality guides"),
    this.$t("Object detection")
  ];

  get isMobile() {
    return isMobile();
  }

  get videoHeight() {
    return this.isMobile ? "auto" : "1000";
  }

  setRole(v: Role) {
    this.currentRole = v;
  }

  mediaPath(file: string) {
    return require(`@/assets/images/vizgu-platform/${file}`);
  }

  platformMediaPath(file: string) {
    return require(`@/assets/images/footer/platform/${file}`);
  }

  get l() {
    return {
      rolePickerTitle: this.$t(
        "Help <span class='text-green3'>us</span> understand who <span class='text-green3'>you</span> are"
      )
    };
  }

  get roles(): Role[] {
    return roleList.map(v => {
      return {
        title: this.$t(v.title).toString(),
        description: this.$t(v.description).toString(),
        image: v.image
      };
    });
  }

  get chooseRoleTitle() {
    return this.currentRole
      ? this.currentRole.title
      : this.$t("Choose a persona");
  }

  media = {
    section1: this.mediaPath("section-1.png"),
    section2: {
      items: [
        {
          src: this.mediaPath("icon-graphic.svg"),
          title: this.$t("E-commerce")
        },
        {
          src: this.mediaPath("icon-route.svg"),
          title: this.$t("Tour guides")
        },
        {
          src: this.mediaPath("icon-credit-card.svg"),
          title: this.$t("In-app sales")
        },
        {
          src: this.mediaPath("icon-donation-calendar.svg"),
          title: this.$t("Donations")
        },
        {
          icon: "ticket",
          title: this.$t("Tickets")
        },
        {
          src: this.mediaPath("icon-membership.svg"),
          title: this.$t("Memberships")
        },
        {
          icon: "plus-sign",
          title: this.$t("& much more...")
        }
      ]
    },
    ticketing: {
      mobile1: this.mediaPath("ticketing-system-1-mobile.png"),
      mobile2: this.mediaPath("ticketing-system-2-mobile.png"),
      desktop1: this.mediaPath("ticketing-system-1-desktop.png"),
      desktop2: this.mediaPath("ticketing-system-2-deskop.png")
    },
    donation: {
      mobile1: this.mediaPath("donation-1-mobile.png"),
      mobile2: this.mediaPath("donation-2-mobile.png"),
      desktop1: this.mediaPath("donation-1-desktop.png"),
      desktop2: this.mediaPath("donation-2-desktop.png")
    },
    tourguide: {
      mobile1: this.mediaPath("tourguide-1-mobile.png"),
      desktop1: this.mediaPath("tourguide-1-desktop.png")
    },
    webshop: {
      mobile1: this.mediaPath("webshop-1-mobile.png"),
      desktop1: this.mediaPath("webshop-1-desktop.png")
    },
    membership: {
      mobile1: this.mediaPath("membership-1-mobile.png"),
      mobile2: this.mediaPath("membership-2-mobile.png"),
      desktop1: this.mediaPath("membership-1-desktop.png"),
      desktop2: this.mediaPath("membership-2-desktop.png")
    },
    curation: {
      mobile1: this.mediaPath("curation-1-mobile.png"),
      mobile2: this.mediaPath("curation-2-mobile.png"),
      desktop1: this.mediaPath("curation-1-desktop.png")
    },
    gamification: {
      mobile1: this.mediaPath("gamification-1-mobile.png"),
      desktop1: this.mediaPath("gamification-1-desktop.png")
    },
    phoneStack1: this.platformMediaPath("phone-stack1.png"),
    desktopPhoneStack: this.platformMediaPath("desktop-phone-stack.png")
  };
}
