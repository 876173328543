


































































import {Component, Vue} from "vue-property-decorator";
import Rating from "@/components/Rating.vue";
import AvatarGroup from "@/components/AvatarGroup.vue";

export interface InformationItem {
  icon: string | null;
  title: string;
}

@Component({
  components: {AvatarGroup, Rating}
})
export default class EventInfoBox extends Vue {
  get eventInfo(): InformationItem[] {
    return [
      {
        icon: "calendar",
        title: "18 Jan 2022"
      },
      {
        icon: "clock",
        title: "2 hrs (lorem ipsum dolor sit amet)"
      },
      {
        icon: "place-location",
        title: "Tondervej 60, 6000 Koldingg"
      }
    ];
  }
}
