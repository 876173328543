












import Vue from "vue";
import LayoutBroker from "@/baseComponents/LayoutBroker.vue";
import FrontPageLayout from "@/views/layouts/FrontPageLayout.vue";
import PublicPageLayout from "@/views/layouts/PublicPageLayout.vue";
import VizguApSLayout from "@/views/layouts/VizguApSLayout.vue";
import Icons from "@/components/Icons.vue";
import Snackbars from "@/components/Snackbars.vue";

export default Vue.extend({
  name: "App",
  components: {LayoutBroker, Icons, Snackbars},
  data: () => ({
    layouts: {
      FrontPageLayout,
      PublicPageLayout,
      VizguApSLayout
    }
  })
});
