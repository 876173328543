









































































































import {Component, Prop, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import {BaseDataV2, VizguType} from "@/types/vizgu";
import {
  getItemHrefFromBaseData,
  getItemsHrefFromBaseData
} from "@/utils/routing";
import CardItemSkeleton from "./CardItemSkeleton.vue";
import {ImageAPI} from "@/utils/image-utils";
// import {ItemTuple} from "@/types/homepage";

@Component({
  components: {GUIDImage, CardItemSkeleton}
})
export default class ItemSlideGroup extends Vue {
  @Prop() dataList!: BaseDataV2[];
  @Prop({required: false, type: Boolean, default: false}) loading!: boolean;
  @Prop() title!: string;
  @Prop({type: String, default: undefined}) titleSize!: string;
  @Prop({type: String, default: undefined}) titleWeight!: string;
  @Prop({type: String, default: undefined}) titlePadding!: string;
  @Prop({type: Boolean, required: false, default: true}) useSubtitle!: boolean;
  @Prop({type: Boolean, required: false, default: false}) useLocation!: boolean;
  @Prop() itemWidth!: number;
  @Prop({type: String, required: false, default: "venue"}) cardTopIcon!: number;
  @Prop({type: Number, required: false, default: 20}) iconSize!: number;
  @Prop({type: String, required: false, default: undefined})
  viewAllHref!: string;
  @Prop({type: Boolean, required: false, default: true})
  showViewAll!: boolean;
  @Prop({type: Boolean, default: true}) useVizguTypeRouting!: boolean;
  @Prop({default: undefined, required: false}) contentType!: VizguType;
  @Prop({default: undefined, required: false}) imageSrcHandler!: (
    el: BaseDataV2 | any
  ) => string;
  @Prop({required: false, default: undefined}) imageAPI?: ImageAPI;

  itemImage(v: BaseDataV2) {
    if (this.imageSrcHandler) {
      return this.imageSrcHandler(v);
    }
    return v?.imageGuids?.$values?.[0] || null;
  }

  // labels
  get viewAllLabel() {
    return this.$t("view all");
  }
  artworksLabel(e: BaseDataV2) {
    const c = e.artworksCount;
    if (!c) {
      return "";
    }
    const l = this.$t("artworks");
    return `${c} ${l}`;
  }
  get awayLabel() {
    return this.$t("away");
  }
  // eof labels

  handleItemHref(item: BaseDataV2) {
    if (this.useVizguTypeRouting) {
      const r = getItemHrefFromBaseData(item, this.contentType);
      this.$router.push(r);
    } else {
      this.$emit("onItemHref", item);
    }
  }

  goViewAllPage() {
    let l = "";
    if (this.viewAllHref) {
      l = this.viewAllHref;
    } else {
      if (this.dataList.length > 0) {
        l = getItemsHrefFromBaseData(this.dataList[0], this.contentType);
      }
    }
    this.$router.push(l);
  }
}
