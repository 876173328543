







































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import ItemSlideGroup from "@/components/ItemSlideGroupV2.vue";
import Review from "@/components/Review.vue";
import Rating from "@/components/Rating.vue";
import ExhibitionCard from "@/components/ExhibitionCard.vue";
import VideoCard from "@/components/VideoCard.vue";
import VideoList from "@/components/VideoList.vue";
import GUIDImage from "@/components/GUIDImage.vue";
import {embeddedStore} from "@/store";
import {ExhibitionV2, VizguTypeLink} from "@/types/vizgu";
import exhibitionsApi from "@/api/exhibitions";
import {copyCurrentRouteClipboard} from "@/utils/clipboard";
import {EventKeys} from "@/store/modules/embedded";
import {parseBaseContentText} from "@/utils/data";
import {parseDateToString} from "@/utils/datetime";
import {openMobileLinkFor} from "@/utils/mobileLink";

export interface InformationItem {
  icon: string | null;
  title: string;
}

@Component({
  components: {
    VideoList,
    ExhibitionCard,
    Rating,
    Review,
    ItemSlideGroup,
    VideoCard,
    GUIDImage
  }
})
export default class Exhibition extends Vue {
  likeFeature = false;
  ratingFeature = false;

  showBaseMedia = false;
  showAdditionalMedia = false;
  showReviews = false;

  @Watch("$route.params.id")
  onIdChange() {
    window.scrollTo(0, 0);
    this.loadApiData();
  }

  openMobile() {
    openMobileLinkFor({
      type: VizguTypeLink.Exhibition,
      id: this.currentExhibition?.id || ""
    });
  }

  get exhibitionInfo(): InformationItem[] {
    const r = [];
    this.exhibitionDateStart &&
      r.push({icon: "calendar", title: this.exhibitionDateStart || ""});
    this.exhibitionDateEnd &&
      r.push({icon: "calendar", title: this.exhibitionDateEnd || ""});
    return r;
  }

  get exhibitionDateStart(): string | null {
    const dateStart = this.currentExhibition?.begin;
    if (dateStart) {
      try {
        return parseDateToString(new Date(dateStart));
      } catch {
        return null;
      }
    }
    return null;
  }

  get exhibitionDateEnd(): string | null {
    const dateStart = this.currentExhibition?.end;
    if (dateStart) {
      try {
        return parseDateToString(new Date(dateStart));
      } catch {
        return null;
      }
    }
    return null;
  }

  shareLink() {
    copyCurrentRouteClipboard(this.$route);
  }

  currentExhibition: ExhibitionV2 | null = null;

  get exhibitionName(): string {
    return this.currentExhibition?.name || "";
  }

  get exhibitionLocation(): string {
    return this.currentExhibition?.venueName || "";
  }

  get exhibitionImgSrc(): string {
    return this.currentExhibition?.imageGuids.$values[0] || "";
  }

  get aboutDescription(): string {
    return this.currentExhibition
      ? parseBaseContentText(this.currentExhibition)
      : "";
  }

  initListeners(enable: boolean) {
    if (enable) {
      window.addEventListener(EventKeys.locale, this.onLocaleEvent);
    } else {
      window.removeEventListener(EventKeys.locale, this.onLocaleEvent);
    }
  }

  onLocaleEvent() {
    this.loadApiData();
  }

  async created() {
    this.initListeners(true);
    this.loadApiData();
  }

  beforeDestroy() {
    this.initListeners(false);
  }
  // eof lifecycle methods

  private async loadApiData() {
    embeddedStore.setCurrentExhibition(null);
    const id: string = this.$route.params.id;
    await this.requestExhibition(id);
  }

  // data load methods
  private async requestExhibition(id: string) {
    const resp: ExhibitionV2 = await exhibitionsApi.getExhibition(id);
    this.currentExhibition = resp;
    embeddedStore.setCurrentExhibition(resp);
  }
}
