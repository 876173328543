export type Role = {
  title: string;
  description: string;
  image(): string;
};

export const roleList: Role[] = [
  {
    title: "I’m a curator",
    description:
      "Manage your collection. Create digital guides. Engage with your audience in real time. A platform designed with curators - Vizgu is your creative toolbox- allowing you to create a digital, inclusive & playful layer to your exhibitions.  \n\n Engage with your audience in real time with push invites and notifications. Imagine this: A visitor is walking in your museum park- suddenly a notification pops up: Hello Friend. In 15 minutes, there is a 15-minute presentation on our new exhibition by the artist himself, come and join us and get a free coffee.  That is called real-time crowd nudging and is of course co-created with curators and experienced designers.",

    image: () => require("@/assets/images/footer/platform/role-curator.png")
  },
  {
    title: "I’m a Museum Director",
    description:
      "As a Museum Director,\n Get a comprehensive view of all essential processes - from donations to real-time data dashboards - expertly designed with your unique workflows in mind. \n\n Vizgu offers complete oversight of critical workflows through tailored dashboards at both institutional and municipal levels, empowering you to make informed decisions and drive success. \n\n Embrace the power of Vizgu and elevate your museum management experience today!",
    image: () => require("@/assets/images/footer/platform/role-director.png")
  },
  {
    title: "I work with Guides (people)",
    description:
      "Empower Your Guides with Vizgu Platform: Streamlined Management & Booking! \n\n Our powerful people management tool, co-created with guides and cultural institutions, revolutionizes the guide booking process. Automate everything from booking to payment, all in one platform. \n\n Easily build your guide database, communicate, and book with them in one convenient location. Say farewell to lengthy wait and response times, as Vizgu automates the entire process for you.",
    image: () =>
      require("@/assets/images/footer/platform/role-work-with-guides.png")
  },

  {
    title: "I tell stories about my city and it’s heritage",
    description:
      "Share Your City's or Institutions Heritage with Vizgu: Engaging, Immersive Storytelling! \n Let Vizgu bring your city's & institutions’ stories to life through digital guides featuring Augmented Reality, audio, and Geo Location triggers. Let your visitors dive into a captivating experience! \n Not only do our guides enrich visitor journeys, but they also gather real-time data on city & institution usage by both citizens and tourists, providing invaluable insights for future improvements.",
    image: () =>
      require("@/assets/images/footer/platform/role-story-teller.png")
  },

  {
    title:
      "I am a city marketing Professional within Art, Culture and City Marketing:",

    description:
      "Unleash Your Marketing Potential: Art, Culture & City Marketing Unified! \n\n Harness the power of Vizgu Platform, designed specifically for art, culture, and city marketing professionals. \n\n Our platform enables Marketing, Curation, Operation, and PR teams to collaborate seamlessly, eliminating the need for multiple applications. Created with input from experts like you, Vizgu empowers your team to excel in your industry.",
    image: () => require("@/assets/images/footer/platform/role-marketing.png")
  },

  {
    title: "I help collecting donations",
    description:
      "Revolutionize Your Fundraising: Collect Donations Effortlessly! \n\n Seeking a cost-effective way to manage donations? Vizgu Platform offers a seamless solution, integrating a donation feature on your website and within our app through our API. \n\n Picture this: Collect donations on your homepage, and prompt Vizgu users for non-intrusive donations upon exiting your location. This approach has proven successful in helping museums and other institutions fund specific projects. Our donation feature supports both corporate and private contributions, simplifying your fundraising process.",
    image: () =>
      require("@/assets/images/footer/platform/role-help-donations.png")
  },
  {
    title: "I develop Podcasts and guides!",
    description:
      "Elevate Your Podcasts & Guides: Storytelling Meets Cutting-Edge Tech! \n\n If you're into storytelling and digital curation, Vizgu offers the ultimate platform to captivate your audience. Share your incredible stories through the Vizgu app and marketplace, leaving pod walk apps behind. \n\n Our toolbox includes AR, Mixed Reality, Audio, Geofencing, and more, providing an unmatched user experience in digital curation. Vizgu is your go-to solution for taking your podcasts and guides to new heights!",
    image: () =>
      require("@/assets/images/footer/platform/role-develop-guides.png")
  },
  {
    title: "I am responsible for tickets, E-commerce & commercial activities",

    description:
      "Unlock the Ultimate Ticketing & E-commerce Experience! Transform your ticketing, e-commerce, and commercial activities with our cutting-edge solutions designed specifically for your needs. Streamline processes, maximize revenue, and enhance visitor satisfaction with ease. \n\n Key benefits: \n Customizable Ticketing: Time-gated, hybrid, and dynamic pricing options for a tailored visitor experience. \n Seamless E-commerce: Boost sales with an intuitive platform that drives merchandise and upsells effortlessly. \n",
    image: () => require("@/assets/images/footer/platform/role-ecommerce.png")
  },

  {
    title: "I work with Memberships",

    description:
      "Maximize Memberships & Retention: Dynamic Management for Private & Corporate Communities! \n\n Elevate your membership strategy with personalized engagement, exclusive perks, and seamless communication. Our solution simplifies membership management for both private and corporate sectors, fostering loyalty and long-term commitment. \n\n Transform your community and watch it thrive. Contact us today!",
    image: () => require("@/assets/images/footer/platform/role-membership.png")
  }
];
