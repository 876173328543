import {ApiClient} from "./client";
import {
  CreatePaymentRequest,
  CreatePaymentResult,
  VizOrder
} from "@/types/api.newviz/payment";

class PaymentApi extends ApiClient {
  public createPayment = async (
    params: CreatePaymentRequest
  ): Promise<CreatePaymentResult> => {
    const {data} = await this.client.post<CreatePaymentResult>(
      `/payments`,
      params.data,
      {
        baseURL: this.currentApi,
        params: {orgId: this.vizguOrgId},
        headers: {
          "Content-Type": "application/internal+json"
        }
      }
    );
    return data;
  };

  public getOrderById = async (params: {
    orderId: string;
  }): Promise<VizOrder> => {
    const {orderId} = params;
    const {data} = await this.client.get<VizOrder>(`/orders/${orderId}`, {
      baseURL: this.currentApi,
      params: {orgId: this.vizguOrgId},
      headers: {
        "Content-Type": "application/internal+json"
      }
    });
    return data;
  };
}

const paymentApi = new PaymentApi();
export default paymentApi;
