






























































import {Component, Vue} from "vue-property-decorator";
import {TabItem} from "@/views/Venue.vue";
import ContactContainer from "@/components/support/ContactContainer.vue";
import {supportSubpages} from "@/utils/footer";
import {TranslateResult} from "vue-i18n";
import {isMobile} from "@/utils/dom";

@Component({
  components: {ContactContainer},
  data: () => ({
    image: require("@/assets/images/footer/support.png"),
    tab: ""
  })
})
export default class Support extends Vue {
  get supportSubpages(): TabItem[] {
    return supportSubpages;
  }

  get isMobile() {
    return isMobile();
  }

  get legalNoticeInfo(): {header: string | TranslateResult; info: string}[] {
    return [
      {
        header: this.$t("Website Host"),
        info: "Vizgu Aps\n Jernbanegade 2 2b\n 6000 Kolding\n Denmark"
      },

      {
        header: this.$t("Management"),
        info: "Esmir Juhl Ibricic"
      },

      {
        header: this.$t("Company Registration"),
        info: "Denmark"
      },
      {
        header: "VAT Reg No",
        info: "DK36536969"
      }
    ];
  }
}
