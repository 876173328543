import {ApiClient} from "./client";
import {
  CreateCartRequest,
  VizCart,
  AddToCartRequest,
  RemoveFromCartRequest,
  GetCartByIdRequest,
  RecalculateCartRequest
} from "@/types/api.newviz/cart";

class CartApi extends ApiClient {
  public createCart = async (params: CreateCartRequest): Promise<VizCart> => {
    const {data} = await this.client.put<VizCart>(`/carts`, params, {
      baseURL: this.currentApi,
      params: {
        orgId: this.vizguOrgId
      },
      headers: {
        "Content-Type": "application/internal+json"
      }
    });
    return data;
  };
  public getCartById = async (params: GetCartByIdRequest): Promise<VizCart> => {
    const {data} = await this.client.get<VizCart>(`/carts/${params.cartId}`, {
      baseURL: this.currentApi
    });
    return data;
  };
  public addToCart = async (params: AddToCartRequest): Promise<VizCart> => {
    const {data} = await this.client.put<VizCart>(`/carts`, params, {
      baseURL: this.currentApi,
      params: {orgId: this.vizguOrgId},
      headers: {"Content-Type": "application/internal+json"}
    });
    return data;
  };
  public removeFromCart = async (
    params: RemoveFromCartRequest
  ): Promise<VizCart> => {
    const {data} = await this.client.put<VizCart>(`/carts/revoke`, params, {
      baseURL: this.currentApi,
      params: {orgId: this.vizguOrgId},
      headers: {"Content-Type": "application/internal+json"}
    });
    return data;
  };

  // POST recalcucalte elements in cart with currency and return recalculated cart
  public recalculateCart = async (
    params: RecalculateCartRequest
  ): Promise<VizCart> => {
    const {cartId, currency} = params;
    // TODO check if returned type is really viz cart
    const {data} = await this.client.post<VizCart>(
      `/carts/${cartId}/withCurrency/${currency}`,
      params,
      {
        baseURL: this.currentApi,
        params: {orgId: this.vizguOrgId},
        headers: {"Content-Type": "application/internal+json"}
      }
    );
    return data;
  };
}

const cartApi = new CartApi();
export default cartApi;
