var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasePageBanner',{attrs:{"data":_vm.baseBannerData}}),_c('div',[(false)?_c('div',{staticClass:"mt-16"},[(_vm.eventsVenues.length > 0)?_c('ItemSlideGroup',{attrs:{"data-list":_vm.eventsVenues,"title":_vm.$t('Tickets to venues'),"item-width":320,"card-top-icon":"place-location","viewAllHref":'/tickets',"useVizguTypeRouting":false},on:{"onItemHref":function (item) {}}}):_vm._e()],1):_vm._e(),(false)?_c('div',{staticClass:"mt-16"},[(_vm.eventsTicketsList.length > 0)?_c('ItemSlideGroup',{attrs:{"data-list":_vm.eventsTicketsList,"title":_vm.$t('Tickets to events'),"item-width":320,"card-top-icon":"place-location","viewAllHref":'/tickets',"imageSrcHandler":function (e) { return e.mainImage; },"useVizguTypeRouting":false},on:{"onItemHref":function (item) {
            _vm.goEventPage(item);
          }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
          var element = ref.element;
return [_vm._v(_vm._s(element.name))]}}],null,false,125261171)}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"mt-16"},[_c('ListItems',{key:"1",attrs:{"loading":_vm.loading.initial,"data-list":_vm.eventsTicketsList,"title":_vm.$t('Countries'),"item-width":_vm.itemWidth,"card-top-icon":"place-location","imageSrcHandler":function (e) { return e.mainImage; },"imageAPI":_vm.eventImageAPI,"useVizguTypeRouting":false},on:{"onItemHref":function (item) {
          _vm.goEventPage(item);
        }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
        var element = ref.element;
return [_vm._v(_vm._s(element.name))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }