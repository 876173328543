
















































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {
  BaseDataV2,
  ExhibitionV2,
  VenueV2,
  VizguType,
  WayfinderV2
} from "@/types/vizgu";
import ItemSlideGroup from "@/components/ItemSlideGroupV2.vue";
import VideoCard from "@/components/VideoCard.vue";
import GUIDImage from "@/components/GUIDImage.vue";
import HighlightedSection, {
  HighlightedSectionData
} from "@/components/HighlightedSection.vue";
import venuesApi from "@/api/venues";
import {
  GetExhibitionsRequest,
  GetExhibitionsResponse,
  GetVenuesRequest,
  GetVenuesResponse,
  GetWayfindersRequest,
  GetWayfindersResponse
} from "@/types/api";
import exhibitionsApi from "@/api/exhibitions";
import wayfindersApi from "@/api/wayfinders";
import {
  exhibitionHighlightedData,
  venueHighlightedData,
  wayfinderHighlightedData
} from "@/utils/highlighted-section";
import {copyCurrentRouteClipboard} from "@/utils/clipboard";
import {embeddedStore} from "@/store";
import {EventKeys} from "@/store/modules/embedded";
import {parseBaseContentText, venueTodayOpenClose} from "@/utils/data";
import {TranslateResult} from "vue-i18n";

export interface TabItem {
  title: string | TranslateResult;
  route: string;
}

@Component({
  components: {HighlightedSection, VideoCard, ItemSlideGroup, GUIDImage}
})
export default class Country extends Vue {
  likeFeature = false;

  smallCardWidth = 220;
  wideCardWidth = 364;
  museumCardWidth = this.smallCardWidth;
  exhibitionCardWidth = this.wideCardWidth;
  eventCardWidth = this.wideCardWidth;
  cityCardWidth = this.smallCardWidth;
  artistCardWidth = this.smallCardWidth;
  wayfinderCardWidth = this.smallCardWidth;

  countryVenue: VenueV2 | null = null;

  venuesCount = 0;

  initialDataLoaded = false;

  slidersSettings = {
    perPage: 20
  };

  slidersData = {
    museums: [] as BaseDataV2[],
    popularExhibitions: [] as BaseDataV2[],
    topEvents: [] as BaseDataV2[],
    cities: [] as BaseDataV2[],
    artists: [] as BaseDataV2[],
    wayfinders: [] as BaseDataV2[]
  };

  @Watch("$route.params.id")
  onIdChange() {
    window.scrollTo(0, 0);
    this.loadApiData();
  }

  venueOpenCloseDate(currentVenue: VenueV2) {
    return venueTodayOpenClose(currentVenue);
  }

  shareLink() {
    copyCurrentRouteClipboard(this.$route);
  }

  get hSections() {
    return {
      first: this.firstHighlightedSection,
      second: this.secondHighlightedSection,
      third: this.thirdHighlightedSection
    };
  }

  get firstHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.museumsData?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      content: venueHighlightedData(v as VenueV2)
    });
  }

  get secondHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.exhibitionsData?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      imageTooltipText: "New!",
      content: exhibitionHighlightedData(v as ExhibitionV2)
    });
  }

  get thirdHighlightedSection(): HighlightedSectionData | undefined {
    const v = this.wayfindersData?.[0];
    if (!v) {
      return undefined;
    }
    return new HighlightedSectionData({
      imageSrc: v.imageGuids.$values[0],
      imageTooltipText: "Popular!",
      content: wayfinderHighlightedData(v as WayfinderV2)
    });
  }

  get tabItems(): TabItem[] {
    return [
      {
        title: this.$t("About"),
        route: "#about"
      },
      {
        title: this.$t("Venues"),
        route: "#venues"
      },
      {
        title: this.$t("Events"),
        route: "#events"
      },
      {
        title: this.$t("Wayfinders"),
        route: "#wayfinders"
      }
    ];
  }

  get bannerImgSrc() {
    return this.countryVenue?.imageGuids.$values[0] || "";
  }

  get inCountryLabel() {
    return `${this.$t("in")} ${this.countryName}`;
  }

  get countryName() {
    return this.countryVenue?.name || "";
  }

  get museumsData(): BaseDataV2[] {
    return this.slidersData.museums;
    // return getSampleBaseDataList();
  }
  get exhibitionsData(): BaseDataV2[] {
    return this.slidersData.popularExhibitions;
    // return getSampleBaseDataList();
  }
  get eventsData(): BaseDataV2[] {
    return this.slidersData.topEvents;
    // return getSampleBaseDataList();
  }
  get citiesData(): BaseDataV2[] {
    return this.slidersData.cities;
    // return getSampleBaseDataList();
  }
  get artistsData(): BaseDataV2[] {
    return this.slidersData.artists;
    // return getSampleBaseDataList();
  }
  get wayfindersData(): BaseDataV2[] {
    return this.slidersData.wayfinders;
    // return getSampleBaseDataList();
  }

  get description(): string {
    return this.countryVenue ? parseBaseContentText(this.countryVenue) : "";
  }

  get isVenueLoading() {
    return embeddedStore.currentVenueLoading;
  }

  addVenuesCount(v: unknown) {
    try {
      const s = `${v}`;
      const r = parseInt(s);
      if (isNaN(r)) {
        return;
      }
      this.venuesCount += r;
    } catch {
      return;
    }
  }

  initListeners(enable: boolean) {
    if (enable) {
      window.addEventListener(EventKeys.locale, this.onLocaleEvent);
    } else {
      window.removeEventListener(EventKeys.locale, this.onLocaleEvent);
    }
  }

  onLocaleEvent() {
    this.loadApiData();
  }

  // lifecycle methods
  async created() {
    this.initListeners(true);
    await this.loadApiData();
  }

  beforeDestroy() {
    this.initListeners(false);
  }
  // eof lifecycle methods

  // data load methods

  async loadApiData() {
    embeddedStore.setCurrentVenueLoading(true);
    embeddedStore.setCurrentVenue(null);
    const id: string = this.$route.params.id;
    await this.requestVenue(id);
    await this.requestSlidersData();
  }

  private async requestSlidersData() {
    this.getSliderMuseums();
    this.getSliderCities();
    this.getSliderExhibitions();
    this.getSliderWayfinders();
  }

  private async requestVenue(id: string) {
    const resp: VenueV2 = await venuesApi.getVenue(id);
    this.countryVenue = resp;
    embeddedStore.setCurrentVenue(resp);
    embeddedStore.setCurrentVenueLoading(false);
  }

  private async getSliderMuseums() {
    const params: GetVenuesRequest = {
      take: this.slidersSettings.perPage,
      country: this.countryName
    };
    const resp: GetVenuesResponse = await venuesApi.getVenues(params);
    this.addVenuesCount(resp.count);
    this.slidersData.museums = resp.list.$values;
  }

  private async getSliderCities() {
    const params: GetVenuesRequest = {
      take: this.slidersSettings.perPage,
      country: this.countryName,
      type: VizguType.City
    };
    const resp: GetVenuesResponse = await venuesApi.getVenues(params);
    this.slidersData.cities = resp.list.$values;
  }

  private async getSliderExhibitions() {
    const params: GetExhibitionsRequest = {
      skip: 0,
      take: this.slidersSettings.perPage,
      country: this.countryName
    };
    const resp: GetExhibitionsResponse = await exhibitionsApi.getExhibitions(
      params
    );
    this.addVenuesCount(resp.count);
    this.slidersData.popularExhibitions = resp.list.$values;
  }

  private async getSliderWayfinders() {
    const params: GetWayfindersRequest = {
      skip: 0,
      take: this.slidersSettings.perPage,
      country: this.countryName
    };
    const resp: GetWayfindersResponse = await wayfindersApi.getWayfinders(
      params
    );
    this.slidersData.wayfinders = resp.list.$values;
  }
}
