





































































import {Component, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import {Stage} from "./Checkout.vue";
import PaymentForm from "@/components/PaymentForm.vue";
import {cartStore} from "@/store";

export type PaymentFormData = {
  cardNumber: string;
  expiry: string;
  cvc: string;
};

export type PaymentData = {
  form: PaymentFormData;
  acceptTerms: boolean;
  saveDetails: boolean;
};

@Component({components: {GUIDImage, PaymentForm}})
export default class Payment extends Vue {
  private stripe: any;
  private elements: any;

  get styles() {
    return {
      title: {
        letterSpacing: "0.3rem"
      }
    };
  }

  get stages(): Stage[] {
    return [
      {label: "Review order", isChecked: true},
      {label: "Information", isChecked: true},
      {label: "Payment", isCurrent: true}
    ];
  }

  get paymentId() {
    return cartStore.payment.paymentId;
  }

  get checkoutKey() {
    return cartStore.payment.checkoutKey;
  }

  get totalPriceGross() {
    return cartStore.payment.totalPriceGross;
  }

  get buyCurrency() {
    return cartStore.buyCurrency;
  }

  async embedStripe() {
    if (!this.paymentId || !this.checkoutKey) {
      if (this.$route.query.redirect_status == "succeeded") {
        cartStore.onSuccessPayment();
        const orderId = this.$route.params?.["orderId"];
        if (!orderId) {
          return;
        }
        this.$router.push(`/checkout-process/${orderId}`);
      }
      return;
    }
    const [key, clientSecret] = this.checkoutKey.split(":");

    const w = window as any;
    this.stripe = w.Stripe(key, {locale: this.$i18n.locale});

    const appearance = {
      theme: "stripe",
      variables: {
        fontFamily: '"Oswald", serif',
        borderRadius: "0"
      }
    };

    const fonts = [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Oswald:300,400,500,700"
      }
    ];

    const options = {clientSecret, appearance, fonts};
    this.elements = this.stripe.elements(options);

    const paymentElement = this.elements.create("payment");
    paymentElement.mount("#payment-element");
  }

  async mounted() {
    await this.embedStripe();
  }
  async confirm() {
    const resp = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        return_url: window.location.href
      }
    });
    if (resp.error) {
      console.error(resp.error.message);
    }
  }
}
