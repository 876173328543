

































import {isMobile} from "@/utils/dom";
import {Component, Vue} from "vue-property-decorator";
@Component({components: {}})
export default class EmptyCart extends Vue {
  get styles() {
    return {
      icon: {
        sizeIcon: isMobile() ? "50" : "120"
      }
    };
  }
}
