


































































































































































































































































































































































































































































































































































































































































































































































































































import PlatformContactForm from "@/components/PlatformContactForm.vue";
import PlatformFAQ from "@/components/PlatformFAQ.vue";
import {Role, roleList} from "@/data";
import {isMobile} from "@/utils/dom";
import {TranslateResult} from "vue-i18n";
import {Component, Vue} from "vue-property-decorator";

type Feature = {
  title: string | TranslateResult;
  description?: string | TranslateResult;
  subFeatures?: Array<{title: string | TranslateResult}>;
};

type CaseItem = {
  title: string | TranslateResult;
  description?: string | TranslateResult;
  imgSrc: string;
};

@Component({components: {PlatformContactForm, PlatformFAQ}})
export default class PlatformV3 extends Vue {
  showCases = false;
  currentRole: Role | null = null;
  featureCards: Array<TranslateResult> = [
    this.$t("In-APP purchases"),
    this.$t("Tour Guide booking"),
    this.$t("Digital  AR guides"),
    this.$t("3D audio guides"),
    this.$t("Tickets"),
    this.$t("Mixed reality guides"),
    this.$t("Object detection")
  ];

  get l() {
    return {
      rolePickerTitle: this.$t(
        "Help <span class='text-green3'>us</span> understand who <span class='text-green3'>you</span> are"
      )
    };
  }

  get isMobile() {
    return isMobile();
  }

  platformMediaPath(file: string) {
    return require(`@/assets/images/footer/platform/${file}`);
  }

  mediaPath(file: string) {
    return require(`@/assets/images/aboutus/${file}`);
  }

  mediaPathV2(file: string) {
    return require(`@/assets/images/aboutus-v2/${file}`);
  }

  media = {
    mainBanner: this.mediaPathV2("main-banner.png"),
    info1: this.mediaPathV2("info-1.png"),
    info2: this.mediaPathV2("info-2.png"),
    info3: this.mediaPathV2("info-3.png"),
    info4: this.mediaPathV2("info-4.png"),
    info5: this.mediaPathV2("info-5.png"),
    info6: this.mediaPathV2("info-6.png"),
    info7: this.mediaPathV2("info-7.png"),
    forOurClients: this.platformMediaPath("desktop-tablet-stack.png"),
    desktopPhoneStack: this.platformMediaPath("desktop-phone-stack.png"),
    phoneStack1: this.platformMediaPath("phone-stack1.png"),
    forAudience: this.platformMediaPath("first-launch-1.png")
  };

  get caseBannerImg() {
    return require("@/assets/images/footer/platform/viz-in-holland.png");
  }

  get casesData(): CaseItem[] {
    return [
      {
        imgSrc: require("@/assets/images/footer/platform/phone-folkentinget.png"),
        title: this.$t("Folketinget"),
        description: this.$t(
          "The Danish Parliament requested a curation and collection app with object detection for internal use. We co-created the app with them and were subsequently awarded top scores for test, delivery, and project management. Part of the project was to allow our APIs for object detection to work with their collection system – for a seamless integration and workflow. After the internal project was completed. The Danish Parliament requested to be part of our Vizgu Official Community APP and thereby presenting their collection for the public to see."
        )
      },
      {
        imgSrc: require("@/assets/images/footer/platform/phone-cadw.png"),
        title: this.$t("CADW"),
        description: this.$t(
          "CADW had an old and outdated app- with great features but lack of maintenance and costs too high to continue the service. We were approached due to a recommendation and subsequently entered a co-creation project in implementing the best parts of the old version into Vizgu Community app. When visiting one of the 100+ locations- the Vizgu Community APP will change UI to fit CADW design and Identity and thus giving them the value of having their own app - combined with the value of being part of Vizgu’s growing community for all things art and cultural heritage! Furthermore, we updated their existing AR games to fit the current standards and expectations."
        )
      },
      {
        imgSrc: require("@/assets/images/footer/platform/phone-kolding.png"),
        title: this.$t("Museum Kolding"),
        description: this.$t(
          "Museum Kolding wanted to extend the visit beyond the walls of the cell and museum. Together we co-created a digital AR experience – where we made a 3D model of the PR & Communication Director. The city of Kolding is now a living and breathing city with a digital layer."
        )
      },
      {
        imgSrc: require("@/assets/images/footer/platform/phone-varde.png"),
        title: this.$t("Varde"),
        description: this.$t(
          "The municipality of Varde has one of the largest and most expensive sculptures and public art in Denmark- due to patronage from a local and successful real estate entrepreneur. We co-created digital guides, Facebook video campaigns, and continue to maintain the page to fit the municipality’s cultural goals going forward. Next stop is co-creating a new guide - based on data and findings from current."
        )
      }
    ];
  }

  get roles(): Role[] {
    return roleList.map(v => {
      return {
        title: this.$t(v.title).toString(),
        description: this.$t(v.description).toString(),
        image: v.image
      };
    });
  }

  features: Feature[] = [
    {
      title: this.$t("Boost revenue"),
      description: this.$t(
        "Ticket system, Booking System for guides, Event Management, Revenue sharing, Generate new and creative revenue streams!"
      ),
      subFeatures: [
        {title: this.$t("Tickets")},
        {title: this.$t("Webshop/Ticket Cross Sell")},
        {title: this.$t("Digital Guides")},
        {title: this.$t("Cross Sell Optimization")},
        {title: this.$t("Tour Guides")},
        {title: this.$t("Revenue Share")},
        {title: this.$t("Hybrid Tickets")},
        {title: this.$t("Time Slot Pricing")},
        {title: this.$t("Predicted Sales Based on Interest")},
        {title: this.$t("Collaboration Tickets")},
        {title: this.$t("Donation Feature")},
        {title: this.$t("Become a Memember (Subscribtion)")},
        {title: this.$t("Destination Marketing")},
        {title: this.$t("One-stop-shop for e-commerce")},
        {title: this.$t("Donate")}
      ]
    },
    {
      title: this.$t("User experience"),
      description: this.$t(
        "Augmented reality guides, Donations, Tickets, 3D audio guides. Co-create content with your audience."
      ),
      subFeatures: [
        {title: this.$t("Digital Guides")},
        {title: this.$t("Tailored Content")},
        {title: this.$t("Real time Nudging")},
        {title: this.$t("Crowd Control")},
        {title: this.$t("Push Notifications")},
        {title: this.$t("Heat Maps")},
        {title: this.$t("Indentify Traffik for Exhibtion Design")},
        {title: this.$t("Tailored Content Based on Interest")},
        {
          title: this.$t(
            "Push Notifications for Members (Engange with Members in real time)"
          )
        },
        {title: this.$t("Object Detection")},
        {title: this.$t("Augmented Reality Guides")},
        {title: this.$t("Geo Fencing / Geo Trigger Guides")},
        {
          title: this.$t(
            "Combine - AR, Audio, Geo Fencing/ Geo Trigger - Ultimate Digital Guide"
          )
        },
        {title: this.$t("Co-Creation")},
        {
          title: this.$t(
            "Ed-Tech -  Engage the future generation in art and Culture"
          )
        },
        {title: this.$t("Pop Up Notifications")},
        {
          title: this.$t(
            "Put your phone in the pocket and let it tell you about your sorroundings"
          )
        },
        {title: this.$t("Non Intrusive Sales")},
        {title: this.$t("Real Time Insight in customer behaviour")},
        {title: this.$t("Location & Detection combined")}
      ]
    },
    {
      title: this.$t("Operations Module"),
      description: this.$t(
        "Automate booking flow, People Management, Membership management."
      ),
      subFeatures: [
        {title: this.$t("Collection System Integration")},
        {title: this.$t("Business Critical Porcess Autimation")},
        {title: this.$t("Reporting/ Business Intelligence")},
        {title: this.$t("Front Office Automation")},
        {title: this.$t("Back office Automation")},
        {title: this.$t("KPI Based Reporting")},
        {title: this.$t("Real-time Insight")},
        {title: this.$t("Real-time Data")},
        {title: this.$t("People Management")},
        {title: this.$t("Payroll Managemet")},
        {title: this.$t("Calednar Sync")}
      ]
    },
    {
      title: this.$t("Data"),
      description: this.$t(""),
      subFeatures: [
        {title: this.$t("Real-Time Data")},
        {title: this.$t("Heat Maps")},
        {title: this.$t("Indentify Traffic")},
        {title: this.$t("How well do you know your visitor?")},
        {title: this.$t("Before, During, After")},
        {
          title: this.$t(
            "Predict Traffic from Insitution level to city and municipal level"
          )
        },
        {title: this.$t("Data Ownership")},
        {title: this.$t("Real Time Nudging - Via Data & Insights")}
      ]
    },
    {
      title: this.$t("Special Co-Creation Projects"),
      description: this.$t(""),
      subFeatures: [
        {title: this.$t("Ed-Tech Co-creation")},
        {title: this.$t("Public Private Innovation & Co-creation")}
      ]
    },
    {
      title: this.$t("API Integration"),
      description: this.$t(""),
      subFeatures: [
        {title: this.$t("CMS")},
        {title: this.$t("ERP")},
        {title: this.$t("CRM")},
        {title: this.$t("Mail Chimp")},
        {title: this.$t("Clearing/Merchents")},
        {title: this.$t("Google Analytics Pixel")},
        {title: this.$t("Facebook")}
      ]
    }
  ];

  setRole(v: Role) {
    this.currentRole = v;
  }

  toggleCases() {
    this.showCases = !this.showCases;
  }

  get content() {
    return {
      featuresTitle: this.$t(
        "The Vizgu platform is an <strong>agile</strong> and <strong>easy to use</strong> platform, developed with our unique co-creation approach. Check out some of the highlighted features below!"
      )
    };
  }

  get chooseRoleTitle() {
    return this.currentRole
      ? this.currentRole.title
      : this.$t("Choose a persona");
  }

  get ownModelImage(): string {
    return require("@/assets/images/footer/platform/own-model.png");
  }
  get packageImage(): string {
    return require("@/assets/images/footer/platform/package.png");
  }
}
