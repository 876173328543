export type PaymentConsumerPrivateData = {
  address: string;
  postCode: string;
  city: string;
  country: string;
};

export type PaymentConsumerData = {
  type: string; // "Private" convert to Enum?
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  marketing: boolean;
  company: string | null;
  private: PaymentConsumerPrivateData;
  comment: string;
};

export type CreatePaymentData = {
  consumer: PaymentConsumerData;
  cartId: string;
};

export type CreatePaymentRequest = {
  data: CreatePaymentData;
};

export enum PaymentType {
  Stripe = "Stripe",
  Free = "Free"
}
export type CreatePaymentResult = {
  externalPaymentId?: string;
  orderId: string;
  checkoutKey: string;
  _type?: PaymentType;
};

export interface VizOrderTicket {
  id: string;
  eventId: string;
  ticketId: string;
  holderFullName: string | null;
  status: string | null;
  activeFrom: string | null;
  activeTo: string | null;
  venues: unknown[];
}

export interface VizOrder {
  orderId: string;
  orgId: string;
  orderNumber: string;
  paymentStatus: string; // Created etc.
  sentToEmail: string;
  event: {
    name: string;
    dateTime: string;
  };
  fullName: string;
  tickets: Array<VizOrderTicket>;
  memberships: unknown[];
}
