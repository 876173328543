<template>
  <component :is="currentLayout">
    <slot name="before-page"></slot>
    <router-view></router-view>
    <slot name="after-page"></slot>
  </component>
</template>
<script>
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
export default class LayoutBroker extends Vue {
  @Prop() layouts;
  @Prop() current;

  get currentLayout() {
    return this.layouts[this.current];
  }
}
</script>
