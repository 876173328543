
































































































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import GUIDImage from "@/components/GUIDImage.vue";
import {VTicket} from "@/models/ticket";
import {CheckoutCategory} from "@/types/api.newviz/cart";
import {cartStore} from "@/store";
import {VCart} from "@/models/cart";
import {COUNTRIES, Country, TCountry} from "@/utils/data";

export type Stage = {
  label?: string;
  isCurrent?: boolean;
  isChecked?: boolean;
};

@Component({components: {GUIDImage}})
export default class Checkout extends Vue {
  placeholderImageUid = "e17fb04a-e32f-4041-92f1-abf608dbb73c";
  coupon = "";

  loading = {
    global: false
  };

  get ticketsCurrency() {
    return cartStore.currentCurrency;
  }

  setGlobalLoading() {
    this.loading.global = true;
  }

  unsetGlobalLoading() {
    this.loading.global = false;
  }

  get isLoading() {
    return this.loading.global;
  }

  get requiredInputsValid() {
    const requiredKeys = [
      "category",
      "firstLastName",
      "phoneCode",
      "phoneNumber",
      "email",
      "postalCode",
      "country"
    ];
    return (
      Object.entries(this.formData)
        .filter(k => requiredKeys.includes(k[0]))
        .find(k => !k[1]) != undefined
    );
  }

  get canDoPayment() {
    return this.requiredInputsValid;
  }

  // TODO
  tickets: VTicket[] = [];

  get categoryItems() {
    const C = CheckoutCategory;
    return [C.Private];
  }

  countryInfo(name: Country): TCountry {
    return COUNTRIES.find(v => v.name == name) as TCountry;
  }

  get countryItemsPhoneCode() {
    return COUNTRIES.map(c => {
      return {
        ...c,
        value: c.phoneCode
      };
    });
  }

  get countryItemsLabels() {
    return COUNTRIES.map(c => c.label);
  }

  formData = cartStore.checkout.formData;

  get styles() {
    return {
      title: {
        letterSpacing: "0.3rem"
      }
    };
  }

  get labels() {
    return {
      yourInfo: this.$t("Your information"),
      product: this.$t("Product")
        .toString()
        .toLowerCase(),
      adults: "Adults",
      child: "Child",
      senior: "Senior",
      selectCategory: "Select category",
      selectCountry: "Select country",
      acceptPolicy: "I have read and accept the return policy and the Offer"
    };
  }

  paymentImagePath(name: string) {
    const path = `@/assets/images/payments/${name}`;
    return require(path);
  }
  get payments() {
    return [
      {icon: require("@/assets/images/payments/visa.png")},
      {icon: require("@/assets/images/payments/master-1.png")},
      {icon: require("@/assets/images/payments/master-2.png")},
      {icon: require("@/assets/images/payments/nets.png")},
      {icon: require("@/assets/images/payments/dk.png")},
      {icon: require("@/assets/images/payments/mobile-pay.png")}
    ];
  }

  get cart(): VCart | null {
    return cartStore.cart;
  }

  // TODO
  get cartTotalAmount() {
    const price = this.cart?.totalPrice.gross || 0;
    return `${price} ${this.ticketsCurrency}`;
  }

  get stages(): Stage[] {
    return [
      {label: "Review order", isChecked: true},
      {label: "Information", isCurrent: true},
      {label: "Payment"}
    ];
  }

  async goPaymentClick() {
    // TODO checks here
    this.setGlobalLoading();
    await cartStore.createPayment({
      onSuccess: () => {
        this.unsetGlobalLoading();
        cartStore.onInitPayment();
        if (cartStore.isCurrentPaymentFree) {
          // FREE PAYMENT CASE, NAVIGATE TO ORDER PAGE
          this.$router.push(`checkout-process/${cartStore.payment.orderId}`);
        } else {
          // there is no way to change currency and recalculate cart after this step (during payment)
          cartStore.setBuyCurrency(cartStore.currentCurrency);
          this.$router.push(`/payment/${cartStore.payment.orderId}`);
        }
      }
    });
  }
}
