







































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {InformationItem} from "@/components/EventInfoBox.vue";
import GUIDImage from "@/components/GUIDImage.vue";
import Rating from "@/components/Rating.vue";
import ItemSlideGroup from "@/components/ItemSlideGroupV2.vue";
import {VizguTypeLink, WayfinderV2} from "@/types/vizgu";
import wayfindersApi from "@/api/wayfinders";
import {embeddedStore} from "@/store";
import {parseDateToString} from "@/utils/datetime";
import {GetWayfindersRequest, GetWayfindersResponse} from "@/types/api";
import {copyCurrentRouteClipboard} from "@/utils/clipboard";
import {EventKeys} from "@/store/modules/embedded";
import {parseBaseContentText} from "@/utils/data";
import {openMobileLinkFor} from "@/utils/mobileLink";
import WayfinderStops, {
  WayfinderStopsProps
} from "@/components/WayfinderStops.vue";

@Component({
  components: {Rating, GUIDImage, ItemSlideGroup, WayfinderStops}
})
export default class Wayfinder extends Vue {
  likeFeature = false;
  ratingFeature = false;
  showMapTour = false;
  currentWayfinder: WayfinderV2 | null = null;

  similarWayfinderTake = 20;
  similarWayfinders: WayfinderV2[] = [];

  shareLink() {
    copyCurrentRouteClipboard(this.$route);
  }

  openMobile() {
    openMobileLinkFor({
      type: VizguTypeLink.Wayfinder,
      id: this.currentWayfinder?.id || ""
    });
  }

  @Watch("$route.params.id")
  async onIdChange() {
    window.scrollTo(0, 0);
    embeddedStore.setCurrentWayfinder(null);
    const id: string = this.$route.params.id;
    await this.requestWayfinder(id);
    await this.getSliderSimilarWayfinders();
  }

  get eventInfo(): InformationItem[] {
    return [
      /*
      {
        icon: "clock",
        title: "3 hrs"
      },
      */
      {
        icon: "calendar",
        title: this.wayfinderDateStart
      }
      /*
      {
        icon: "place-location",
        title: ''
      }
      */
    ];
  }

  get wayfinderStopsProps(): WayfinderStopsProps | undefined {
    if (!this.currentWayfinder) {
      return undefined;
    }
    return {wayfinder: this.currentWayfinder};
  }

  get aboutDescription(): string {
    return this.currentWayfinder
      ? parseBaseContentText(this.currentWayfinder)
      : "";
  }

  initListeners(enable: boolean) {
    if (enable) {
      window.addEventListener(EventKeys.locale, this.onLocaleEvent);
    } else {
      window.removeEventListener(EventKeys.locale, this.onLocaleEvent);
    }
  }

  onLocaleEvent() {
    this.loadApiData();
  }

  // lifecycle methods
  async created() {
    this.initListeners(true);
    await this.loadApiData();
  }
  beforeDestroy() {
    this.initListeners(false);
  }
  // eof lifecycle methods

  async loadApiData() {
    embeddedStore.setCurrentWayfinder(null);
    const id: string = this.$route.params.id;
    await this.requestWayfinder(id);
    await this.getSliderSimilarWayfinders();
  }

  get wayfinderName(): string {
    return this.currentWayfinder?.name || "";
  }

  get wayfinderDateStart(): string {
    const dateStart = this.currentWayfinder?.begin;
    if (dateStart) {
      // catcher in case of invalid string for Date parsing
      try {
        return parseDateToString(new Date(dateStart));
      } catch {
        return "No date yet";
      }
    }
    return "No date yet";
  }

  get wayfinderImageSrc(): string {
    const defaultImgSrc = "e17fb04a-e32f-4041-92f1-abf608dbb73c";
    return this.currentWayfinder?.imageGuids.$values[0] || defaultImgSrc;
  }

  // data load methods
  private async requestWayfinder(id: string) {
    const resp: WayfinderV2 = await wayfindersApi.getWayfinder(id);
    this.currentWayfinder = resp;
    embeddedStore.setCurrentWayfinder(resp);
  }

  private async getSliderSimilarWayfinders() {
    const params: GetWayfindersRequest = {
      skip: 0,
      take: this.similarWayfinderTake
    };
    const resp: GetWayfindersResponse = await wayfindersApi.getWayfinders(
      params
    );
    this.similarWayfinders = resp.list.$values;
  }
}
