import {VCart} from "@/models/cart";
import {Module, Mutation, VuexModule, Action} from "vuex-module-decorators";
import {
  CheckoutFormData,
  defaultCheckoutFormData
} from "@/types/api.newviz/cart";
import paymentApi from "@/api.newviz/payment";
import {PaymentType} from "@/types/api.newviz/payment";
import currencyApi from "@/api.newviz/currency";

export interface CartState {
  cart: VCart | null;
  checkout: {
    formData: CheckoutFormData;
  };
  payment: {
    paymentId: string | null;
    orderId: string | null;
  };
  currencies: Array<string>;
  currentCurrency: string;
  buyCurrency: string;
}

@Module({name: "cart", namespaced: true})
export default class CartModule extends VuexModule implements CartState {
  cart: VCart | null = null;
  checkout = {
    formData: defaultCheckoutFormData()
  };
  payment = {
    paymentId: null as string | null,
    checkoutKey: null as string | null,
    orderId: null as string | null,
    type: null as PaymentType | null,
    totalPriceGross: null as number | null
  };

  /** Current supported currencies returned from backend */
  currencies: Array<string> = [];
  /** Current currency , use DKK as default */
  currentCurrency = "DKK";
  buyCurrency = "DKK";

  get isCurrentPaymentFree() {
    return this.payment.type && this.payment.type == PaymentType.Free;
  }

  @Action
  async initialize() {
    // this.restoreCurrencyFromCache();
    // this.restoreCartFromCache();

    // fetch currencies on module initialize
    // this.fetchCurrencies();
  }

  @Mutation
  setCurrentCurrency(currency: string) {
    this.currentCurrency = currency;
  }

  @Mutation
  setBuyCurrency(currency: string) {
    this.buyCurrency = currency;
  }

  @Mutation
  setCurrencies(currencies: Array<string>) {
    this.currencies = currencies;
  }

  @Mutation
  setCurrentCart(v: VCart | null) {
    this.cart = v;
  }

  @Action
  cacheCart() {
    if (this.cart) {
      localStorage.setItem("viz-cart", JSON.stringify(this.cart));
    }
  }

  @Action
  cacheCurrency() {
    if (this.currentCurrency) {
      localStorage.setItem("currency", this.currentCurrency);
    }
  }

  @Action
  removeCachedCart() {
    if (localStorage.getItem("viz-cart")) {
      localStorage.removeItem("viz-cart");
    }
  }

  @Action
  restoreCartFromCache() {
    const vRaw = localStorage.getItem("viz-cart");
    if (vRaw) {
      const v: VCart = JSON.parse(vRaw);
      const cart = new VCart(v);
      if (cart.isExpired) {
        localStorage.removeItem("viz-cart");
      } else {
        this.context.commit("setCurrentCart", cart);
      }
    }
  }

  @Action
  restoreCurrencyFromCache() {
    const currency = localStorage.getItem("currency");
    if (currency) {
      this.context.commit("setCurrentCurrency", currency);
    }
  }

  @Action
  async createPayment(p: {onSuccess: () => void}) {
    if (!this.cart || this.cart.isExpired) {
      return;
    }
    const {onSuccess} = p;
    const req = this.cart.buildCreatePaymentFromCheckoutInfo({
      form: this.checkout.formData
    });
    try {
      const res = await paymentApi.createPayment(req);
      const {orderId, externalPaymentId, _type, checkoutKey} = res;
      this.payment.orderId = orderId;
      externalPaymentId &&
        (this.payment.paymentId = externalPaymentId) &&
        (this.payment.checkoutKey = checkoutKey);
      this.payment.type = _type || null;
      this.payment.totalPriceGross = this.cart.totalPrice.gross;
      onSuccess();
    } catch (e) {
      // TODO handle error
    }
  }

  @Action
  onSuccessPayment() {
    this.payment.orderId = null;
    this.payment.paymentId = null;
  }

  @Action
  onInitPayment() {
    this.setCurrentCart(null);
    this.removeCachedCart();
  }

  @Action
  async fetchCurrencies() {
    try {
      const currencies = await currencyApi.getCurrencies();
      if (Array.isArray(currencies)) {
        this.setCurrencies(currencies);
      }
    } catch (e) {
      console.error(`[CartModule][fetchCurrencies] ${e}`);
    }
  }
}
