






















































































import hubspotApi from "@/api/hubspot";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
export default class IntroSignUpModal extends Vue {
  @Prop({required: true, type: Boolean, default: false}) isOpen!: boolean;
  @Prop({required: true, type: Function}) setOpen!: (isOpen: boolean) => void;

  formSended = false;

  loaders = {
    sendingForm: false
  };

  get isModalOpen() {
    return this.isOpen;
  }

  set isModalOpen(v: boolean) {
    this.setOpen(v);
  }

  form = {
    firstName: "",
    lastName: "",
    email: ""
  };

  get formData(): Array<Record<string, string>> {
    return [
      {name: "email", value: this.form.email},
      {name: "firstName", value: this.form.firstName},
      {name: "lastName", value: this.form.lastName},
      {name: "role", value: "Client"}
    ];
  }

  onFormSend() {
    this.sendForm();
  }

  async sendForm() {
    this.loaders.sendingForm = true;
    try {
      const result = await hubspotApi.submitForm(this.formData);
      if (result.status == 200) {
        this.formSended = true;
      }
    } finally {
      this.loaders.sendingForm = false;
      this.setOpen(false);
    }
  }
}
