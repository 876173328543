import {
  CartItem,
  VizCart,
  CartItemType,
  CartItemEvent,
  CreateCartRequest,
  RemoveFromCartRequest,
  AddToCartRequest,
  CartItemEventItems,
  CheckoutFormData,
  RecalculateCartRequest
} from "@/types/api.newviz/cart";
import {VEvent} from "./events";
import cartApi from "@/api.newviz/cart";
import {cartStore, embeddedStore} from "@/store";
import {parseTimestamp} from "@/utils/datetime";
import {CreatePaymentRequest} from "@/types/api.newviz/payment";

export class VCartItem implements CartItem {
  id!: string;
  type!: CartItemType;
  totalPrice!: number;
  totalQuantity!: number;
  event!: CartItemEvent;
  product!: unknown;
  membership!: unknown;
  shipping!: unknown;

  constructor(props: Partial<CartItem>) {
    Object.assign(this, props);
  }

  get dateLabel() {
    return this.event.dateTime;
  }
}

export class VCart implements VizCart {
  id!: string;
  items: VCartItem[] = [];
  fees: [] = [];
  totalPrice!: {
    net: number;
    gross: number;
    tax: number;
  };
  itemsTotalQuantity!: number;
  forcedTotalPrice: number | null = null;

  constructor(props: Partial<VizCart>) {
    Object.assign(this, props);
    this.items.map(item => new VCartItem(item));
  }

  get allEventItems() {
    const items: CartItemEventItems[] = [];
    this.items.forEach(item =>
      item.event.items.forEach(cartItemEvent => items.push(cartItemEvent))
    );
    return items;
  }

  expireTimestamp: number = new Date().getTime() + 15 * 60 * 1000;

  refreshExpireTime() {
    this.expireTimestamp = new Date().getTime() + 15 * 60 * 1000;
  }

  save() {
    cartStore.setCurrentCart(this);
    cartStore.cacheCart();
  }

  async removeItem(itemId: string): Promise<VCart> {
    const params: RemoveFromCartRequest = {
      cartId: this.id,
      itemId
    };
    try {
      const newCart = await cartApi.removeFromCart(params);
      this.updateWith(newCart);
      this.refreshExpireTime();
      this.save();
      return this;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async recalculateCurrency() {
    const params: RecalculateCartRequest = {
      cartId: this.id,
      currency: cartStore.currentCurrency
    };

    try {
      const newCart = await cartApi.recalculateCart(params);
      this.updateWith(newCart);
      this.refreshExpireTime();
      this.save();
    } catch (e) {
      console.error("[Cart][recalculateLocale] ${e}");
    }
  }

  updateWith(cart: VizCart) {
    Object.assign(this, cart);
    this.items = this.items.map(item => new VCartItem(item));
  }

  static async create(event: VEvent): Promise<VCart> {
    const params: CreateCartRequest = VCart.buildCreateRequestFromEventTicket(
      event
    );
    const resp = await cartApi.createCart(params);
    const cart = new VCart(resp);
    cart.save();
    return cart;
  }

  async addItem(event: VEvent) {
    const createR = VCart.buildCreateRequestFromEventTicket(event);
    const params: AddToCartRequest = {
      cartId: this.id,
      added: createR.added,
      language: createR.language,
      buyCurrency: cartStore.currentCurrency
    };
    const resp = await cartApi.addToCart(params);
    this.updateWith(resp);
    this.refreshExpireTime();
    this.save();
    return this;
  }

  static async createOrAdd(event: VEvent): Promise<VCart> {
    const existCart = cartStore.cart;
    let cart;
    if (existCart) {
      cart = await existCart.addItem(event);
    } else {
      cart = await VCart.create(event);
    }
    return cart;
  }

  static buildCreateRequestFromEventTicket(event: VEvent): CreateCartRequest {
    const items = event.tickets.list
      .filter(t => t.count > 0)
      .map(t => {
        return {
          ticketId: t.nameDefaultOrCurrentLocale(event.defaultLanguage),
          quantity: t.count
        };
      });
    return {
      added: {
        type: CartItemType.Event,
        eventId: event.eventId,
        items
      },
      language: event.defaultLanguage || embeddedStore.currentLocale.code,
      buyCurrency: cartStore.currentCurrency
    };
  }

  get timeLeftMillis(): number {
    return this.expireTimestamp - embeddedStore.timeNow.getTime();
  }

  get isExpired(): boolean {
    return this.timeLeftMillis < 0;
  }

  get timeLeftLabel(): string {
    const l = this.timeLeftMillis;
    if (l < 0) {
      return "0";
    }
    const p = parseTimestamp(l);
    return `${p.minutes}:${p.seconds}`;
  }

  buildCreatePaymentFromCheckoutInfo(p: {
    form: CheckoutFormData;
  }): CreatePaymentRequest {
    const {form} = p;
    return {
      data: {
        cartId: this.id,
        consumer: {
          type: form.category,
          email: form.email,
          phoneNumber: form.phoneNumber,
          firstName: form.firstName,
          lastName: form.lastName,
          marketing: false,
          company: null,
          private: {
            address: form.address,
            postCode: form.postalCode,
            city: form.city,
            country: form.country
          },
          comment: ""
        }
      }
    };
  }
}
